@import "style-constants.scss";

.availability-wrapper {
    margin-bottom: 20px;

    .switch-container {
        display: flex; 
        gap: 20px;

        .export-cv-btn {
            margin: 30px 0px 0px auto;
        }
    }

    .availability-date-picker-wrapper {
        margin-bottom: 10px;
    }

    .buttons-wrapper {
        display: flex;

        .availability-save-button {
            margin-left: auto;
            margin-right: 20px;
        }
    }
}
