@import 'style-constants.scss';

.date-range-picker-wrapper {
    .date-range-picker-button {
        background-color: $white;
        color: $purple-dark;
        border-radius: 18px;
        border: none;
        padding: 10px 13px;

        .MuiButton-label {
            display: grid;
            grid-auto-flow: column;
            gap: 10px;
            align-items: center;
        }

        .MuiTypography-body1 {
            text-transform: none;
        }

        svg {
            stroke: $purple-dark;
            stroke-width: 1;
            width: 14px;
            height: 14px;
        }

        &.active,
        &:hover {
            background-color: $purple-dark;
            color: $white;

            svg {
                stroke: $white;
                fill: $white;
            }
        }

        &:hover {
            cursor: pointer;

            svg {
                stroke: $white;
                fill: $white;
            }
        }
    }
}
