@import "style-constants.scss";

.multi-filter-list-wrapper {
    .multi-filter-list-button {
        min-width: 192px;
        padding: 12px 16px;
        background-color: $white;
        border-color: $pale-gray;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-aligned {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        span {
            font-size: $subheading-m;
            font-weight: 400;
            line-height: 26px;
            color: $grey;
        }

        .filter-list-icon {
            margin-right: 10px;
        }

        &.open {
            border-color: $grey;
        }
    }
}



.filter-list-menu {
    .MuiList-padding {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .filter-list-menu-item {
        padding: 7px 12px 7px 20px;
        font-size: $subheading-m;
        font-weight: 400;
        line-height: 26px;
        color: $dark-grey;
        justify-content: space-between;
        align-items: center;

        &.selected {
            background-color: $deep-purple;
        }

        &:hover:not(.selected) {
            background-color: $purple;
        }
    }

    &.client-view {
        .filter-list-menu-item.selected {
            background-color: $deep-orange;
        }

        .filter-list-menu-item:hover:not(.selected) {
            background-color: $orange;
        }

        text-transform: capitalize;
    }
}