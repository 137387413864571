@import 'style-constants.scss';

.matching-profiles-table {
    margin-top: 25px;
    .title {
        color: rgba($purple-dark, 0.8);
        font-size: $medium;
        font-weight: 400;
    }

    .matching-profiles-wrapper {
        margin-top: 20px;
        display: grid;
        gap: 20px;
    }

    .matching-profile-wrapper {
        position: relative;
        background-color: $white;
        width: 100%;
        box-shadow: 0px 1px 3px $grey-darker-button;
        border-radius: 4px;
        padding: 50px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .matching-profile-role-badge {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 5px 15px;
        background-color: rgba($grey-dark, 0.1);
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;

        span {
            text-align: center;
            font-size: $medium;
            color: $grey-dark;
        }
    }

    .matching-profile-information {
        display: flex;
        align-items: center;
        width: 40%;
    }

    .matching-profile-avatar-wrapper {
        position: relative;
        margin-right: 20px;

        .matching-profile-image {
            width: 50px;
            height: 50px;
        }

        .status-dot {
            position: absolute;
            bottom: 2px;
            right: 4px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            border: 1px solid $white;

            &.available {
                background-color: $secondary-color-300;
            }

            &.occupied,
            &.default {
                background-color: $grey-dark;
            }

            &.unavailable {
                background-color: $red-dark;
            }
        }
    }

    .matching-profile-about-wrapper {
        display: grid;
        gap: 3px;

        .name-status-wrapper {
            display: flex;
            align-items: center;
        }

        .name-link {
            text-decoration: none;
        }

        .name {
            font-size: $medium-title;
            font-weight: 500;
            letter-spacing: 0.61px;
            color: $purple-dark;
        }

        .status-wrapper {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 6px;
            margin-left: 6px;

            .status {
                font-size: $small;

                &.vetted {
                    color: $secondary-color;
                }

                &.rejected {
                    color: $red-darker;
                }

                &.incomplete-profile {
                    color: $grey-dark;
                }

                &.pending-calendly-review {
                    color: $grey-dark;
                }

                &.pending {
                    color: $grey-dark;
                }

                &.pending-video-review {
                    color: $grey-dark;
                }
            }
        }

        .position {
            font-size: $medium;
            letter-spacing: 0.28px;
            color: $purple-dark;
        }
    }

    .matching-profile-score-wrapper {
        position: relative;

        .matching-profile-matching-text-container {
            display: grid;
            grid-auto-flow: column;
            gap: 10px;
            align-items: center;
            margin-bottom: 15px;

            .matching-profile-matching-text {
                font-size: 16px;
                line-height: 21px;
                font-weight: 400;
                letter-spacing: 0.28px;
                color: $purple-dark;
            }

            .matching-profile-information-icon {
                display: flex;
                align-items: center;
            }
        }

        .matching-profile-matching-number {
            font-size: 30px;
            line-height: 18px;
            font-weight: bold;
            letter-spacing: 1.01px;
            color: $purple-dark;
            display: flex;
            align-items: center;

            small {
                font-size: 70%;
                font-weight: 400;
            }
        }

        .matching-profile-matching-tooltip.hide {
            visibility: hidden;
        }

        .matching-profile-matching-tooltip.show {
            visibility: visible;
        }

        .matching-profile-matching-tooltip {
            display: flex;
            align-items: center;
            position: absolute;
            top: 22px;
            right: 10px;
            z-index: 999;
            flex-wrap: wrap;
            max-width: 700px;
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 3px $grey-shadow;
            border-radius: 4px;
            padding: 21px 28px;
            text-align: left;
            font-size: 14px;
            line-height: 21px;
            font-weight: 300;
            letter-spacing: 0.24px;
            color: $grey-dark-text;
            width: 283px;

            .matching-profile-matching-tooltip-title {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: 0.24px;
                color: $purple-dark;
                margin: 8px 0 19px 0;
            }
        }
    }
}
