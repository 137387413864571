@import "style-constants.scss";

.trainings-wrapper {
    .loader-wrapper {
        position: relative;
        min-height: 208px;
    }

    .loader-wrapper {
        min-height: 500px;
    }

    .centered {
        display: flex;
        position: absolute;
        left: 48%;
        top: 40%;
    }

    .trainings-list {
        display: grid;
        gap: 20px;
    }
}
