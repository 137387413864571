@import "style-constants.scss";

.candidate-personal-information-content-wrapper {
    .profile-info-wrapper {
        .actions-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;

            .action-buttons {
                display: flex;
                justify-content: space-between;
                height: 30px;
                width: 180px;
            }

            .save-button,
            .basic-info-save-button {
                margin-right: 20px;
            }
        }

        .profile-info-content {
            padding: 40px;
            border-radius: 18px;
        }
    }

    .personal-info-wrapper {
        width: 100%;

        .actions-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 10px 0;

            .personal-form-save-button,
            .basic-info-save-button,
            .contract-information-save {
                margin-right: 20px;
            }
        }

        .personal-info-content {
            padding: 40px;
            margin: 0px auto;
            width: 100%;
        }

        .personal-info-form-field {
            margin-bottom: 34px;
            text-align: left;

            &.last {
                margin-bottom: 0;
            }
        }
    }

    .candidate-general-form {
        margin-bottom: 20px;
    }
}

.candidate-personal-information-content-wrapper::-webkit-scrollbar {
    width: 0px;
    display: none;
}
