@import "style-constants.scss";

.agency-tasks-wrapper {
    .buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-left: 40px;

        .create-task-btn {
            display: flex;
            justify-content: flex-end;
            margin-left: 40px;
        }
    }

    .tasks-wrapper {
        .actions-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}
