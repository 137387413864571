@import "style-constants.scss";

.timesheets-table-container {
    .days-worked {
        display: flex;
        align-items: center;
    }

    .warning-icon {
        cursor: pointer;
        height: 16px;
        margin-left: 3px;
    }
}