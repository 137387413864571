@import "style-constants.scss";

.cv-completion-container {
    padding: 40px 96px;
    background-color: $white;
    margin: auto;
    margin-bottom: 11px;
    border-radius: 18px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    
    @media screen and (max-width: $mobile) {
        padding: 32px 30px;
    }

    .title {
        margin-bottom: 20px;
        text-align: center;
        
        @media screen and (max-width: $mobile) {
            font-size: 18px;
            letter-spacing: 0.14px;
        }
    }
    
    .summary {
        text-align: center;
        
        @media screen and (max-width: $mobile) {
            margin: 0;
            font-size: 16px;
            letter-spacing: 0.14px;
        }
    }
}
