@import 'style-constants.scss';

.benefit-info-wrapper {
    display: flex;
    padding-left: 33px;

    .benefit-info-container {
        border-bottom: 1px solid $grey-border;
        margin: auto;
        width: 100%;
        padding: 30px 0px;

        .benefit-info-description-wrapper {
            color: #707070;
            margin-top: 5px;
        }
    }
}

.benefit-info-wrapper.view-mode {
    padding: 0px 33px;
}
