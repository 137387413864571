@import "style-constants.scss";

.job-historical-note-wrapper {
    padding: 30px 40px;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 4px;
    background-color: $white;

    .job-historical-note-creation-info {
        display: flex;
        align-items: center;
        margin-bottom: 17px;

        .creator-name {
            font-size: $medium;
            font-weight: 500;
            letter-spacing: 0.21px;
            color: $purple-dark;
            margin-right: 8px;
        }

        .creation-date {
            font-size: $medium;
            font-weight: 400;
            letter-spacing: 0.21px;
            color: $purple-dark;
            opacity: 0.8;
        }

        .job-historical-note-actions {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 12px;
            margin-left: 10px;

            .icon-wrapper {
                cursor: pointer;
            }
        }
    }

    .job-historical-note-description {
        .description-text {
            font-size: $medium;
            font-weight: 400;
            letter-spacing: 0.21px;
            color: $purple-dark;
            white-space: pre-wrap;
        }
    }
}
