@import "style-constants.scss";

.evrotrust-banner-outer-container {
    padding: 20px 0;
}

.evrotrust-banner-wrapper {
    padding: 60px 80px;
    display: flex;
    border-radius: 4px;
    justify-content: space-between;
    background-color: $light-shade-blue;
    align-items: center;

    .col-1of2 {
        width: 53%;
        color: $primary-color-400;
    }

    .col-2of2 {
        width: 47%;
        text-align: right;
    }

    .image-info {
        margin-top: -20px;
        margin-right: 20px;
    }

    .buttons-container {
        width: 250px;
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        .store-btn {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .action-wrapper-title {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 500;
        min-width: 200px;
    }

    .action-wrapper-content {
        color: $grey-lighter;
        font-size: 16px;

        span {
            color: $primary-color-700;
            font-weight: bold;
        }
    }

    .view-hiring-details {
        margin-left: 45px;
    }
}
