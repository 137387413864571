body {
    margin: 0;
    overflow: hidden;
    font-family: "Urbanist", sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Urbanist", sans-serif;
}
