@import "style-constants.scss";

.edit-image-dialog {
    .img-cropp-container {
        display: none;
    }

    .ReactCrop__crop-selection {
        border: 1.7px dashed $purple-dark;
    }

    &.job-image {
        .ReactCrop__crop-selection {
            border: 1.5px dashed $secondary-color;
        }

        .ReactCrop__drag-handle::after {
            border-color: $secondary-color;
        }
    }
}
