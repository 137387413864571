@import "style-constants.scss";

.profile-fit-table-wrapper {
    margin-top: 30px;
    position: relative;

    .matching-consultants-loader {
        position: absolute;
        left: 50%;
        top: 250%;
    }
}

.grouped-select-filter-list-menu.matching-consultants {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 25px;
    }
}
