@import "style-constants.scss";

.upcoming-interview-table-wrapper {
    margin-top: 40px;

    .upcoming-interview-table-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
}
