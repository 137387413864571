@import 'style-constants.scss';

.common-formik-dialog {
    position: relative;

    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:hover {
        background: $purple-light;
    }

    .MuiDialog-paper {
        padding: 30px 40px 0px;
    }

    .dialog-title {
        padding: 0;
        margin-bottom: 12px;
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
        grid-template-columns: 95% 5%;
    }

    .dialog-title-text {
        text-align: center;
    }

    .dialog-content {
        padding: 8px 0px 0px;
        overflow: unset;
    }

    @media (max-width: $mobile) {
        .MuiDialog-container {
            height: unset;
        }

        .MuiDialog-paper {
            padding: 30px 40px;
            width: 100%;
            margin: 0;
            height: 100vh;
        }

        .MuiDialog-paperWidthSm {
            max-width: unset;
        }

        .MuiDialogContent-root {
            flex: unset;
        }

        .dialog-actions-container {
            display: flex;
            justify-content: center;
        }
    }

    .dialog-actions-wrapper {
        background-color: $white;
        position: sticky;
        bottom: 0;
        margin-top: 12px;
        z-index: 1;
        padding: 20px 0px 30px;
        background-color: $white;
    }

    .dialog-actions-container {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;

        .dialog-save-btn.loading {
            .MuiButton-label {
                justify-items: center;

                span {
                    visibility: hidden;
                }
            }

            .button-loader {
                position: absolute;
            }
        }
    }

    .MuiSelect-icon {
        right: 16px;
        color: $grey-dark;
    }

    .form {
        display: grid;
        gap: 20px;

        .invalid-field-message {
            padding-left: 16px;
            padding-top: 4px;
            font-size: 12px;
            letter-spacing: 0.4px;
            color: $red-darker;
        }

        .MuiFormControl-root {
            .MuiInput-underline:after {
                border-bottom-color: $primary-color-700;
            }

            label {
                color: $grey-dark;

                &.Mui-disabled {
                    opacity: 0.71;
                }
            }

            label.Mui-focused,
            &:hover label.MuiInputLabel-shrink {
                color: $dark-grey;

                &.Mui-error {
                    color: $red;
                }

                font-weight: 500;
            }

            .MuiOutlinedInput-root.Mui-disabled {
                opacity: 0.71;
            }

            .MuiOutlinedInput-root:not(.Mui-error):not(.Mui-disabled) {
                .MuiOutlinedInput-input {
                    color: $blue-darkest;
                }

                fieldset {
                    border-color: $grey-lighter;
                    border-width: 1px;
                }

                &:hover,
                &.Mui-focused {
                    fieldset {
                        border-color: $dark-grey;
                    }

                    span {
                        color: $grey;
                        font-weight: 500;
                    }

                    svg {
                        color: $grey;
                    }
                }
            }

            .MuiOutlinedInput-root.Mui-error:not(.Mui-disabled) {
                fieldset {
                    border-color: $red-darker;
                    border-width: 1px;
                }

                &:hover,
                &.Mui-focused {
                    span {
                        color: $red;
                        font-weight: 500;
                    }

                    svg {
                        color: $red;
                    }
                }
            }
        }
    }

    .hint {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 10px;
        color: $grey-dark;
        margin-bottom: 10px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.common-formik-dialog.sm {
    .MuiDialog-paper {
        padding: 30px 40px 0px;
        width: 462px;
    }
}

.common-formik-dialog.md {
    .MuiDialog-paper {
        padding: 30px 40px 0px;
        width: $paper-element-m-plus;
    }
}