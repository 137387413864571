@import 'style-constants';

.forgotten-password-wrapper {
    background-color: $dark-grey;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-evenly;
    width: 100%;

    .forgotten-password-form-wrapper {
        padding: 40px 90px;
        width: 524px;
        height: 524px;
        background-color: $white;
        border-radius: 40px;
        display: grid;
        grid-auto-flow: row;
        grid-template-rows: 70px auto;
    }

    .page-info-container {
        display: flex;
        max-width: 555px;
        justify-content: flex-start;
        flex-direction: column;
    }

    .codery-info {
        margin-top: 132px;
    }

    .codery-info .heading-l {
        margin-bottom: 20px;
    }

    .heading-m {
        text-align: center;
    }

    .sign-text-wrapper {
        margin-top: 17.5px;
        font-size: $small;
        text-align: center;

        a {
            text-decoration: none;
            color: $deep-purple;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .buttons-wrapper {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 14px;
    }

    .form-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}