@import "style-constants.scss";

.cv-profile-information-wrapper {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;

    .cv-profile-information {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 10px;
        color: $grey-dark;
        margin-bottom: 20px;

        .profile-avatar {
            width: 110px;
            height: 110px;
        }

        .intro-container h1 {
            @media screen and (max-width: $mobile) {
                font-size: 18px;
                letter-spacing: 0.21px;
                margin-bottom: 5px;
            }
        }

        .intro-details-container {
            display: grid;
            grid-auto-flow: column;
            gap: 20px;
            align-items: center;
            justify-content: flex-start;

            p {
                display: grid;
                grid-auto-flow: column;
                justify-content: flex-start;
                align-items: center;
                gap: 2px;

                svg {
                    * {
                        stroke: $purple;
                    }

                    &.client {

                        * {
                            stroke: $orange;
                        }
                    }
                }
            }
        }

        .intro-container h3 {
            @media screen and (max-width: $mobile) {
                font-size: 16px;
                letter-spacing: 0.21px;
            }
        }

        .intro-container p {
            @media screen and (max-width: $mobile) {
                font-size: 12px;
                letter-spacing: 0.15px;
            }
        }

        .avatar-img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;

            @media screen and (max-width: $mobile) {
                width: 65px;
                height: 65px;
            }
        }
    }

    .matching-score-container {
        display: grid;
        grid-auto-flow: row;
        gap: 0;
        justify-content: flex-start;
        height: min-content;
        margin-top: 25px;

        p {
            text-align: right;
        }
    }
}