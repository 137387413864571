@import 'style-constants.scss';

.attached-files-list-container {
    padding-top: 40px;

    .attached-list-title {
        color: #7E7E7E;
        font-size: 16px;
        letter-spacing: 0.15px;
        margin-bottom: 2px;
    }

    .MuiListItem-container {
        .MuiListItem-root {
            padding-left: 0px;
            padding-bottom: 5px;

            .MuiListItemAvatar-root {
                min-width: 40px;

                &.color-green {
                    color: #22D696;
                }
            }
        }

        .MuiListItemSecondaryAction-root {
            right: 8px;

            button {
                padding: 0px;
            }
        }
    }

    &.variant-new {
        padding-top: 0px;
    }
}
