@import 'style-constants.scss';

.button {
    text-transform: none;
    font-size: 14px;
    color: $grey-normal-title;
}

.menu-item {
    margin-top: 14px;
    font-size: 14px;
    color: $grey-normal-title;
    li {
        font-size: 14px;
    }
}

.styled-menu {
    .MuiList-root {
        li {
            margin-top: 6px;
            margin-bottom: 6px;
        }
    }
}
