@import 'style-constants.scss';

.sorry-page-wrapper {
    padding: 200px 0px;
    background-color: $indigo;
    color: $white;
    height: 100vh;

    .sorry-page-content {
        margin: 0 auto;
        padding: 0 215px;
    }

    .sorry-page-title-img-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 -30px;
    }

    .sorry-page-title-wrapper {
        width: 50%;
        padding: 0 30px;

        h2 {
            font-size: 60px;
            line-height: 1.3;
        }
    }

    .sorry-page-img-wrapper {
        width: 50%;
        padding: 0 30px;
        text-align: center;

        img {
            max-width: 550px;
            height: auto;
            vertical-align: middle;
        }
    }

    @media screen and (max-width: 1700px) {
        .sorry-page-content {
            padding: 0 150px;
        }

        .sorry-page-title-wrapper h2 {
            font-size: 37.8px;
        }

        .sorry-page-img-wrapper img {
            max-width: 400px;
        }
    }

    @media screen and (max-width: 1500px) {
        .sorry-page-content {
            padding: 0 100px;
        }
    }

    @media screen and (max-width: 1024px) {
        .sorry-page-content {
            padding: 0 50px;
        }

        .sorry-page-title-wrapper h2 {
            font-size: 34.2px;
        }
    }

    @media screen and (max-width: 900px) {
        .sorry-page-title-wrapper h2 {
            font-size: 30.4px;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 150px 0 100px;

        .sorry-page-title-wrapper {
            width: 100%;
            
            h2 {
                font-size: 24px;
            }
        }

        .sorry-page-img-wrapper {
            width: 100%;
            padding-top: 40px;

            img {
                max-width: 200px;
            }
        }
    }
}
