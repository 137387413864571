@import 'style-constants.scss';

.skills-dropdown-container {
    position: absolute;
    top: 40px;
    width: 100%;
    box-shadow: 0px 1px 10px $grey-darker-button;
    background: $white;
    padding: 5px 7px;
    z-index: 5;

    .skill {
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
        
        cursor: pointer;
    }

    .add-skill-container {
        margin-top: 5px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
            font-weight: 450;
        }
        
        cursor: pointer;
    }
}
