@import 'style-constants.scss';

.export-timesheet-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    .date-fields-wrapper {
        display: flex;
        justify-content: space-between;
    }
    
    .start-date-container,
    .end-date-container {
        width: 47%;
    }
    
    .dialog-actions-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: auto 0 5px 0;
        
        .helper-text-wrapper {
            text-align: right;
            font-size: 12px;
            color: $primary-color-700;
            letter-spacing: 0.4px;
        }

        .dialog-actions {
            padding-right: 0;
        }
    }
}
