@import 'style-constants';

.upcoming-birthdays-paper {
    padding: 31px 0;
    display: grid;
    grid-auto-flow: row;
    gap: 13px;
    overflow: hidden;

    .MuiTypography-h2 {
        color: $purple-dark;
        text-align: left;
        font-size: $medium;
        letter-spacing: 0.11px;
        opacity: 1;

        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;
        font-weight: 400;

        &.bold-text {
            padding-left: 43px; 
            font-weight: 600;
        }
    }

    .carousel-container {
        width: 100%;
        padding-left: 43px; 
        max-width: 800px;
        margin: 0 auto;

        .carousel {
            width: 100%;
        }
    }

    .carousel-slide {
        flex: 0 0 100%;
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */

        .birthday-item {
            height: min-content;
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 15px;
            gap: 18px
        }

        .date-box {
            width: 64px;
            height: 64px;
            display: grid;
            grid-auto-flow: row;
            align-items: center;
            background-color: $indigo;
            padding: 12px 14px;
            border-radius: 10px;
        }

        .MuiTypography-h2.date-item {
            color: $white;
            text-transform: uppercase;
            justify-content: center;
            font-weight: 400;
        }

        .employee-name:hover {
            cursor: pointer;
        }
    }

    .carousel-slide img {
        width: 100%;
        height: auto;
    }

    .carousel-dots {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .carousel-dot {
        width: 10px;
        height: 10px;
        border: 2px solid $purple-dark;
        border-radius: 50%;
        margin: 0 5px;
        cursor: pointer;
    }

    .carousel-dot.active {
        background-color: $purple-dark;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .MuiTypography-h2 {
            font-size: $smallest;
        }

        .carousel-slide .date-box {
            width: 54px;
            height: 54px;
        }

        .carousel-dots {
            width: $upcoming-birthdays-paper-width-small-desktop;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-h2 {
            font-size: $medium;
        }

        .carousel-slide .date-box {
            width: 64px;
            height: 64px;
        }

        .carousel-dots {
            width: $upcoming-birthdays-paper-width-medium-desktop;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-h2 {
            font-size: $medium;
        }

        .date-box {
            width: 64px;
            height: 64px;
        }

        .carousel-dots {
            width: $upcoming-birthdays-paper-width-large-desktop;
        }
    }
}