@import 'style-constants.scss';

.add-profile-dialog {

    .MuiDialog-paper {
        width: $small-dialog-width;
        padding: 0 16px; 
    }

    .dialog-title {
        display: flex;
        padding-bottom: 0;
        padding-right: 8px;
        align-items: center;
        justify-content: space-between;
    }

    .dialog-title-text {
        color: $grey-dark;
        font-size: 16px;
    }
    
    .dialog-actions {
        margin-top: 8px;
        margin-bottom: 14px;
        padding-right: 0;
    }
}
