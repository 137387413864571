@import "style-constants.scss";

.upcoming-interview-card-wrapper {
    position: relative;
    padding: 67px 40px 30px;
    margin-bottom: 0px;

    .upcoming-interview-card-header {
        
        margin-bottom: 28px;

        .position {
            color: $purple-dark;
            font-size: 18px;
            font-weight: 550;
            margin-bottom: 10px;
        }

        .time {
            color: $medium-dark-shade-blue;
            font-size: 16px;
            font-weight: 450;
        }
    }

    .upcoming-interview-card-body {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .candidate-information {
            .candidate-details {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .avatar {
                    height: 52px;
                    width: 52px;
                }

                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }

                span {
                    margin-left: 20px;
                    color: $purple-dark;
                    font-size: 16px;
                    font-weight: 450;
                }
            }

            .location {
                color: $medium-dark-shade-blue;
                display: flex;
                font-size: 16px;
                margin-top: 27px;

                .upcoming-interview-title {
                    margin-right: 5px;
                }
            }
        }

        .date-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .day {
                font-size: 30px;
                color: $purple-dark;
                font-weight: 600;
            }

            .month {
                font-size: 16px;
                font-weight: 500;
                color: $purple-dark
            }
        }
    }
}
