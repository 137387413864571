@import "style-constants.scss";

.start-candidate-journey-desktop-page {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-auto-flow: row;
    gap: 40px;
    padding: 64px 80px;
    overflow: scroll;

    background: url('/assets/start-journey-background.svg') no-repeat center center fixed;
    background-size: cover;

    &.client-view {
        background: url('/assets/start-journey-background-client.svg') no-repeat center center fixed;
        background-size: cover;
    }

    .steps-section {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;

        .step-card {
            display: grid;
            gap: 5px;
            padding: 32px 24px;
            border-radius: 24px;
            background-color: $white;
        }

        .card-heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .card-content {
            display: flex;
            align-self: end;
        }

        .time-wrapper {
            padding: 8px;
            background-color: $dark-grey;
            border-radius: 8px;
            color: $white;
        }
    }

    .buttons-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}