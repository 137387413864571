@import "style-constants.scss";

.application-video-introduction-content-wrapper {
    .title {
        font-size: $medium-title;
        font-weight: bold;
        color: $purple-dark;
    }

    .video-interview-answers-wrapper {
        display: grid;
        gap: 10px;
        margin-top: 16px;
        margin-bottom: 20px;
    }

    .video-wrapper {
        margin-top: 0px;
        width: 100%;
        height: 300px;

        .video-player {
            height: 100%;
        }

        .play-button-wrapper {
            width: 40px;
            height: 40px;

            .play-button-container .play-button {
                color: $primary-color-700;
            }
        }

        .play-button-relative .play-button-container::after {
            content: "";
            display: block;
            position: absolute;
            left: 40%;
            top: 33%;
            width: 12px;
            height: 15px;
            background-color: $white;
            z-index: 1;
        }
    }

    .no-videos-available-wrapper {
        margin-top: 24px;
        margin-bottom: 24px;

        .no-videos-available-message {
            display: grid;
            grid-auto-flow: column;
            gap: 5px;
            align-items: center;
            position: relative;
            justify-content: center;
            width: fit-content;
            margin: 0 auto;
        }
    }
}
