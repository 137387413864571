@import "style-constants.scss";

.clients-table-wrapper {
    .registration-type {

        &.self {
            border: 1.6px solid $orange;

            &.filled {
                background-color: $pink-light;
            }
        }

        &.invited {
            border: 1.6px solid $pink-light;

            &.filled {
                background-color: $orange;
            }
        }
    }

    .legend {
        display: flex;
        height: min-content;
        align-items: center;
        align-self: center;

        .description {
            color: $grey-normal-title;
        }
    }

    .table-body-cell {
        padding: 0;
    }

    .table-body-cell-content {
        display: flex;
        padding: 8px 16px;
        color: $grey-normal-title;
        width: 100%;
        height: 100%;
        min-height: 50px;
        overflow-wrap: break-word;
        justify-content: flex-start;
        align-items: center;
    }

    .clients-table-container {
        margin: 0 auto 20px;
        width: 100%;

        .logo-name-wrapper {
            display: flex;
            align-items: flex-start;
            pointer-events: none;

            .avatar-logo-image {
                width: 30px;
                height: 30px;
                margin-right: 11px;
                background-color: $grey-avatar-background;

                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }

                &.invited-by-admin {
                    border: 2px $secondary-color solid;
                }

                &.self-registered {
                    border: 2px $yellow-light solid;
                }
            }

            .MuiAvatar-colorDefault {
                background-color: $grey-avatar-background;
            }
        }

        .invoice-container {
            display: flex;
            flex-direction: column;
        }

        .clients-table {
            padding: 0 30px 24px;
            overflow: hidden;
        }

        .clients-table>table {
            position: relative;
            min-height: 100px;
        }

        .clients-table-loader {
            position: absolute;
            left: 50%;
            top: 80px;
        }

        .table-head-cell {
            color: #0415ac;
            font-size: 16px;
            font-weight: 500;

            .MuiTableSortLabel-root.MuiTableSortLabel-active,
            .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
                color: $white;
            }
        }

        .table-head-checkbox {
            color: $primary-color;

            &.Mui-checked {
                color: $secondary-color-300;
            }
        }

        .table-body-cell {
            max-width: 250px;

            &.status-cell {
                color: $secondary-color;
            }

            &.name {
                @media screen and (max-width: 1500px) {
                    max-width: 200px;

                    .name-text {
                        max-width: 126px;
                        overflow-wrap: break-word;
                    }
                }
            }

            &.contactEmail {
                a {
                    text-decoration: none;
                    color: #00000099;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                @media screen and (max-width: 1500px) {
                    max-width: 150px;

                    a {
                        max-width: 118px;
                        overflow-wrap: break-word;
                    }
                }
            }

            &.contactPerson {
                max-width: 150px;
            }

            &.accountManager {
                max-width: 100px;
            }
        }
    }

    .table-footer {
        display: flex;
        justify-content: space-between;

        &.clients-table {
            .table-footer-wrapper {
                margin-left: auto;
            }
        }
    }

    @-moz-document url-prefix() {
        td {
            height: auto;
        }
    }
}