@import 'style-constants.scss';

.form-control-checkbox {
    margin-left: 0;
    margin-right: 0;
    
    &.centered {
        width: 100%;
        justify-content: center;
        margin: 0;
    }

    &.smaller {
        .MuiFormControlLabel-label {
            font-size: $smallest;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0.11px;
            margin: auto;
            margin-left: 7px;
        }
    }

    &.error {

        .MuiFormControlLabel-label,
        .MuiCheckbox-root {
            color: rgba($red, 0.6);
        }

        .MuiCheckbox-root:hover {
            background-color: rgba($red, 0.04);
        }
        
        a {
            color: inherit;
        }
    }

    .MuiCheckbox-root {
        color: $purple-dark-light;

        &:hover {
            background-color: rgba($purple-dark-light, 0.04);
        }
    }

    .MuiFormControlLabel-label {
        font-size: $small;
        line-height: 21px;
        letter-spacing: 0.24px;
        color: $purple-darker-light;
        margin-left: 6px;
    }

    a {
        color: $purple;

        &.client {
            color: $deep-orange;
        }

        &.candidate {
            color: $purple;
        }
    }

    @include profile-creation-mobile {

        .MuiFormControlLabel-label,
        &.smaller .MuiFormControlLabel-label {
            font-size: $tiny;
            line-height: 12px;
            letter-spacing: 0.1px;
            margin-left: 0px;
        }

        svg {
            width: 12px;
            height: 12px;

            circle {
                stroke-width: 1;
            }
        }
    }
}