@import "style-constants.scss";

.confirm-restore-user-dialog-wrapper {
    .MuiDialog-paper {
        max-width: 573px;
        padding: 0 16px;
    }

    .confirm-dialog-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        padding-right: 8px;

        .MuiSvgIcon-root {
            width: 18px;
            height: 18px;
        }
    }

    .dialog-content {
        padding-top: 0;
        margin-top: -15px;
    }

    .dialog-text {
        color: #7e7e7e;
        font-size: 16px;
        letter-spacing: 0.11px;
    }

    .dialog-title {
        font-weight: 500;
        word-break: break-all;
    }

    .dialog-subtitle {
        font-size: 20px;
        font-weight: 500;
        color: #2449E1;
        margin: 30px 0 20px 0;
    }

    .dialog-details {
        color: #707070;
        font-weight: 400;
    }

    .dialog-actions {
        margin: 40px 0px 15px 280px;
        display: flex;
    }
}
