@import "style-constants.scss";

.add-profile-form {
    .MuiFormControl-marginNormal {
        margin: 0;
        margin-bottom: 3px;
    }

    .name-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .profile-first-name-field,
        .profile-last-name-field {
            width: 49%;
            margin-top: 12px;
            margin-bottom: 10px;
        }
    }

    .linkedin-field {
        margin-top: 12px;
        margin-bottom: 20px;
    }
}
