@import "style-constants.scss";

.client-projects-wrapper {
    margin-top: 40px;

    .client-projects-container {
        width: 100%;
    }

    .client-projects-container::-webkit-scrollbar {
        width: 0px;
        display: none;
    }

    .client-projects-container > div {
        margin-bottom: 40px;
    }

    h1 {
        color: $purple-dark;
        font-size: medium;
        letter-spacing: 0.11px;
        font-weight: 500;
    }

}

.client-projects-wrapper::-webkit-scrollbar {
    width: 0px;
    display: none;
}
