@import 'style-constants.scss';

.track-time-upload-file-dialog {
    position: relative;

    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-title-text {
        color: $grey-dark;
        font-size: 16px;
    }

    .dialog-actions {
        display: grid;
        grid-auto-flow: column;
        gap: 8px;
        justify-content: flex-end;
        padding: 0 0 30px 0; 
        margin-bottom: 20px;
    }

    .upload-files-form {
        margin: 0px 24px;
    }
}
