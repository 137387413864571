@import "style-constants.scss";

.application-cv-information-wrapper {
    display: grid;
    gap: 30px;
    padding: 30px 0px;
    background-color: $light-shade-blue;

    .cv-container {
        margin: auto;
        width: 37rem;
        display: flex;
        flex-direction: column;
    }

    .export-cv-btn {
        margin-left: auto;
        margin-bottom: 10px;
    }
}
