@import "style-constants.scss";

.job-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3.75rem;

    .job-container {
        width: 100%;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px $grey-darker-button;
        border-radius: 18px;
        margin-inline: auto;
        margin-top: 1.8rem;

        .job-inner {
            position: relative;
            padding: 30px 40px;

            .job-type-label-contract,
            .job-type-label-full-time {
                right: 0px;
                left: auto;
                border-bottom-left-radius: 4px;
                border-top-left-radius: 4px;
                border-bottom-right-radius: 0px;
            }

            .job-header-wrapper {
                display: grid;
                gap: 1.1rem;
                grid-auto-flow: column;
                justify-content: flex-start;
                align-items: center;
            }

            .header-info {
                .job-title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 10px;

                    h4 {
                        font-size: $medium-title;
                        font-weight: 700;
                        letter-spacing: 0.23px;
                        color: $purple-dark;
                        margin-right: 10px;
                    }

                    .label {
                        font-size: $small;
                        line-height: 15px;
                        border-radius: 4px;
                        padding: 5px 10px;
                        text-align: center;

                        &.seen {
                            color: $pink-light;
                            background-color: rgba($pink-light, 0.1);
                            margin-right: 5px;
                        }

                        &.applied {
                            color: $secondary-color;
                            background-color: rgba($secondary-color, 0.1);
                        }
                    }
                }

                .job-company-date-wrapper {
                    display: grid;
                    gap: 18px;
                    grid-auto-flow: column;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 15px;
                }

                .job-company,
                .job-date,
                .job-location {
                    display: flex;
                    align-items: center;

                    p {
                        font-size: $medium;
                        line-height: 21px;
                        letter-spacing: 0.28px;
                        color: $purple-dark;
                    }

                    .icon {
                        margin-right: 8.5px;
                    }
                }
            }

            .divider {
                margin: 20px 0px;
            }

            .job-body {
                width: 100%;
                max-width: 100%;

                .mandatory-skills,
                .nice-to-have-skills,
                .job-description:not(:last-child) {
                    padding-bottom: 30px;
                }

                .description-title {
                    font-size: 17px;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.31px;
                    color: $purple-dark;
                }

                .text-description {
                    margin-top: 20px;
                    text-align: left;
                    
                    font-size: 1rem;
                    line-height: 24px;
                    letter-spacing: 0.28px;
                    color: $purple-dark;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    white-space: -moz-pre-wrap;
                    white-space: -pre-wrap;
                    white-space: -o-pre-wrap;
                    white-space: pre-wrap;
                    word-wrap: break-word;
                }

                .skills-list {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 20px;
                    margin-bottom: -0.7rem;

                    .skill {
                        display: flex;
                        margin-right: 0.7rem;
                        margin-bottom: 0.7rem;
                        width: fit-content;
                        background: $white 0% 0% no-repeat padding-box;
                        border: 1px solid $actions-border;
                        border-radius: 4px;
                        text-align: center;

                        p {
                            font-size: $medium;
                            letter-spacing: 0.29px;
                            padding: 0.4rem 1rem;
                            color: $grey-dark-text;
                        }
                    }
                }
            }
        }
    }

    .apply-button {
        margin-top: 1.8rem;
    }

    .benefits {
        margin-top: 1.8rem;
        max-width: 933.99px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        p,
        h6 {
            font-size: 1rem;
            color: $purple-dark;
            letter-spacing: 0.28px;
        }

        .benefit {
            background: $white;
            width: 48%;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 1px 3px $grey-darker-button;
            border-radius: 18px;

            .benefit-icon {
                margin-top: 1.6rem;
            }

            .benefit-title {
                margin-top: 0.9rem;
                font-weight: 700;
                text-align: center;
            }

            .benefit-text {
                margin-top: 1rem;
                margin-bottom: 2.09rem;
                margin-inline: 1.8rem;
                text-align: center;
            }
        }

        .bottom-benefit {
            margin-top: 1.26rem;
        }
    }

    .footer {
        margin-top: 1.54rem;
        width: 100%;
        max-width: 933.99px;
        background: white 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px $grey-darker-button;
        border-radius: 18px;

        .inner {
            padding-top: 1.59rem;
            padding-bottom: 1.72rem;
            padding-left: 4.53rem;
            padding-right: 4.57rem;
            display: flex;
            justify-content: space-between;

            .key-points {
                display: grid;
                gap: 1.1rem;
                grid-auto-flow: column;

                h6 {
                    font-size: 1.43rem;
                    font-weight: 700;
                    color: $purple-dark;
                    letter-spacing: 0.28px;
                }
            }
        }
    }

    .job-container.mobile {
        width: auto;

        .job-company-date-wrapper {
            gap: 10px;
        }

        .key-points h6 {
            font-size: $medium;
        }

        svg {
            width: 15px;
            height: auto;
        }
    }

    .footer.mobile {
        .key-points {
            display: grid;
            grid-auto-flow: column;
            gap: 8px;
            justify-content: center;
        }

        .key-points h6 {
            display: flex;
            align-self: center;
            vertical-align: middle;
            font-size: $small;
        }

        .key-points svg {
            width: 20px;
            height: 20px;
        }

        .inner {
            padding: 25px 15px;
        }
    }
}
