@import 'style-constants.scss';

.application-status-banner-container {
    padding: 32px;
    display: grid;
    grid-auto-flow: row;
    gap: 48px;
    position: relative;
    margin-bottom: 20px;
    border-radius: 18px;

    .status-text {
        text-align: center;
    }

    .steps-list-container {
        display: grid;
        grid-auto-flow: column;
        gap: 8px;
    }

    .MuiLinearProgress-colorPrimary {
        background-color: $pale-gray;
    }

    .MuiLinearProgress-barColorPrimary {
        background-color: $deep-purple;
    }

    .custom-progress {
        height: 8px;
        border-radius: 32px;
    }

    svg {
        align-self: flex-end;
        position: absolute;
        right: 20px;
        top: -20%;
    }
}
