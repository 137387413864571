@import 'style-constants.scss';

.add-project-form {

    .MuiFormControl-marginNormal {
        margin: 0;
        margin-bottom: 3px;
    }

    .name-type-fields-block {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 34px;

        div {
            width: 100%;
        }

        .type-field {
            margin-left: 20px;
        }
    }

    .switch-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 10px -4px 10px 0;

        .switch-label {
            margin: 0;
        }

        .MuiFormControlLabel-label {
            font-size: 14px;
            letter-spacing: 0.24px;
            color: $grey-normal-title;
            margin-right: 17px;
        }
    }

    .date-fields-block {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 34px;

        .start-date-container {
            width: 100%;
            margin-right: 20px;
        }

        .end-date-field, .end-date-container, .start-date-field  {
            width: 100%;
        }
    
        .calendar-icon {
            cursor: pointer;
            color: $grey-dark;
        }

        .disabled-calendar-icon {
            color: $grey-disabled;
        }
    }

    .contact-person-field{
        margin-bottom: 34px;
    }

    .contact-details-block {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 32px;

        div {
            width: 100%;
        }

        .contact-phone-field {
            margin-left: 20px;
        }
    }
}
