@import 'style-constants';

.time-off-requests-paper {
    .flex-container {
        padding: 31px 43px;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 10px;
    }

    .flex-box {
        display: grid;
        grid-auto-flow: row;
        gap: 8px;
    }

    .MuiTypography-h2 {
        color: $purple-dark;
        text-align: left;
        font-size: $medium;
        letter-spacing: 0.11px;
        opacity: 1;
        font-weight: 400;
        
        &.bold-text {
            font-weight: 600;
        }
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .MuiTypography-h2 {
            font-size: $smallest;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-h2 {
            font-size: $medium;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-h2 {
            font-size: $medium;
        }
    }
}
