@import "style-constants.scss";

.slide-wrapper {
    .green-square {
        width: 22px;
        height: 22px;
        transform: matrix(0.54, -0.84, 0.84, 0.54, 0, 0);
        border: 2px solid $secondary-color-300;
        border-radius: 4px;
        opacity: 1;
    }

    .purple-square {
        width: 18px;
        height: 18px;
        transform: matrix(0.54, -0.84, 0.84, 0.54, 0, 0);
        border: 2px solid $purple-dark;
        border-radius: 4px;
        opacity: 1;
        margin-top: 60px;
    }

    .image-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 70px;
    }

    .slide-content {
        margin-top: 40px;
        white-space: normal;

        .title {
            color: $purple-dark;
            font-size: 22px;
            font-weight: bold;
        }

        .text {
            font-size: 16px;
            margin-top: 20px;
            color: $purple-dark;
        }
    }
}
