@import 'style-constants.scss';

.individual-training-topics-wrapper {

    .topics-content {
        padding: 30px 40px 20px 30px;

        > div:not(:last-child) {
            border-bottom: solid $grey-light-amount 1px;
            padding-bottom: 10px;
        }
    }
}
