@import "style-constants.scss";

.interview-invitation-card-wrapper {
    padding: 30px 40px 40px 40px;
    width: 48%;
    position: relative;
    overflow: hidden;

    &.max-width {
        width: 100%;
    }

    .pro-label-container {
        background-color: $black;
        color: white;
        padding: 6px;
        width: 100px;
        transform: skew(-20deg);
        position: absolute;
        top: 0;
        right: -6px;
        text-align: center;

        .content {
            font-weight: bold;
            transform: skew(20deg);
        }
    }

    .information-container {
        border-bottom: $grey-light-amount solid 1px;
        padding-bottom: 9px;

        .information-container-title {
            font-size: 20px;
            font-weight: 550;
            color: $primary-color-400;
        }

        .information-container-content {
            margin-top: 15px;
            .content-wrapper {
                display: flex;
                gap: 5px;
                margin-top: 6px;

                .title {
                    color: $grey-dark;
                    font-size: 14px;
                }

                .content {
                    color: $primary-color-400;
                    font-size: 14px;
                }
            }
        }
    }

    .date-container {
        margin-top: 15px;

        .date-container-button {
            display: flex;
            gap: 20px;
            justify-content: flex-end;
            margin-top: 40px;
        }

        .date-container-title {
            font-size: 16px;
            font-weight: 600;
            color: $primary-color-700;
        }

        .date-container-description {
            font-size: 12px;
            color: $grey-lighter;
            margin-top: 20px;
        }

        .date-container-content {
            margin-top: 20px;

            .date-block-checkbox {
                &.Mui-checked {
                    color: $secondary-color-300;
                }
            }

            .date-block-container {
                margin-top: 10px;
                padding: 10px 20px;
                display: flex;
                justify-content: space-between;
                background-color: #E5E5E5;
                align-items: center;

                .date-block-container-content {
                    display: flex;
                    gap: 10px;
                    color: $grey-lighter;

                    .date-block-icon {
                        rect, path {
                            fill: $grey-lighter;
                        }
                    }

                    .date-block-icon.date {
                        width: 20px;
                    }
            
                    .date-block-icon.hour {
                        width: 30px;
                        margin: 0 -5px;
                    }
                }

                .hour {
                    margin-left: 25px;
                }
            }
        }
    }
}
