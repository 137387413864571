@import 'style-constants.scss';

.previous-button {
    color: $dark-grey;
    font-size: $subheading-l;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 15px;
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    gap: 0px;

    &:hover {
        cursor: pointer;
    }

    @media screen and (max-width: $small-desktop) {
        font-size: $tiny;
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        font-size: $small;
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        font-size: $small;
    }
}
