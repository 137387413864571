@import "style-constants.scss";

.history-holidays-wrapper {
    .approval-status {
        text-transform: capitalize;

        &.approved {
            color: $secondary-color-300;
        }

        &.rejected {
            color: $red-darker;
        }
    }

    .signature-status-wrapper {
        display: flex;
        align-items: center;

        span {
            text-transform: capitalize;
            margin-right: 10px;
        }

        .sign.pending,
        .sign.rejected {
            color: $red-darker;
        }

        .signed {
            color: $secondary-color-300;
        }

        .information-icon {
            color: $actions-green;
        }
    }
}
