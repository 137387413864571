@import 'style-constants';

.banner-wrapper {
    background-color: $purple-dark;
    padding: 30px 50px;
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
    grid-template-columns: 70% 30%;
    margin-bottom: 20px;

    .title-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
    }

    .content-wrapper {
        display: grid;
        grid-auto-flow: row;
        gap: 11px;
    }

    .banner-buttons-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
    }

    &.vertical {
        grid-auto-flow: row;
        grid-template-columns: none;

        .title-wrapper {
            display: grid;
            grid-auto-flow: row;
            gap: 8px;
            justify-content: flex-start;
            align-items: center;
        }
    
        .content-wrapper {
            display: grid;
            grid-auto-flow: row;
            gap: 11px;
        }
    
        .banner-buttons-wrapper {
            display: grid;
            grid-auto-flow: column;
            gap: 10px;
            align-items: center;
            justify-content: flex-start;
        }
    }

    .MuiTypography-body1.bold-title {
        font-weight: bold;
    }

    .MuiTypography-body1 {
        color: $white;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .MuiTypography-body1 {
            font-size: $tiny;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-body1 {
            font-size: $small;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-body1 {
            font-size: $small;
        }
    }
}