@import "style-constants.scss";

.contracts-table-wrapper {
    .signature-status-wrapper {
        display: flex;
        align-items: center;

        .Signed,
        .information-icon {
            color: $secondary-color-300;
        }

        .Sign.pending {
            color: $red-darker;
        }

        .Signed,
        .Sign.pending {
            margin-right: 10px;
        }
    }
}