@import 'style-constants.scss';

.expired-link-wrapper {
    .expired-link-content-text {
        font-size: $medium-title;
        line-height: 26px;
        letter-spacing: 0.17px;
        color: $medium-shade-blue;
        text-align: center;
    }

    .expired-link-footer-text {
        margin-top: 45px;
        font-size: $smallest;
        line-height: 18px;
        letter-spacing: 0.08px;
        color: $medium-shade-blue;
        text-align: center;

        .email {
            color: $teal;
        }
    }

    @include profile-creation-mobile {
        .expired-link-content-text {
            font-size: $medium;
            letter-spacing: 0.15px;
        }

        .expired-link-footer-text {
            margin-top: 14.5px;
            font-size: $tiny;
        }
    }
}
