@import 'style-constants.scss';

.ask-recruiter-banner-container {
    margin: auto;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px $grey-medium-shadow;
    border-radius: 24px;
    padding: 32px;
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    gap: 60px;

    .ask-recruiter-button {
        margin: auto;
    }

    .ask-recruiter-banner-content {
        .banner-body-text {
            padding-top: 10px;
            padding-bottom: 20px;
        }
    }
}
