@import 'style-constants.scss';

.video-content { 
    > div:not(:last-child) {
        border-bottom: solid $grey-light-amount 1px;
        padding-bottom: 40px;
    }

    .text-video-height { 
        height: 100px;
        margin-bottom: 20px;
        margin-top: 11px;
        
        .center-video-text{ 
            color: rgba(0, 0, 0, 0.54);
            text-align: center;
            padding: 35px;
        }     
    }

    .nothing-to-show-wrapper.table-view {
        padding-top: 0;
    }
}
