@import 'style-constants.scss';

.job-searches-job {
    min-height: 200px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 75% 25%;
    gap: 17px;

    .job-searches-job-matching-candidates {
        padding: 18px 32px;
        border-radius: 24px;
        background-color: $white;
        display: grid;
        grid-auto-flow: row;
        gap: 3px;
        display: grid;
        grid-auto-flow: row;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
        max-height: 200px;
    }

    .job-search-matching-candidates-search-again {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-end;
        gap: 10px;

        svg:hover {
            cursor: pointer;
        }

        svg {
            * {
                stroke: $deep-orange;
            }
        }
    }

    .job-searches-job-card-footer {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;

        .job-searches-job-card-footer-info {
            display: grid;
            grid-auto-flow: column;
            gap: 30px;
            justify-content: flex-start;
        }

        .job-searches-job-card-footer-info-container {
            display: grid;
            grid-auto-flow: column;
            gap: 5px;
            justify-content: flex-start;
            align-items: center;
        }

        .job-searches-job-card-footer-icon {
            * {
                stroke: $grey;
            }
        }
    }

    .job-searches-job-card-footer-actions {
        display: grid;
        grid-auto-flow: column;
        gap: 3px;
        align-items: center;

        .job-searches-job-card-footer-view-details {
            color: $deep-orange;
            font-size: $subheading-l;
            font-weight: 500;
            line-height: 26px;

        }

        svg {
            margin-top: 3px;

            * {
                stroke: $deep-orange;
            }

            &.arrow-up {
                margin-top: 8px;
            }

            &.arrow-down {
                margin-bottom: 5px;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .job-searches-job-card {
        padding: 22px 32px;
        border-radius: 24px;
        background-color: $white;
        display: grid;
        grid-auto-flow: row;
        gap: 26px;
        box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
    }

    .job-searches-job-matching-candidates-container {
        display: grid;
        grid-auto-flow: row;
        align-items: center;
        justify-content: center;
        gap: 10px;

        p {
            text-align: center;
        }
    }

    .job-searches-job-card-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;

        .job-searches-job-card-header-info {
            display: grid;
            grid-auto-flow: column;
            gap: 10px;
            align-items: center;
        }

        .job-searches-job-card-header-status {
            border-radius: 8px;
            padding: 5px 15px;
            min-width: 94px;
            text-align: center;
            text-transform: capitalize;

            &.open {
                border: 1px solid $green-darker;
                background-color: $pale-green;
                color: $green-darker;
            }

            &.closed {
                border: 1px solid $deep-orange;
                background-color: $pale-orange;
                color: $deep-orange;
            }
        }
    }

    .job-searches-job-card-options {
        display: grid;
        grid-auto-flow: column;
        gap: 5px;

        svg:hover {
            cursor: pointer;
        }
    }

    .job-searches-job-details-section {
        margin: 15px 0;
    }

    .job-searches-job-details-skills {
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        justify-content: flex-start;

        .skill-box {
            padding: 5px 10px;
            background-color: $pale-orange;
            border-radius: 100px;
        }
    }
}