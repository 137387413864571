@import "style-constants.scss";

.track-time-dialog {
    .dialog-content {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .dates-container {
            min-width: 50%;

            .DayPicker * {
                outline: none;
            }

            .DayPicker-NavBar {
                display: none;
            }

            .DayPicker-Caption {
                text-align: center;
                padding-bottom: 5px;
            }

            .DayPicker-Day {
                padding: 14px;
                font-size: 14px;
                line-height: normal;

                .day-wrapper.holiday {
                    color: $grey-light-amount;
                }
            }

            .DayPicker-Day--selected {
                background-color: unset;
                padding: 5px;

                .day-wrapper {
                    padding: 9px;
                    border-radius: 50%;
                    background-color: #d0f7ea;
                    color: $secondary-color;
                    font-weight: bold;
                }
                .day-wrapper.holiday {
                    color: $secondary-color;
                }
            }

            .DayPicker-Day--today {
                color: unset;
                font-weight: unset;
            }

            .DayPicker-Weekdays {
                font-weight: 500;
                border-bottom: 1px solid #e9e9f0;
                padding-bottom: 3px;
            }

            .DayPicker-Month {
                margin: 0;
            }
        }

        .input-container {
            display: flex;
            flex-direction: column;
            margin-left: 41px;
            width: 100%;

            .project {
                margin-bottom: 34px;
            }

            .tasks-desc {
                margin-top: 32px;

                &.hours-err {
                    margin-top: 15px;
                }
            }
        }

        .project-actions {
            display: flex;
            justify-content: flex-end;
        }
    }

    .dialog-actions {
        padding-top: 20px;
    }

    .selected-project {
        border: 2px solid #5066e7;
    }

    .track-time-upload-file-container {
        position: relative;

        .track-time-upload-image {
            position: absolute;
            left: 249px;
            top: 13px;
            width: 30px;
            cursor: pointer;
        }

        .track-time-upload-text-field {
            width: 100%;
        }
    }
}

.sick-leave-select {
    color: $yellow;
}

.bench-activities-select {
    color: $red-darker;
}
