@import "style-constants.scss";

.profile-summary-wrapper {
    .title {
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .summary {
        margin: 20px 0px 0px;
        white-space: pre-line;
        
        @media screen and (max-width: $mobile) {
            font-size: 14px;
            letter-spacing: 0.13px;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px $purple-dark solid;

        .item-edit-icon-wrapper {
            cursor: pointer;

            svg:hover {
                cursor: pointer;
    
                rect {
                    fill: $purple;
                }
    
                path {
                    stroke: $white;
                }
            }
        }
    }
}
