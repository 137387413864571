@import 'style-constants.scss';

.navigation {
    font-size: $subheading-l;
    width: $navigation-width;
    transition: width 0.3s;
    z-index: 1003;
    transform: translate3d(0, 0, 0);

    .MuiDrawer-paper {
        position: relative;
        white-space: nowrap;
        background-color: $dark-grey;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
        overflow: hidden;
    }

    .divider {
        margin: 0 auto;
        width: 75%;
        border: 1px solid $grey;
    }

    .navigation-header {
        display: grid;
        grid-auto-flow: row;
        gap: 32px;
        margin-top: 40px;

        .company-logo {
            margin: auto;
        }

        .header-title {
            font-size: $small;
            font-weight: bold;
            line-height: 19px;
            letter-spacing: 0.24px;
            color: $white;
            width: 99px;
        }

        .navigation-header-divider-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .header-divider {
                width: 146px;
                height: 1px;
                background-color: $white;
            }

            .header-arrow-icon {
                cursor: pointer;
                color: $white;
            }
        }

        .MuiAvatar-colorDefault {
            background-color: $grey-avatar-background;
        }
    }

    .user-info-wrapper {
        display: flex;
        justify-content: center;
        align-self: center;
    }

    .navigation-additional-menu {
        width: 75%;
        margin: 0 auto;
    }

    .list-item {
        color: $grey;
        padding: 12px 24px;
        transition: padding 0.3s;
        position: relative;
        border-radius: 16px;

        span {
            font-size: $subheading-l;
            font-weight: 700;
            line-height: 26px;
        }

        .MuiListItemText-root {
            margin: 0;
            min-width: 125.5px;
        }

        &.expandable {
            .MuiListItemText-root {
                min-width: 101.5px;
            }
        }

        &.Mui-selected {
            background-color: $grey;
            color: $white;

            &.expanded {
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }
    }

    .orange {
        * {
            stroke: $orange;
        }
    }

    .Mui-selected {
        .orange {
            * {
                stroke: $deep-orange;
            }
        }
    }

    .list-item-icon-wrapper {
        display: flex;
        width: 24px;
        margin-right: 14px;
    }

    .MuiListItemIcon-root {
        min-width: 24px;
    }

    .navigation-footer {
        display: grid;
        grid-auto-flow: row;
        justify-content: flex-start;
        align-self: center;
        position: absolute;
        bottom: 0;
        margin-bottom: 30px;
        width: 75%;

        .divider {
            width: 203px;
        }

        .navigation-footer-content {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            align-self: center;
            gap: 7px;
            margin-top: 24px;

            .logo {
                width: 64px;
                transition: width 0.3s;
                height: auto;
            }
        }
    }


    .additional-menu-btn-icon {
        margin-right: 13px;
        padding-top: 10px;
        padding-bottom: 10px;

        &.grey {
            color: $white;
        }

        &.blue {
            color: $primary-color-400;
        }
    }

    .MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
        white-space: normal;
    }

    .navigation-footer-mobile-wrapper {
        display: none;
    }

    .close-mobile-navigation-button {
        display: none;
    }

    .language-selector {
        margin: 0 auto;
    }

    &.navigation-close .language-selector {
        margin: 0 auto;
    }


    @media screen and (min-width: $desktop) {
        &.navigation-close .navigation-header {
            .header-title {
                display: none;
            }

            .header-divider {
                width: 32px;
            }

            .header-arrow-icon {
                transform: rotate(180deg);
            }
        }

        &.navigation-close {
            width: $navigation-width-closed;

            .list-item {
                padding: 15px 9.5px 15px 30px;

                .MuiListItemText-root {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            .list-item-icon-wrapper {
                margin-right: 3px;
            }
        }

        &.navigation-close .navigation-footer {
            padding: 20px 20px 30px 18px;
            justify-content: center;
            flex-direction: column-reverse;

            .additional-menu-btn-icon {
                margin-right: 0px;
                padding-top: 13px;
                padding-bottom: 13px;
            }

            >img {
                width: 52px;
                height: auto;
            }

            >div {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        width: 100%;
        position: relative;
        z-index: 2000;
        background-color: $purple-dark;

        .MuiDrawer-paper {
            height: auto;
        }

        .close-mobile-navigation-button {
            display: flex;
            position: absolute;
            right: 32px;
            top: 36px;
            z-index: 2001;
            cursor: pointer;

            svg {
                width: 28px;
                height: 28px;
                color: $white;
            }
        }

        .navigation-header {
            margin-bottom: 6px;
            padding: 36px 0px 7px 48px;
            position: relative;

            .navigation-header-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: flex-start;
            }

            .header-title {
                font-size: 20px;
                font-weight: 400;
                margin-bottom: 4px;
                width: 100%;
                color: $grey-light;
            }

            .company-logo {
                width: 79px;
                height: 79px;
                margin-bottom: 13px;
                margin-right: 0;
            }

            .navigation-header-divider-wrapper {
                display: none;
            }
        }

        .nav-main-list {
            padding-bottom: 0;
        }

        .list-item {
            padding: 10px 38px 10px 38px;
            position: relative;
            transition: padding .3s;
            color: $white;
            text-transform: none;
            border-radius: 0;

            .MuiListItemText-root {
                margin: 4px 0px;
            }

            span {
                font-size: $medium;
                line-height: 1.5;
            }
        }

        .nested-list .nested-list-item {
            span {
                font-size: $medium;
                font-weight: 400;
                line-height: 1.5;
            }
        }

        .list-item-icon-wrapper {
            display: none;
        }

        .list-item-icon {
            display: none;
        }

        .navigation-footer {
            display: none;
        }

        .additional-menu-btn-icon {
            display: none;
        }

        .navigation-footer-mobile-wrapper {
            display: block;
        }

        &.navigation-mobile-hide {
            display: none;
        }
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {

        .list-item svg,
        .nested-list-item .list-item-icon svg {
            width: 22px;
            height: 22px;
        }

        .list-item span {
            font-size: $smallest;
        }

        .nested-list-item span {
            font-size: $smallest;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .list-item span {
            font-size: $small;
        }

        .nested-list-item span {
            font-size: $small;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .list-item span {
            font-size: $small;
        }

        .nested-list-item span {
            font-size: $small;
        }
    }
}