@import 'style-constants.scss';

.language-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;

    .language-level-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .language-delete-icon {
        width: 30px;
        height: 30px;
        color: #707070;
        cursor: pointer;
    }

    .language-delete-icon:hover {
        color: $primary-color;
    }

    .language-select {
        margin-right: 20px;
    }
}
