@import "style-constants.scss";

.details-header {
    width: 100%;
    display: flex;
    align-items: center;

    .profile-logo {
        width: 70.21px;
        height: 70.21px;
    }

    .candidate-preferences-details {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name-container {
            max-width: 200px;
            margin-left: 20.42px;

            .candidate-name {
                color: $purple-dark;
                font-size: $medium-title;
                font-weight: bold;
                letter-spacing: 0.31px;
            }
        }

        .preferences-data {
            display: flex;
            align-items: center;
            flex-grow: 2;
            justify-content: space-evenly;

            .preference-item {
                position: relative;
                display: grid;
                gap: 5px;

                .preference-value {
                    color: $purple-dark;
                    font-size: $medium;
                    letter-spacing: 0.28px;
                    cursor: pointer;
                }
            }

            .preference-item:not(:first-child) {
                margin-left: 37.4px;
            }
        }
    }

    .candidate-name-link {
        text-decoration: none;
    }
}
