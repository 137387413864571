@import "style-constants.scss";

.add-contact-person-dialog-container {
  .add-contact-person-form {
    display: grid;
    grid-auto-flow: row;
    gap: 20px;

    .dialog-title {
      padding-bottom: 0;
      padding-right: 8px;
      display: flex;
      justify-content: space-between;
      color: $grey-dark;
      width: 100%;

      p {
        font-size: 17px;
        letter-spacing: 0.11px;
      }

      .dialog-title-text {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}