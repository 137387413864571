@import "style-constants.scss";

.job-options-popover {
    border-radius: 4px;
    box-shadow: 0px 1px 10px $grey-darker-button;

    .MuiDivider-root {
        margin-bottom: 10px;
    }

    .popover-list {
        width: 225.45px;
        padding: 22.2px 20.32px 0px 20.13px;

        a {
            text-decoration: none;
        }

        .popover-list-item {
            padding: 0;
            margin-bottom: 16.23px;
            color: $grey-normal-title;

            .info-icon {
                font-size: 11px;
                fill: $grey-normal-title;
                position: absolute;
                right: 28px;
                top: 0px;
            }

            .job-popover-icon {
                display: flex;
                margin-right: 10px;
            }

            .job-popover-text {
                align-items: center;
                font-size: 14px;
                font-weight: 300;
                line-height: 21px;
                letter-spacing: 0.24px;
            }
        }
    }

    .status-options {
        margin: 16.4px 0;
        height: 30px;
        border-radius: 4px;
        display: flex;

        button {
            padding: 2px;
            flex-basis: 50%;
            border: none;
            letter-spacing: 1.25px;
            font-size: $small;
            color: $grey-dark;
            text-transform: capitalize;
            cursor: pointer;
        }

        .white-bg {
            border: 1px solid #dddddd;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: $white;
        }

        .green-bg {
            background-color: $secondary-color-300;
            color: $white;
            box-shadow: 0px 3px 6px $black-grey-shadow;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .grey-bg {
            box-shadow: 0px 3px 6px $black-grey-shadow;
            border-radius: 4px;
        }
    }

    .filter-list-menu-item {
        padding: 7px 12px 7px 20.32px;
        font-size: $small;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0.24px;
        color: $purple-dark;
        justify-content: space-between;
        align-items: center;
        min-height: 38px;

        .flex-group {
            display: grid;
            grid-auto-flow: column;
            gap: 13px;
        }

        .rectangle {
            width: 4px;
            height: 17px;
            border-radius: 1px;

            &.high {
                background-color: $red-darker;
            }

            &.medium {
                background-color: #FAC800;
            }

            &.low {
                background-color: $grey-dark;
            }
        }

        &.selected {
            background-color: rgba($primary-color-700, 0.06);
        }

        .text {
            color: $grey-dark-text;
            font-size: $small;
            letter-spacing: 0.24px;
            font-weight: normal;
            display: block;
            margin: auto;
        }
    }

    .priority-item {
        color: $grey-dark-text;
        font-size: $small;
        padding: 15px 20.32px 10px 20.32px;
        letter-spacing: 0.24px;
        font-weight: 500;
    }

    .no-padding {
        padding: 0px;
    }

    .check-icon {
        fill: $secondary-color-300;
    }
}