@import "style-constants.scss";

.search-results {
    width: 100%;
    margin: 20px auto;

    .actions-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: 12px;

        .title {
            margin-right: 10px;
        }
    }

    .result-category {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .result-list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid #cbcbcb;
        border-radius: 5px;
        padding: 10px 25px 10px;

        .main-info {
            color: $primary-color;
            font-size: 14px;
            font-weight: 700;

            .MuiListItemText-primary {
                margin-bottom: 5px;
            }

            .name-info {
                margin-top: 15px;
                margin-left: 5px;
            }
        }

        .secondary-info,
        .main-info {
            display: grid;
            margin-bottom: 5px;
            align-items: flex-start;
            grid-template-columns: 1fr 4fr;
            margin-bottom: 5px;

            .MuiListItemText-primary {
                font-weight: bold;
                margin-bottom: 5px;
            }

            .MuiListItemText-secondary {
                font-size: 13px;
            }
        }
        .main-info {
            align-items: center;
        }

        .search-result-avatar {
            background-color: $grey-avatar-background;

            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .MuiAvatar-colorDefault {
            background-color: $grey-avatar-background;
        }

        .secondary-info > .MuiListItemText-root {
            flex-grow: 0;
            margin-left: 60px;
            color: $grey-dark;

            .MuiListItemText-primary {
                font-size: 13px;
            }
        }
        .email-field {
            word-wrap: break-word;
            flex: 0 0 150px;
        }
        .phone-field {
            word-wrap: break-word;
            flex: 0 0 120px;
        }
        .projects-field {
            flex-grow: 1;
        }
    }
    .card-view-wrapper {
        margin-bottom: 25px;
        display: flex;
    }
 
    .restore-profile {
        background-color: #dc083a;
        padding: 5px 10px;
        position: absolute;
        right: 10px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        cursor: pointer;

        button {
            color: #fff;
            font-size: 16px;
            text-transform: none;
        }

        .restore-icon {
            color: #fff;
            font-size: 2rem;
        }
        .restore-user-tooltip {
            display: flex;
            align-items: center;
            pointer-events: auto;
            margin-left: 10px;
    
            &:hover::after {
                content: attr(tooltip);
                position: absolute;
                bottom: 65%;
                left: -45px;
                font-size: 12px;
                font-weight: 400;
                color: #00000099;
                box-shadow: 0px 1px 10px #00000033;
                background: #fafafa;
                width: 166px;
                margin-bottom: 10px;
                padding: 13px 10px;
                z-index: 12;
            }
        }
    }

}
