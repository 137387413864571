@import "style-constants.scss";

.role-selection-wrapper {
    form {
        padding: 0px 40px;
    }

    .options-labels-wrapper {
        display: grid;
        gap: 20px;
        padding-top: 55px;
        padding-bottom: 18px;

        .invalid-field-message {
            margin-top: -15px;
        }

        .options-label {
            margin: 0;
            justify-content: space-between;
            padding: 12px 30px;
            border-radius: 16px;
            background-color: $white;
        }
    }

    .terms-and-conditions-checkbox-wrapper {
        margin-bottom: 12px;

        .invalid-field-message {
            margin-top: -7px;
            padding-left: 12px;
        }

        .terms-and-conditions-label {
            .MuiCheckbox-colorPrimary.Mui-checked {
                color: $secondary-color-300;
            }
    
            a {
                color: $pale-purple;
            }
        }
    }
}
