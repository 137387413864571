@import "style-constants.scss";

.coaching-mentoring-details-wrapper {
    position: relative;
    min-height: 200px;

    .coaching-mentoring-details-content {
        padding: 30px 42px;
    }

    .coach-info {
        display: flex;

        .profile-logo {
            width: 52px;
            height: 52px;
        }

        .coach-name {
            font-size: $medium-title;
            color: $purple-dark;
            letter-spacing: 0.31px;
            display: flex;
            align-self: center;
            padding-left: 21px;
            word-break: break-word;
        }
    }

    .icon {
        fill: $grey-lighter;
    }

    .centered {
        display: flex;
        position: absolute;
        left: 48%;
        top: 40%;
    }

    .coaching-mentoring-details-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0 0 4px 4px;
        background-color: $grey-background-light;
        padding: 23px 40px;

        h1 {
            font-size: $medium-title;
            color: $purple-dark;
            letter-spacing: 0.61px;
            word-break: break-word;
        }
    }

    .languages-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-bottom: -5px;
        margin-left: 15px;

        .language-item {
            padding: 5px 12px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 4px;
            background-color: $primary-color-light;
            color: $primary-color-700;
            font-size: $small;
            font-weight: 500;
            letter-spacing: 0.24px;
            height: fit-content;
        }
    }
}

