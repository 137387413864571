@import 'style-constants.scss';

.employee-status-banner-wrapper {
    padding: 40px;
    background-color: $purple-dark;
    border-radius: 15px;
    display: grid;
    grid-template-columns: 1fr auto; 
    grid-template-rows: 1fr auto;
    grid-template-areas: 
            'text icon'
            'actions icon';
            gap: 20px;

    .employee-status-text {
        font-size: $medium-title;
        font-weight: 400;
        letter-spacing: 0.31px;
        color: $white;
        grid-area: text;

        .bold {
            font-weight: 700;
            font-size: $medium-title;
        }
    }

    .employee-status-actions-wrapper {
        grid-area: actions;
    }

    .employee-status-icons-wrapper {
        max-width: 115px;
        max-height: 85px;
        display: flex;
        justify-items: center;
        align-items: center;
        grid-area: icon;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}
