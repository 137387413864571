@import 'style-constants.scss';

.multi-select-autocomplete {
    width: auto;
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        padding: 0;
        font-size: 14px;
        color: $grey-normal-title;
    }

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding-right: 0;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
        padding: 7.5px 4px;
    }

    .multi-select-autocomplete-input-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 20px;
        .search-icon.icon {
            position: absolute;
            left: 0;
        }
    }

    .MuiAutocomplete-endAdornment {
        display: none;
    }
}

.multi-select-autocomplete-list-box {
    height: 400px;
    overflow: auto;

    .MuiListSubheader-root {
        background-color: #e5e5e5;
        line-height: 28px;
        color: #3340b9;
        font-weight: 500;
    }

    .MuiAutocomplete-option {
        padding: 0;
        font-size: 14px;
        font-weight: 300;
        padding-right: 9px;
        padding-left: 9px;
    }

    .multi-select-autocomplete-list-box-option{
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid #e5e5e5;
    }

    .MuiCheckbox-root {
        color: $grey-lighter;
        padding-left: 0;
        &.Mui-checked {
            color: $secondary-color;
        }
    }

    .MuiAutocomplete-groupUl {

        .MuiAutocomplete-option{

            .multi-select-autocomplete-list-box-option:last-child() {
                border: none;
            }
        }
    }
}
