@import 'style-constants.scss';

.supplier-employee-profile-content-wrapper {
  .additional-actions-wrapper {
    margin-bottom: 30px;
    display: flex;

    .switch-block {
      display: flex;
      flex: 1 1 60%;

      .switch {
        display: inline-block;
        
        font-size: 12px;
        color: $grey-dark;

        &.currently-available {
          padding-left: 35.96px;
        }
      }
    }

    .buttons-wrapper {
      height: fit-content;
      margin-left: auto;
      display: flex;
      justify-content: space-between;
    }

    .btn {
        margin-left: 10px;
    }
  }


  .profile-info-wrapper, .profile-education-wrapper {
    .actions-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 10px 0;

      .personal-form-save-button,
      .basic-info-save-button,
      .contract-information-save {
          margin-right: 20px;
      }
    }
  }

  .personal-info-wrapper {
      width: 100%;

      .actions-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 10px 0;

          .personal-form-save-button,
          .basic-info-save-button,
          .contract-information-save {
              margin-right: 20px;
          }
      }

      .personal-info-content {
          padding: 40px;
          margin: 0px auto;
          width: 100%;
      }

      .personal-info-form-field {
          margin-bottom: 34px;
          text-align: left;

          &.last {
              margin-bottom: 0;
          }
      }
  }

  .profile-info-content {
    padding: 40px;
    margin-bottom: 20px;
    border-radius: 18px;
  }

  .profile-info-form-field {
      margin-bottom: 34px;

      &.last {
          margin-bottom: 0;
      }
  }

  .profile-skills-info-wrapper .buttons-wrapper,
  .profile-education-wrapper .buttons-wrapper,
  .profile-projects-wrapper .buttons-wrapper {
    height: fit-content;
  }
}
