@import "style-constants.scss";

.left-aligned-section-actions-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 20 1 400px;
    gap: 7px;

    .search-term-field {
        font-size: 14px;
        border: 1px solid;
        border-collapse: separate;
        border-radius: 4px;
        border-color: rgba(0, 0, 0, 0.23);

        .search-icon {
            color: #00000099;
            margin-left: 2px;
        }
    }

    @media screen and (max-width: 1751px) {
        .search-term-field {
            min-width: 82px;
        }
    }

    .date-picker-container {
        .MuiFormControl-root .MuiInputBase-root {
            input {
                padding: 7px;
                color: #00000099;
                text-align: center;
                width: 80px;
            }
        }
    }

    .notification-button-container {
        span {
            color: $white;
        }

        .red {
            background-color: #DC083A;
        }

        .orange {
            background-color: $orange;
        }

        .primary-light-blue {
            background-color: $primary-color-400;
        }

        .lower-case-button {
            span {
                text-transform: none;
            }
        }
        .red-darker {
            background-color: $red-darker;
        }
        .primary-color-700 {
            background-color: $primary-color-700;
        }
        .grey-dark-text {
            background-color: $grey-dark-text;
        }
    }
}

.date-picker-popover {
    .MuiPickersToolbar-toolbar {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
        height: auto;
        background-color: $purple-dark;
    }

    .MuiPickersToolbarText-toolbarTxt,
    .MuiTypography-subtitle1,
    .MuiPickersMonth-monthSelected,
    .MuiPickersYear-yearSelected {
        letter-spacing: 0.21px;
        font-size: $small;
    }

    .MuiPickersMonth-monthSelected,
    .MuiPickersYear-yearSelected {
        font-weight: bold;
        color: $purple-dark;
    }

    .MuiPickersMonthSelection-container {
        width: auto;
        background-color: $grey-light-background;

        .MuiTypography-subtitle1 {
            color: $purple-dark;
        }
    }

    .MuiPickersYearSelection-container,
    .MuiPickersCalendarHeader-iconButton {
        background-color: $grey-light-background;
    }

    .MuiPickersBasePicker-pickerView {
        background-color: $grey-light-background;
        width: auto;
        height: auto;
        min-height: 0px;
        min-width: 150px;
    }

    .MuiPickersMonth-root {
        height: 45px;
    }

    .MuiPaper-rounded {
        border-radius: 18px;
        box-shadow: none;
        margin-top: 11px;
        margin-left: 50px !important;
    }
}
