@import 'style-constants.scss';

.add-invoice-form {    
    .invoice-fields-container {
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        grid-template-columns: 49% 49%;
        margin: 20px 0;
    }

    .invoice-field-full  {
        width: 100%;
        margin-bottom: 20px;
    }
    
    .calendar-icon {
        cursor: pointer;
        color: $grey-dark;
    }    

    .info-message{
        font-size: 0.75rem;
        color: $red-darker;
    }
}

.invoice-dialog-period-popover {
    .MuiPopover-paper {
        overflow-y: unset;
        overflow-x: unset;
        max-height: calc(100% - 30px);
    }
}
