.edit-invoice-status-dialog {
    .MuiDialog-paper {
        width: 394px;
        padding: 0 16px; 
    }

    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-title-text {
        color: #7E7E7E;
        font-size: 16px;
    }
    
    .dialog-actions {
        padding-right: 0;
    }

    .MuiDialogContent-root.dialog-content {
        overflow-y: 'hidden'; 
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: scroll;
    }

    .MuiDialogContent-root.dialog-content::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
}
