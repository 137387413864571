@import "style-constants.scss";

.calendar-wrapper {
    text-align: center;
    height: 530px;
    padding-bottom: 5px;

    .rbc-month-view {
        width: $page-content-width;
        cursor: pointer;
    }

    .rbc-toolbar {
        .rbc-toolbar-label {
            flex-grow: 0;
        }

        button {
            all: unset;
            padding: 0;
            background: none;
            border: none;
            cursor: pointer;

            &:focus {
                all: unset;
            }

            &:hover {
                all: unset;
            }

            &:focus:active {
                all: unset;
            }
        }

        .MuiTouchRipple-root {
            display: none;
        }
    }

    .rbc-header {
        background: #f5f6fa;
        border-left: none;
        letter-spacing: 0.09px;
        color: $grey-dark;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .rbc-date-cell {
        font-size: 9px;
        padding-top: 11px;
        padding-bottom: 5px;
        a {
            cursor: default;
        }
    }

    .rbc-day-bg {
        border: 1px solid #eaf0f4;
        margin: 0.5px;
        background: $white;

        &.selected {
            border: 1px solid $secondary-color-300;
        }

        &.rbc-off-range-bg {
            background: $white;
        }

        &.weekend {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            background: $grey-light;

            span {
                font-size: 9px;
                color: $grey-dark;
                text-align: left;
                padding: 0px 0px 4px 18px;
            }
        }

        &.tracked:hover {
            background-color: $grey-light;
        }

        &.tracked.weekend:hover {
            background-color: white;
        }
    }

    .rbc-event {
        height: 18px;
        border-radius: unset;
        padding: unset;

        &.rbc-selected {
            background-color: $primary-color-700;
        }

        &:focus {
            outline: unset;
        }

        .event-container {
            display: flex;
            justify-content: space-between;
            height: 18px;
            padding: 2px 5px;
            background-color: $purple-dark;

            span {
                font-size: 11px;
            }

            &.holiday,
            &.sick-leave {
                background-color: #ffb6c1;
            }

            &.holiday:hover,
            &.sick-leave:hover {
                background-color: $pink;
            }
        }

        .bench-activities {
            background-color: $red-light;
            color: $red-darker;
        }

        .bench-activities:hover {
            background-color: $red-light-darker;
        }
    }

    .rbc-show-more {
        font-size: 11px;
    }
}

.rbc-overlay {
    .rbc-event {
        height: 18px;
        border-radius: unset;
        background-color: $primary-color-700;

        &.rbc-selected {
            background-color: $primary-color-700;
        }

        &:focus {
            outline: unset;
        }

        .event-container {
            display: flex;
            justify-content: space-between;
            height: 18px;

            span {
                font-size: 11px;
            }
        }
    }
}
