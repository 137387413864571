@import "style-constants.scss";

.common-dialog {
    position: relative;

    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:hover {
        background: $purple-light;
    }

    .MuiDialog-paper {
        padding: 30px 40px;
    }

    .dialog-title-text {
        text-align: center;
    }

    .dialog-close-btn {
        position: absolute;
        top: 30px;
        right: 30px;
    }

    .dialog-content {
        display: grid;
        grid-auto-flow: row;
        justify-content: center;
    }

    @media (max-width: $mobile) {
        .MuiDialog-container {
            height: unset;
        }

        .MuiDialog-paper {
            padding: 30px 40px;
            width: 100%;
            margin: 0;
            height: 100vh;
        }

        .MuiDialog-paperWidthSm {
            max-width: unset;
        }

        .MuiDialogContent-root {
            flex: unset;
        }

        .dialog-actions-container {
            display: flex;
            justify-content: center;
        }

        .dialog-title {
            .dialog-title-text {
                color: $purple-dark;
                font-size: 18px;
            }
        }
    }

    .dialog-actions-container {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        padding: 0;
        margin-top: 12px;
    }

    .MuiSelect-icon {
        right: 16px;
        color: $grey-dark;
    }

    .form {
        display: grid;
        gap: 20px;

        .invalid-field-message {
            padding-left: 16px;
            padding-top: 4px;
            font-size: 12px;
            letter-spacing: 0.4px;
            color: $red-darker;
        }

        .MuiFormControl-root {
            .MuiInput-underline:after {
                border-bottom-color: $primary-color-700;
            }

            label {
                color: $grey-dark;

                &.Mui-disabled {
                    opacity: 0.71;
                }
            }

            label.Mui-focused,
            &:hover label.MuiInputLabel-shrink {
                color: $dark-grey;

                &.Mui-error {
                    color: $red;
                }

                font-weight: 500;
            }

            .MuiOutlinedInput-root.Mui-disabled {
                opacity: 0.71;
            }

            .MuiOutlinedInput-root:not(.Mui-error):not(.Mui-disabled) {
                .MuiOutlinedInput-input {
                    color: $blue-darkest;
                }

                fieldset {
                    border-color: $grey-lighter;
                    border-width: 1px;
                }

                &:hover,
                &.Mui-focused {
                    fieldset {
                        border-color: $dark-grey;
                    }

                    span {
                        color: $grey;
                        font-weight: 500;
                    }

                    svg {
                        color: $grey;
                    }
                }
            }

            .MuiOutlinedInput-root.Mui-error:not(.Mui-disabled) {
                fieldset {
                    border-color: $red-darker;
                    border-width: 1px;
                }

                &:hover,
                &.Mui-focused {
                    span {
                        color: $red;
                        font-weight: 500;
                    }

                    svg {
                        color: $red;
                    }
                }
            }
        }
    }
}