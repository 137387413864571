@import "style-constants.scss";

.video-wrapper {
    cursor: pointer;
    position: relative;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    overflow: hidden;
    margin: auto;
    margin-top: 20px;

    .video-player {
        box-shadow: 0px 1px 3px $grey-darker-button;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        object-fit: fill;
        overflow: hidden;
    }

    .play-button-wrapper {
        pointer-events: none;
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, 0);
        width: 1.89375rem;
        height: 1.89375rem;
        .play-button-relative {
            .play-button-container {
                .play-button {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 999;
                    color: $medium-dark-shade-blue;
                }
                &::after {
                    display: block;
                    position: absolute;
                    left: 22%;
                    top: 20%;
                    content: "";
                    width: 0.8rem;
                    height: 0.8rem;
                    background-color: white;
                    z-index: 1;
                }
            }
        }
    }
    .hidden {
        display: none;
    }
}
