@import 'style-constants.scss';

.request-holiday-form {

    .MuiFormControl-marginNormal {
        margin: 0;
        margin-bottom: 3px;
    }
    
    .start-date-container {
        width: 100%;
        margin-right: 20px;
    }

    .date-fields-block {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 35px;
    }

    .end-date-field, .end-date-container, .start-date-field  {
        width: 100%;
    }

    .calendar-icon {
        cursor: pointer;
        color: $grey-dark;
    }

    .holiday-type-field {
        margin-top: 12px;
        margin-bottom: 35px;
    }

}
