@import 'style-constants.scss';

.no-items-card-wrapper {
    display: grid;
    grid-auto-flow: row;
    border-radius: 24px;
    box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
    background-color: $white;
    padding: 24px;

    .no-items-content {
        display: grid;
        grid-auto-flow: row;
        justify-content: center;
        align-items: center;
        padding: 40px 0;

        svg {
            margin: auto;
        }
    }
}