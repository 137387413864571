@import "style-constants.scss";

.login-wrapper {
    .auth-card-form-wrapper {
        min-height: 450px;
    }

    .form-fields-wrapper {
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .forgotten-pwd-link {
        font-size: $smallest;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0.11px;
        color: $deep-purple;

        &.client {
            color: $deep-orange;
        }
    }

    .buttons-wrapper {
        margin-top: 40px;
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 14px;
    }

    .sign-text-wrapper {
        margin-top: 17.5px;
        font-size: $small;
        text-align: center;
        
        a {
            text-decoration: none;

            &.candidate {
                color: $deep-purple;
            }

            &.client {
                color: $deep-orange;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    @include profile-creation-mobile {
        .sign-text-wrapper {
            font-size: $tiny;
        }

        .forgotten-pwd-link {
            font-size: $tiny;
            line-height: 12px;
            letter-spacing: 0.1px;
        }
    }
}
