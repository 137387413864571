@import "style-constants.scss";

.skills-wrapper {
    .skills-container {
       display: grid;
       grid-auto-flow: row;
       grid-template-columns: 1fr;
    }

    .skill-name {
        padding: 5px 16px;
        background-color: $pale-purple;
        border-radius: 100px;
    }

    .editable-container {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        align-items: end;
        gap: 10px;
    }

    .skill-box-row {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
    }

    .delete-skill-icon {
        border: none;
        background-color: inherit;

        &:hover {
            cursor: pointer;

            svg * {
                stroke: $red;
            }
        }
    }
}