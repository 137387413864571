@import "style-constants.scss";

.project-card {
    width: 20%;
    min-width: 245px;
    margin-bottom: 20px;
    background: none;

    @media screen and (max-width: 1771px) {
        width: 25%;
    }

    @media screen and (max-width: 1524px) {
        width: 33.33%;
    }

    @media screen and (max-width: 1281px) {
        width: 50%;
    }

    &.MuiPaper-elevation1 {
        box-shadow: none;
    }

    .project-card-inner {
        border-radius: 18px;
        cursor: pointer;
        padding: 10px 20px;
        box-shadow: 0px 1px 5px $grey-darker-button;
        height: 100%;
        background-color: $white;
        margin-right: 10px;

        &:hover {
            box-shadow: 0px 1px 25px $grey-darker-button;
        }
    }

    .title-wrapper {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .no-pointer {
        cursor: auto;

        &:hover {
            box-shadow: 0px 1px 5px $grey-darker-button;
        }
    }

    .project-status {
        width: 22px;
        height: 22px;
        border-radius: 50%;
    }

    .green-status {
        background-color: $secondary-color-100;
    }

    .red-status {
        background-color: $red-pink;
    }

    .project-name {
        text-align: left;
        width: calc(100% - 30px);
        font-size: 17px;
        font-weight: 500;
        letter-spacing: 0em;
        line-height: 1.3;
        color: $primary-color-400;
    }

    .project-client {
        margin-top: 16px;
    }

    .info-row,
    .additional-info-row {
        width: 100%;
        display: flex;
        margin-bottom: 16px;

        &.last {
            margin-bottom: 0;
            padding-right: 15px;
        }

        .project-date {
            display: flex;
        }
    }

    .additional-info-row > .info-text {
        margin-right: 35px;
        flex-shrink: 0;
    }

    .info-icon {
        color: $grey-dark;
        margin-right: 8px;
    }

    .info-text {
        font-size: 14px;
        letter-spacing: 0.22px;
        color: $primary-color-400;
        word-break: break-all;
    }
}
