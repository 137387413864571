@import 'style-constants.scss';

.create-task-dialog {
    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }
    
    .dialog-actions {
        margin-top: 30px;
        margin-bottom: 22px;
    }
}
