@import 'style-constants';

.ideal-candidate-dialog-form {
    .options-card-labels-wrapper {
        display: grid;
        gap: 16px;

        &.transition-error {
            .options-card-label {
                outline: 1px solid $red-darker;
                color: $red-darker;
            }

            .radio-btn {
                color: $red-darker;
            }
        }

        &.error,
        &.animation-error {
            .options-card-label {
                animation: fadeout 3s ease-in-out forwards;
            }

            .radio-btn {
                animation: fadeout-radio-btn 3s ease-in-out forwards;
            }
        }
    }

    .options-card-label {
        border-radius: 16px;
        border: 1px solid $pale-gray;
        justify-content: space-between;
        margin: 0;
        padding: 16px;
        color: $grey;
        font-size: 16px;
        letter-spacing: 0px;

        &.selected {
            border: 1px solid $dark-grey;
            color: $dark-grey;
        }
    }

    .invalid-field-wrapper {
        display: flex;
        align-items: center;
        color: $red-darker;
        padding-top: 11px;

        .error-icon {
            width: 22px;
            height: 22px;
            margin-right: 9px;
        }

        span {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.25px;
        }
    }

    .dialog-label-text {
        margin: 20px 0;
    }

    .form-cols {
        display: grid;
        grid-auto-flow: column;
        gap: 15px;
        grid-template-columns: 1fr 1fr;
    }
}