@import "style-constants.scss";

.cv-experience-wrapper {
    .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px $purple-dark solid;

        .item-edit-icon-wrapper {
            cursor: pointer;

            svg:hover {
                cursor: pointer;
    
                rect {
                    fill: $purple;
                }
    
                path {
                    stroke: $white;
                }
            }
        }
    }

    .experience-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 20px 0px;

        @media screen and (max-width: $mobile) {
            font-size: 14px;
            letter-spacing: 0.18px;
        }

        .row-heading {
            @media screen and (max-width: $mobile) {
                font-size: 14px;
                letter-spacing: 0.18px;
            }
        }

        .row-subheading {
            margin-top: 8px;

            @media screen and (max-width: $mobile) {
                font-size: 12px;
                letter-spacing: 0.15px;
                text-align: right;
            }
        }

        .dates-wrapper {
            display: flex;
            align-items: center;
            margin-left: 10px;
            white-space: nowrap;

            .material-icons {
                font-size: 18px;
                color: $blue-darkest;
            }

            @media screen and (max-width: $mobile) {
                margin-left: 0px;
                margin-top: 2px;

                .dates {
                    font-size: 12px;
                    margin-left: 5px;
                }
            }
        }
    }

    .description {
        white-space: pre-line;
    }

    .skills-container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: -16px;

        .skill-box {
            border-radius: 100px;
            padding: 6px 16px;
            max-width: fit-content;
            background-color: $pale-purple;
            margin-right: 12px;
            margin-bottom: 16px;

            &.client {
                background-color: $pale-orange;
            }
        }
    }
}
