@import "style-constants.scss";

.training-wrapper {
    max-width: 934px;
    margin: auto;

    .centered {
        display: flex;
        position: absolute;
        left: 48%;
        top: 40%;
    }
}
