@import 'style-constants.scss';

.education-dialog {

    .education-start-date-field {
        margin-bottom: 20px;
    }

    .education-dates-options {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;

        .custom-switch-control {
            flex-direction: row;
            margin-right: 0px;

            &:not(:first-child) {
                margin-left: 16px;
            }

            .MuiSwitch-switchBase {
                padding: 0;
                margin: 3px;
            }

            .MuiSwitch-thumb {
                width: 11px;
                height: 11px;
            }

            .MuiFormControlLabel-label {
                font-size: 14px;
                font-weight: 300;
                line-height: 19px;
                color: $grey-dark-text;
            }
        }
    }

    .hint {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 10px;
        color: $grey-dark;
        margin-bottom: 10px;

        svg {
            width: 20px;
            height: 20px;
        }
    }
}
