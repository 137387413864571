@import 'style-constants.scss';

.completion-progress-bar-wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    .determinated-linear-progress-wrapper {
        width: 100%;
        margin-right: 20px;
        height: 18px;

        .MuiLinearProgress-bar {
            border-radius: 40px;
            background-color: $teal;

            &::after {
                content: '';
                width: 12px;
                height: 12px;
                position: absolute;
                background-color: $white;
                border-radius: 50%;
                top: 3px;
                right: 6px;
            }
        }

        .MuiLinearProgress-root {
            height: 100%;
            border-radius: 40px;
            background-color: $grey-light-background;
        }
    }

    .percentage-text {
        font-size: $small;
        line-height: 18px;
        letter-spacing: 0px;
        color: $white;
    }

    @include profile-creation-mobile {
        padding: 0px 20px;

        .determinated-linear-progress-wrapper {
            height: 12px;
            margin-right: 10px;

            .MuiLinearProgress-bar {
                border-radius: 40px;
                background-color: $teal;
    
                &::after {
                    width: 8px;
                    height: 8px;
                    top: 1px;
                    right: 3px;
                }
            }

            .MuiLinearProgress-root {
                border: 1px solid $teal;
                background-color: $white;
            }
        }

        .percentage-text {
            font-size: $tiny;
            line-height: 13px;
            color: $teal;
        }
    }
}
