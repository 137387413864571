@import 'style-constants.scss';

.job-search-details-wrapper {
    display: grid;
    grid-auto-flow: row;
    gap: 15px;
    
    .job-search-details-header {
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        align-items: top;
        justify-content: flex-start;
    }

    .job-search-details-back-arrow {
        margin-top: 10px;

        &:hover {
            cursor: pointer;
        }
    }
}