@import "style-constants.scss";

.cv-certificates-wrapper {
    .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px $purple-dark solid;

        .item-edit-icon-wrapper {
            cursor: pointer;

            svg:hover {
                cursor: pointer;
    
                rect {
                    fill: $purple;
                }
    
                path {
                    stroke: $white;
                }
            }
        }
    }

    .dates-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 20px 0px;

        a {
            text-decoration: none;
        }

        .row-subheading {
            margin-top: 8px;
        }

        .dates-wrapper {
            display: flex;
            align-items: center;
            margin-left: 10px;
            white-space: nowrap;

            .material-icons {
                font-size: 18px;
                color: $blue-darkest;
            }
        }
    }
}
