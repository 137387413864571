@import 'style-constants.scss';

.choose-avatar-section-wrapper {
    .choose-avatar-section-container {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        justify-content: center;
        text-align: center;
        gap: 32px;
    }

    .choose-avatar-section-avatars-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(4, 1fr);
        gap: 15px,
    }

    .avatar-container {
        height: 166px;
        width: 123px; 
    }

    .avatar-icon-container {
        width: 125px;
        height: 125px;

        &.selected {
            border-radius: 100%;
            border: 3px solid $dark-grey;

            svg.avatar {
                width: 120px;
                height: 120px;
            }
        }

        &:hover {
            cursor: pointer;

            svg.avatar {
                width: 120px;
                height: 120px;
            }
        }
    }

    .checkbox {
        margin-top: 10px;
    }

    .choose-avatar-btn {
        width: 192px;
        margin: auto;
    }
}