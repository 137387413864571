@import 'style-constants.scss';

.slim-select {
    .MuiSelect-select {
        padding: 7px 32px 6px 12px;
        font-size: 14px;
        color: $grey-normal-title;
    }
}

.select-menu-technology {
    .MuiCheckbox-root {
        color: $grey-lighter;

        &.Mui-checked {
            color: $secondary-color;
        }
    }

    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 0px;
    }

    .MuiPaper-rounded {
        margin-top: 14px;
        color: $grey-normal-title;
        max-height: 50%;

        li {
            font-size: 14px;
            padding: 0;
            padding-right: 10px;

            span {
                font-size: 14px;
            }
        }
    }
}
