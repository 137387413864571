.editor-container {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    font-family: "Urbanist", sans-serif;
    margin-bottom: 20px;

    .toolbar {
        margin-bottom: 10px;
    }

    .toolbar button {
        margin-right: 5px;
        padding: 5px 10px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        cursor: pointer;
    }

    .editor {
        min-height: 100px;
        cursor: text;
    }

    .editor .public-DraftEditorPlaceholder-root {
        color: #a8a8a8;
    }

    .editor .public-DraftEditor-content {
        min-height: 100px;
    }
}