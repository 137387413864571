@import 'style-constants';

.my-upcoming-interview-card {
    background: $white;
    box-shadow: 0px 1px 3px rgba(121, 121, 121, 0.1607843137);
    border-radius: 24px;
    padding: 32px 40px;
    display: grid;
    gap: 16px;
    justify-content: center;

    .date-time-wrapper {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        gap: 16px;

        .info-container {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: space-between;
            gap: 6px;
        }
    }
}