@import "style-constants.scss";

.candidate-applications-admin-view-wrapper {
    .candidate-applications-sub-title {
        font-size: $medium;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: $purple-dark;
        display: flex;
        align-items: center;
        height: 25px;
        padding-top: 10px;
        margin-bottom: 28px;
        opacity: 0.8;

        svg {
            margin-left: 10px;
        }
    }
}
