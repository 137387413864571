@import "style-constants.scss";

.application-interview-slots-content-wrapper {
    margin-top: 30.07px;
    padding: 32px 96px;

    .interviews-title {
        text-align: center;
    }

    .interviews-sub-title {
        margin-top: 8px;
        margin-bottom: 24px;
        text-align: center;
    }

    .application-interview-slots-list {
        display: grid;
        grid-auto-flow: column;
        gap: 16px;
        justify-content: center;
        list-style-type: none;
        margin: 20px 0px;
    }

    .application-interview-slot-item {
        display: grid;
        gap: 8px;
        padding: 24px 26px;
        background-color: $background-color;
        border-radius: 24px;
        color: $grey;
        cursor: pointer;

        .item-row {
            display: grid;
            grid-auto-flow: column;
            gap: 6px;
            justify-content: flex-start;

            &:last-child {
                justify-content: center;
            }
        }

        &.selected {
            background-color: $dark-grey;
            color: $white;
        }

        &.waiting-for-response {
            cursor: default;
            opacity: 0.7;
        }

        .check-circle-icon {
            position: absolute;
            width: 22px;
            height: 22px;
            top: 15px;
            right: 22px;
            color: $secondary-color-300;
        }
    }

    .interview-slots-action-buttons {
        display: grid;
        grid-auto-flow: column;
        width: fit-content;
        gap: 15px;
        margin: 0 auto;
    }
}
