@import 'style-constants.scss'; 

.popup-invite-candidate-for-interview { 
    .MuiDialog-paperWidthSm {
        width: 100%;

        padding: 0 20px;
    }

    .popup-title {
        display: block;
        padding-right: 10px;
        
        .MuiTypography-h6 {
            color: $grey-dark;
            width: 100%;
            display: flex;
            align-items: center;

            .popup-close-btn {
                width: 48px;
                height: 48px; 
            }

            .popup-title-text {
                font-weight: 500;
                font-size: 16px;
                width: calc(100% - 48px);
                padding-right: 10px;
            }
        }
    }

    .dialog-content { 
        padding-bottom: 30px;
    }
}
