.notification {
    width: 100%;

    .MuiAlert-message {
        white-space: pre-wrap;
        text-align: center;
    }

    .MuiAlert-icon {
        align-items: center;
    }
}
