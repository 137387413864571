@import 'style-constants.scss';

.agency-basic-settings {

    .agency-settings-content-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .agency-basic-settings-form {
        width: 60%; 
        padding-right: 40px;
    }

    .agency-basic-settings-logo {
        flex-grow: 1;
        display: flex;
        flex-direction: column;   
    }

    .basic-settings-logo {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    @media screen and (max-width: $small-desktop) {
        .agency-settings-content-wrapper {
            flex-wrap: wrap;
        } 
        
         .agency-basic-settings-form {
            width: 100%; 
            order: 2;
            padding-right: 0px;
        }

         .basic-settings-logo {
            width: 100%; 
            order: 1;
            margin-bottom: 30px;
        }
    }
}
