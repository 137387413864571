@import 'style-constants';

.language-selector {
    display: grid;
    grid-auto-flow: column;
    color: $white;
    width: 55px;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    border: 1.5px solid $teal;
    border-radius: 18px;

    .option {
        font-size: $tiniest;
        padding: 2px;

        &.selected {
            background-color: $teal;
            border-radius: 18px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}