@import 'style-constants.scss';

.profile-skills-info-wrapper {

    .profile-skills-rows-wrapper {

        .with-border { 
            padding-bottom: 30px;
            border-bottom: 1px solid $grey-border;
            margin-bottom: 30px;
        }

         .profile-info-row.global-profile-info-row { 
            cursor: grab;
            padding-bottom: 0px;
            padding-top: 0;
            border-bottom: 0; 
            margin-bottom: 30px;

            &:last-child { 
                border-bottom: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
 }
