@import 'style-constants.scss';

.password-set-wrapper {
    .form-control-checkbox {
        margin: auto;
        margin-top: 20px;
    }

    .buttons-wrapper {
        margin-top: 15px;
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        gap: 14px;
    }

    .inviter-info {
        font-size: 17px;
        line-height: 30px;
        letter-spacing: 0;
        color: $purple-dark;
        text-align: center;
        text-transform: uppercase;
    }

    @include profile-creation-mobile {
        .inviter-info {
            font-size: $tiny;
            line-height: 16px;
        }
    }
}
