@import "style-constants.scss";
.hire-banner-outer-container {
    padding-top: 20px;
}
.hired-banner-wrapper {
    padding: 0 50px 20px 50px;
    margin: 0 auto 25px;

    display: flex;
    justify-content: space-between;
    background-color: $light-shade-blue;
    align-items: center;
    .col-1of2 {
        width: 50%;
        color: $primary-color-400;
    }
    .col-2of2 {
        width: 50%;
        text-align: right;
    }

    .image-info {
        margin-top: -20px;
    }
    .action-wrapper-title {
        margin-bottom: 10px;
        font-size: 25px;
        min-width: 200px;
    }
}
