@import 'style-constants.scss';

.job-type-filter-box {
    display: grid;
    gap: 30px;
    align-content: center;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 231px;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px $black-grey-shadow;
    border-radius: 18px;
    opacity: 1;
    cursor: pointer;

    &.filter-box-contract-jobs h1,
    &.filter-box-full-time-jobs h1 {
        text-align: center;
        font-weight: 900;
        font-size: 70px;
        line-height: 30px;
        letter-spacing: 0.9px;
    }

    &.filter-box-contract-jobs p,
    &.filter-box-full-time-jobs p {
        text-align: left;
        font-weight: normal;
        font-size: 20px;
        line-height: 21px;
        letter-spacing: 0.35px;
        color: $purple-dark;
    }

    &.filter-box-contract-jobs {
        h1 {
            color: $primary-color-400;
        }
    }

    &.filter-box-full-time-jobs {
        h1 {
            color: $orange;
        }
    }

    &.active-jobs-box {
        border: 2px solid $purple-dark;
    }
}

.job-type-filter-box:hover {
    display: grid;
    gap: 30px;
    align-content: center;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    height: 231px;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 10px #CCCCCC;
    border-radius: 4px;
    opacity: 1;

    &.active-jobs-box {
        border: 2px solid $purple-dark;
        box-shadow: none;
    }
}
