@import 'style-constants.scss';

.export-timesheet-dialog {
    .MuiDialog-paper {
        max-width: 719px;
        width: 719px;
        height: 266px;
        padding: 15px 40px 25px 40px;
    }
    
    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;   
        padding: 0;
    }

    .dialog-title-text {
        font-size: 16px;
        
        font-weight: 500;
        color: $grey-dark;
    }

    .dialog-content {
        padding: 20px 0 0 0;
    }

    .dialog-close-btn {
        margin-right: -15px;
    }
}
