@import 'style-constants';

.job-searches-wrapper {
    display: grid;
    grid-auto-flow: row;
    gap: 16px;

    .job-searches-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
    }

    .job-searches-container {
        display: grid;
        grid-auto-flow: row;
        gap: 16px;
    }

    .job-searches-actions {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 16px;
        align-items: center;

        span {
            text-transform: capitalize;
        }        
    }

    .search-term-field {
        border: 1px solid $pale-gray;
        border-radius: 16px;
        background-color: $white;
        min-width: 400px;
        min-height: 56px;
        margin-top: 10px;
    }

    .search-icon {
        margin-left: 10px;
    }

    .MuiCircularProgress-root {
        margin: auto;
    }
}
