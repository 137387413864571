@import 'style-constants.scss';

.pagination-wrapper {
    width: fit-content;
    margin: auto;
    display: grid;
    grid-auto-flow: column;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    svg:hover {
        cursor: pointer;
    }
}