@import 'style-constants.scss';

.add-team-member-form {
    .add-member-field {
        margin-bottom: 14px;
    }

    .helper-text-wrapper {
        margin-bottom: 7px;

        span {
            text-align: left;
            font-size: 12px;
            color: $grey-dark;
        }
    }
}
