@import 'style-constants.scss';

.button-wrapper {
    width: 56px;
    height: 56px;
    border-radius: 50px;
    background-color: $dark-grey;
    display: grid;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
}
