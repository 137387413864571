@import "style-constants.scss";

.coaching-mentoring-card-wrapper {
    box-shadow: 0px 1px 3px $grey-darker-button;

    .coaching-mentoring-card-link {
        text-decoration: none;
    }

    .coaching-mentoring-card-content {
        padding: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;

        & > div:not(:first-child) {
            margin-top: 20px;
        }
    }

    .coaching-mentoring-card-header-information {
        display: flex;
        align-items: center;
    }

    .profile-logo {
        width: 52px;
        height: 52px;
    }

    .title-name-wrapper {
        margin-left: 8px;
    }

    .coaching-title h1 {
        font-size: $medium;
        font-weight: 700;
        color: $purple-dark;
        letter-spacing: 0.24px;
        word-break: break-word;
    }

    .coach-name h4 {
        font-size: $medium;
        font-weight: 400;
        color: $purple-dark;
        letter-spacing: 0.28px;
        word-break: break-word;
    }

    .languages-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-bottom: -5px;

        .language-item {
            padding: 5px 12px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 4px;
            background-color: $primary-color-light;
            color: $primary-color-700;
            font-size: $small;
            font-weight: 500;
            letter-spacing: 0.24px;
            height: fit-content;
        }
    }

    .description {
        font-weight: 400;
        font-size: $medium;
        color: $purple-dark;
        letter-spacing: 0.54px;
    }

    .tags-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -8px;
        margin-bottom: -7px;

        .tag-item {
            padding: 10px 14px;
            border-radius: 4px;
            margin-right: 8px;
            margin-bottom: 7px;
            border: 1px solid $actions-border;
            background-color: $white;
            color: $grey-dark-text;
            font-size: $small;
            letter-spacing: 0.25px;
            height: fit-content;
        }
    }

    &:hover {
        cursor: pointer;
    }
}
