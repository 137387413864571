@import "style-constants.scss";

.candidate-profile-page {
    min-height: 100vh;

    .improve-cv {
        margin-top: 2rem;
    }

    .candidate-cv {
        display: flex;
        flex-direction: column;
        margin: 1.67rem auto auto;
        border-radius: 18px;

        .cv-completion-page {
            margin: auto;
            box-shadow: 0px 1px 3px $grey-darker-button;
            border-radius: 18px;
        }
    }

    .raw-cv-profile-content.paper-element-container {
        width: 100%;
        max-width: 100%;

        .import-pdf-container {
            width: 100%;
        }
    }

    .candidate-profile-page-assessment-reminder {
        background-color: $pale-red;
        border-radius: 24px;
        box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
        padding: 20px 32px;
        margin-bottom: 24px;
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        gap: 35px;
        align-items: center;
    }
}

.welcome-image {
    margin: 20px auto;
}
