@import "style-constants.scss";

.content-container { 
    width: 100%;
    min-height: 100vh;
    padding: 40px 30px;
    min-width: 1050px;
    position: relative;
}

.content-container-with-submenu {
    width: 100%;
}
