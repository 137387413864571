@import "style-constants.scss";

.job-details-container {
    min-height: 100vh;
    position: relative;
    .job-details-button-container {
        cursor: pointer;
        width: fit-content;

        .custom-button-container {
            cursor: pointer;
            width: fit-content;
            display: flex;
            align-items: center;
            color: $purple-dark;
            font-weight: 600;
            font-size: 16px;
        }
    }
}
