@import './style-constants.scss';

.client-contracts-table-wrapper {
    .client-contracts-table-header {
        margin-top: 33px;
        margin-bottom: 20px;
    }

    .search-term-field {
        border: 1px solid $pale-gray;
        border-radius: 16px;
        background-color: $white;
        min-width: 400px;
        min-height: 56px;
        margin-top: 10px;
    }

    .search-icon {
        margin-left: 10px;
    }

    .client-contracts-table-content {
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 24px;
        width: 100%;
        box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
        border-radius: 24px;
        background-color: $white;
        border-collapse: collapse;

        width: 100%; // Ensure the table takes full width of its container

        // Set the column widths
        .table-column-name {
            width: 70%;
        }

        .table-column-date,
        .table-column-action {
            width: 15%;
        }

        td svg {
            margin-left: 5px;

            * {
                stroke: $dark-grey;
            }

            &:hover {
                cursor: pointer;
            }
        }

        th {
            text-align: left;
        }

        tbody tr {
            border-radius: 24px;

            &:nth-child(odd) {
                background-color: $app-background;
            }
        }

        td,
        th {
            padding: 15px;
        }
    }
}