@import 'style-constants';

.technical-self-assessment-wrapper {
    .technical-self-assessment-paper {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        gap: 30px;
        position: relative;
        height: 800px;

        .fading-effect {
            position: absolute;
            right: 0;
            bottom: 205px;
            height: 40px;
            width: 70%;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            pointer-events: none;
        }
    }

    .technical-self-assessment-content-wrapper {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        grid-template-columns: 30% 70%;
        height: 450px;
    }

    .technical-self-assessment-title-wrapper {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .technical-self-assessment-warning {
        border-radius: 8px;
        background-color: $pale-red;
        padding: 8px 16px;
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
    }

    .technical-self-assessment-actions-wrapper {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        height: 72px;
    }

    .technical-self-assessment-skills-wrapper {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        justify-content: flex-start;
        height: min-content;
        gap: 16px;
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: auto;

        .technical-self-assessment-skill-container {
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
            align-items: center;

            .skill-box {
                background-color: $pale-purple;
                border-radius: 100px;
                padding: 2px 10px;
            }

            .rating-stars-wrapper {
                display: grid;
                grid-auto-flow: column;
                align-self: center;
                width: 150px;
                margin-right: 20px;
            }
        }

    }

    .menu-items-wrapper {
        display: grid;
        grid-auto-flow: row;
        gap: 10px;

        .last-step {
            margin-bottom: 80px;
        }
    }

    .steps-wrapper {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 8px;

        .progress-wrapper {
            height: 100%;
            width: 8px;
            border-radius: 32px;
            background-color: $pale-gray;
            position: relative;

            .filled-progress {
                position: absolute;
                top: 0;
                left: 0;
                width: 8px;
                border-radius: 32px;
                background-color: $deep-purple;


                &.preferredStack {
                    height: 14%;
                }

                &.programmingLanguages {
                    height: 28%;
                }

                &.frameworksAndLibraries {
                    height: 42%;
                }

                &.databases {
                    height: 56%;
                }

                &.commercialClouds {
                    height: 70%;
                }

                &.tools {
                    height: 84%;
                }

                &.other {
                    height: 100%;
                }
            }
        }
    }
}