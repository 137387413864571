@import 'style-constants.scss';

.team-statistics-container {
    .team-statistics-charts-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: -20px;
    }
}
