@import 'style-constants.scss';

.performance-review-overall-card {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    gap: 20px;
    padding: 20px 40px;
    width: 100%;

    .overall-performance {
        display: flex;
        flex-direction: column;

        .overall-performance-loader {
            margin: 0 auto;
            margin-bottom: 20px;
        }

        .overall-performance-title {
            font-size: $large-title;
            font-weight: 500;
            letter-spacing: 0.26px;
            line-height: 1.6;
            color: $purple-dark;
            margin-bottom: 5px;
        }

        .overall-performance-score {
            font-size: 40px;
            font-weight: 500;
            letter-spacing: 0.52px;
            color: $purple-dark;
            margin-bottom: 5px;
        }
    }

    .performance-criteria {
        display: flex;
        flex-wrap: wrap;

        .performance-criterion {
            margin-bottom: 10px;
            margin-right: 20px;

            .performance-criterion-title {
                font-size: $small;
                letter-spacing: 0.24px;
                color: $purple-dark;
                white-space: nowrap;
                margin-bottom: 8px;
            }
        }

    }

    .performance-bar-container {
        &.large {
            width: 124px;
            height: 14px;
        }

        &.small {
            width: 93px;
            height: 10px;
        }
    }
}