@import 'style-constants.scss';

.start-trial-wrapper {
    display: grid;
    gap: 20px;

    .start-trial-text {
        font-size: $medium-title;
        line-height: 26px;
        letter-spacing: 0.17px;
        color: #363452;
        text-align: center;
    }

    .start-trial-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @include profile-creation-mobile {
        .start-trial-text {
            font-size: $medium;
            letter-spacing: 0.15px;
        }
    }
}
