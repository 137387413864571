@import "style-constants.scss";

.nothing-to-show-wrapper {
    width: 100%;
    display: grid;
    align-items: center;
    grid-auto-flow: row;
    gap: 7px;

    svg {
        margin: auto;
    }

    &.table-view {
        height: 80px;
    }

    .MuiTypography-body1 {
        color: $purple-dark;
        letter-spacing: 0.28px;
        font-size: $medium;
        text-align: center;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .MuiTypography-body1 {
            font-size: $smallest;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-body1 {
            font-size: $medium;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-body1 {
            font-size: $medium;
        }
    }
}
