@import "style-constants.scss";

.pending-team-holidays-table-container {
    .logo-name-wrapper {
        display: flex;
        align-items: flex-start;

        &:hover {
            cursor: pointer;
        }

        .avatar-logo-image {
            width: 30px;
            height: 30px;
            margin-right: 11px;
            background-color: $grey-creamy;

            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .MuiAvatar-colorDefault {
            background-color: $grey-creamy;
        }
    }
}