@import 'style-constants.scss';

.agency-matching-bonus-system-settings-wrapper {
    margin-bottom: 15px;

    .agency-matching-settings-content {
        padding: 40px;

        form {
            display: grid;
            grid-auto-flow: row;
            gap: 10px;
        }
    }

    .agency-use-bonus-system-checkbox-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .checkbox-text {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.00938em;
            color: $grey-normal-title;
        }
    }
}
