@import 'style-constants.scss';

.profile-time-tracking {
    .time-tracking-container {
        width: 100%;
        margin-top: -46px;

        .rbc-month-view {
            width: 100%;
        }
        .export-btn {
            display: block;
            margin-right: 0;
            margin-left: auto;
        }
    }
}

