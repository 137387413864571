@import "style-constants.scss";

.job-card {
    position: relative;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 18px;
    background: $white;
    text-decoration: none;
    padding: 30px 40px;

    .job-type-label-contract,
    .job-type-label-full-time {
        right: 0px;
        left: auto;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 0px;
    }

    .inner-card {
        .card-header {
            display: grid;
            gap: 1.1rem;
            grid-auto-flow: column;
            justify-content: flex-start;
            align-items: center;

            .job-avatar {
                height: 88px;
                width: 88px;
                background: transparent 0% 0% no-repeat padding-box;
                border-radius: 4px;

                img {
                    object-fit: contain;
                }
            }

            .header-info {
                .job-title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 10px;

                    h4 {
                        font-size: $medium-title;
                        font-weight: 500;
                        letter-spacing: 0.23px;
                        color: $purple-dark;
                        margin-right: 10px;
                    }

                    .label {
                        font-size: $small;
                        line-height: 15px;
                        border-radius: 4px;
                        padding: 5px 10px;
                        text-align: center;

                        &.seen {
                            color: $pink-light;
                            background-color: rgba($pink-light, 0.1);
                            margin-right: 5px;
                        }

                        &.applied {
                            color: $secondary-color;
                            background-color: rgba($secondary-color, 0.1);
                        }
                    }
                }

                .job-company-date-wrapper {
                    display: grid;
                    gap: 18px;
                    grid-auto-flow: column;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 15px;
                }

                .job-company,
                .job-date,
                .job-location {
                    display: flex;
                    align-items: center;

                    p {
                        font-size: $medium;
                        line-height: 21px;
                        letter-spacing: 0.28px;
                        color: $purple-dark;
                    }

                    .icon {
                        margin-right: 8.5px;
                    }
                }
            }

            &.mobile {
                display: grid;
                grid-auto-flow: row;

                .grid-container {
                    display: grid;
                    grid-auto-flow: column;
                    justify-content: flex-start;
                    gap: 15.8px;
                }

                .job-avatar {
                    width: 50px;
                    height: 50px;
                }

                h4 {
                    font-size:  $medium-title;
                    text-align: left;
                    letter-spacing: 0.21px;
                    color: $purple-dark;
                    opacity: 1;
                }

                .job-title {
                    display: grid;
                    align-items: center;
                }

                .job-company-date-wrapper {
                    gap: 10px;
                }

                svg {
                    width: 15px;
                    height: auto;
                }
            }
        }

        .card-divider {
            margin: 20px 0px;
        }

        .job-description {
            padding-bottom: 20px;
            width: calc(100% - 65px);

            h4 {
                font-size: $medium;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0.28px;
                color: $purple-dark;
                padding-bottom: 12px;
            }

            pre {
                font-size: $medium;
                
                letter-spacing: 0.28px;
                color: $purple-dark;
                font-weight: 400;
                text-align: left;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                -webkit-box-orient: -moz-vertical;
                white-space: -moz-pre-wrap;
                white-space: -pre-wrap;
                white-space: -o-pre-wrap;
                white-space: pre-wrap;
                word-wrap: break-word;
            }
        }

        .card-footer {
            width: calc(100% - 65px);

            h4 {
                font-size: $medium;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0.28px;
                color: $purple-dark;
                padding-bottom: 12px;
            }

            .skills-list {
                margin-bottom: -12px;
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;

                .skill {
                    width: fit-content;
                    margin-right: 12px;
                    margin-bottom: 12px;
                    background: $white 0% 0% no-repeat padding-box;
                    border: 1px solid #dddddd;
                    border-radius: 4px;
                    color: $grey-dark-text;
                    text-align: center;
                    letter-spacing: 0.29px;

                    p {
                        padding: 0.4rem 1rem;
                    }
                }

                .matching-skill {
                    border-color: $secondary-color-300;
                }

                .missing-skill {
                    border-color: $red-darker;
                }
            }
        }
    }

    .rest-skills-count {
        font-size: $medium;
        font-weight: 300;
        line-height: 19px;
        letter-spacing: 0.29px;
        color: $grey-dark-text;
    }

    .rest-skills-count:active {
        color: $grey-dark-text;
    }

    .rest-skills-container {
        position: relative;
    }

    .rest-skills-popup {
        position: absolute;
        top: 150%;
        z-index: 999;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 0.7rem;
        grid-template-rows: repeat(auto-fill);
        width: max-content;
        max-width: 700px;
        background-color: $white;
        border-radius: 4px;
        -webkit-box-shadow: 2px 3px 14px 1px rgba(0, 0, 0, 0.73);
        box-shadow: 2px 3px 14px 1px rgba(0, 0, 0, 0.73);
        padding: 15px 20px;

        @media screen and (max-width: 1000px) {
            max-width: 300px;
        }
    }

    .rest-skills-popup.false {
        visibility: hidden;
    }

    .rest-skills-popup.true {
        visibility: visible;
    }

    .rate-buttons-wrapper {
        position: absolute;
        bottom: 30px;
        right: 40px;
        width: fit-content;
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        align-items: center;
        gap: 7px;
        cursor: default;

        .vote-icon-wrapper {
            display: flex;
            cursor: pointer;

            &.downvote {
                transform: rotate(180deg);

                &.active {
                    #icon-vote-rectangle {
                        fill: $red-darker;
                        stroke: $red-darker;
                    }

                    #icon-vote-thumb {
                        fill: $white;
                    }
                }
            }

            &.upvote {

                &.active {
                    #icon-vote-rectangle {
                        fill: $secondary-color-300;
                        stroke: $secondary-color-300;
                    }

                    #icon-vote-thumb {
                        fill: $white;
                    }
                }
            }
        }
    }
}
