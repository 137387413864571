@import "style-constants.scss";

.candidates-table-wrapper {
    .full-name-wrapper,
    .logo-name-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .full-name-wrapper:hover {
        cursor: pointer;
    }

    .name-text {
        width: 100%;
        overflow-wrap: break-word;
    }

    .table-body-cell-status.new,
    .table-body-cell-status.cv-completed,
    .table-body-cell-status.vetted {
        color: $secondary-color-300;
    }

    .table-body-cell-status.pending-calendly-review,
    .table-body-cell-status.pending-video-review {
        color: $pink-dark;
    }

    .table-body-cell-status.rejected {
        color: $red-darker;
    }

    .table-body-cell-status .edited {
        color: $grey-dark;
    }

    .avatar-logo-image {
        width: 30px;
        height: 30px;

        border-radius: 50%;

        &.invited {
            border: 2px solid $green-light;
        }

        &.self {
            border: 2px solid $yellow-light;
        }

        &.google {
            border: 2px solid $purple;
        }

        &.linkedin {
            border: 2px solid $blue-light;
        }

        &.other-sources {
            border: 2px solid $grey-dark;
        }

        &:hover {
            cursor: pointer;
        }
    }
}
