@import 'style-constants.scss';

.profile-creation-card {
    width: 100%;
    max-width: 770px;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;

    .profile-creation-card-header {
        display: flex;
        justify-content: center;

        .logo-wrapper {
            display: none;
        }
    }

    .profile-creation-card-content-wrapper {
        padding: 50px 85px 30px;
        border-radius: 40px;
        box-shadow: 0px 3px 6px $black-grey-shadow;
    }

    .profile-creation-card-content-title {
        font-size: $extra-large;
        font-weight: 700;
        line-height: 37px;
        letter-spacing: 0.28px;
        color: $medium-shade-blue;
        text-align: center;
        text-transform: uppercase;

        &.normal-text {
            font-weight: 400;
            text-transform: none;
        }
    }

    .profile-creation-card-content-divider {
        width: 100%;
        margin: 30px auto 60px;
        background-color: $medium-shade-blue;
    }

    .profile-creation-card-content-body,
    .profile-creation-card-content-action-wrapper {
        max-width: 380px;
        margin: 0 auto;

        &.full-width {
            max-width: 100%;
        }
    }

    .profile-creation-card-content-action-wrapper {
        display: flex;
        margin-top: 45px;

        .action-button {
            width: 100%;
        }
    }

    @include profile-creation-mobile {
        max-width: 380px;

        .profile-creation-card-header {
            .logo-wrapper {
                display: block;
            }

            .logo-wrapper-white {
                display: none;
            }
        }

        .profile-creation-card-content-wrapper {
            padding: 40px 20px 30px;
            box-shadow: none;
        }

        .profile-creation-card-content-title {
            font-size: $medium;
            line-height: 26px;
            letter-spacing: 0.25px;
        }

        .profile-creation-card-content-divider {
            margin: 15px auto 40px;
            width: calc(100% - 90px);
        }
    }
}