@import 'style-constants';

.contract-info-banner-wrapper {
    margin: 10px 0;
    padding: 16px 29px;
    background-color: rgba(142, 148, 243, 0.1);
    border-radius: 15px;

    .MuiTypography-body1 {
        color: $purple-dark;
        margin-bottom: 12px;
    }

    .bold-text {
        font-weight: 600;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .MuiTypography-body1 {
            font-size: $tiny;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-body1 {
            font-size: $smallest;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-body1 {
            font-size: $smallest;
        }
    }
}