.employee-team-section {
    display: flex;
    flex-direction: column;

    .employee-team-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 30px;
    }

    .back-to-dashboard-btn {
        align-self: end;
        margin-bottom: 15px;
    }
}
