@import 'style-constants.scss';

.add-client-form {
    .MuiFormControl-marginNormal {
        margin: 0;
        margin-bottom: 3px;
    }

    .country-field,
    .client-status-field,
    .email-field,
    .contact-person-field {
        width: 100%;
    }

    .account-name-field,
    .account-manager-field,
    .country-field,
    .client-status-field {
        margin-top: 12px;
        margin-bottom: 35px;
    }

    .client-details-block {
        display: flex;
        gap: 27px;
        justify-content: space-between;
        margin-top: 12px;
    }
}
