@import 'style-constants.scss';

.applied-candidate-progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-flow: column wrap;

    .applied-candidate-progress {
        position: relative;
        margin-bottom: 12px;
        width: 100%;
        height: 33px;
        background-color: $grey-button-hover;
        border-radius: 4px;
        
        &::before {
            position: absolute;
            content: attr(title);
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            letter-spacing: 0.24px;
            color: $purple-dark;
            z-index: 2;
            padding: 6px;
        }

        & > div {
            background-color: $grey-progress;
            
        }
    }
}
