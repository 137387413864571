@import 'style-constants.scss';

.navigation.navigation-close {
    .nested-list {
        .nested-list-item {
            @media screen and (min-width: $desktop) {
                padding-left: 30px;

                &>.MuiListItemText-root {
                    opacity: 0;
                    display: none;
                }
            }
        }
    }
}

.nested-list {
    background-color: $grey;
    border-bottom-right-radius: 15px;

    .nested-list-item {
        padding: 15px 16px 15px 40px;
        transition: padding 0.3s;
        color: $white;
        text-transform: uppercase;

        &:last-child {
            border-bottom-right-radius: 15px;
        }

        .list-item-icon {
            color: $white;
            position: relative;
            width: 24px;
            height: 24px;

            svg {
                width: 100%;
                height: auto;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .notification-badge {
            position: absolute;
            top: 20px;
            left: 21px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $red;
            display: flex;
            align-items: center;
            justify-content: center;

            .badge-text {
                font-size: 10px;
                line-height: 1;
                color: $white;
            }
        }

        .notification-badge-mobile {
            display: none;
        }

        span {
            font-size: $medium;
            line-height: 19px;
            letter-spacing: 0.24px;
            font-weight: 300;
        }

        .MuiListItemText-root {
            margin: 0;
            min-width: 104px;
        }

        &.Mui-selected {
            background-color: $purple-dark-selected;
        }

    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {

        .list-item svg,
        .nested-list-item .list-item-icon svg {
            width: 22px;
            height: 22px;
        }

        .list-item span {
            font-size: $smallest;
        }

        .nested-list-item span {
            font-size: $smallest;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .list-item span {
            font-size: $small;
        }

        .nested-list-item span {
            font-size: $small;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .list-item span {
            font-size: $small;
        }

        .nested-list-item span {
            font-size: $small;
        }
    }

    @media screen and (max-width: $mobile) {
        border-bottom-right-radius: 0px;

        .nested-list-item {
            padding: 10px 16px 10px 70px;

            &:last-child {
                border-bottom-right-radius: 0;
            }

            &.Mui-selected {
                background-color: $blue-darkest;
                color: $white;
            }

            .MuiListItemText-root {
                margin: 4px 0px;
            }

            .notification-badge-mobile {
                display: block;
                position: absolute;
                top: 12px;
                left: 145px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: $red-darker;
                display: flex;
                align-items: center;
                justify-content: center;

                .badge-text {
                    font-size: 10px;
                    line-height: 1;
                    color: $white;
                }
            }
        }
    }
}