@import 'style-constants.scss';

.assign-admin-dialog-container {
    .MuiDialog-paper {
        width: 490px;
        min-height: 212px;
        padding: 19px 41px 25px 41px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .dialog-title {
        width: 417px;
        display: flex;
        justify-content: space-between;
        color: $grey-dark;
        padding: 0;

        p {
            font-size: 17px;
            letter-spacing: 0.11px;
        }

        .dialog-title-text {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .dialog-content {
        padding: 0;
        overflow-y: visible;
    }

    .permissions-container {
        .permissions-title {
            color: $purple-dark;
            font-size: $medium-title;
            font-weight: 500;
            line-height: 1.5;
        }

        .checkboxes-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);  /* Three columns with equal width */
            grid-gap: 10px; /* Gap between grid items */
        }

        .checkbox-container {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}