@import 'style-constants.scss';

.performance-review-card {
    width: 430px;  
    padding: 16px 20px 20px 25px;
    margin-bottom: 20px;
    margin-right: 20px;

    &:hover {
        box-shadow: 0px 1px 25px #00000033;
    }

    .score-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .average-score {
        text-align: left;
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 0.39px;
        color: $primary-color-400;
    }

    .bar-container {
        width: 120px;
        height: 13px;
    }

    .review-info {
        margin-top: 16px;
    }

    .info-row {
        width: 100%;
        display: flex;
        margin-bottom: 16px;

        &.last {
            margin-bottom: 0;
        }
    }

    .info-icon {
        color: $grey-dark;
        margin-right: 8px;
    }

    .info-text {
        font-size: 14px;
        letter-spacing: 0.22px;
        color: $primary-color-400;
        word-break: break-all;
    }
}
