@import 'style-constants.scss';

.job-search-results-wrapper {
    display:grid;
    grid-auto-flow: column;
    gap: 20px;
    grid-template-columns: 10% 80% 10%;

    .previous-button-wrapper,
    .next-button-wrapper {
        position: relative;
        width: 56px;

        &:hover {
            cursor: pointer;
        }
    }

    .previous-button {
        position: sticky;
        top: 50%;
    }

    .next-button {
        position: sticky;
        top: 50%;
    }

    .job-search-results-container {
        display: grid;
        grid-auto-flow: row;
        gap: 20px;

        .job-search-pages-wrapper {
            display: grid;
            grid-auto-flow: column;
            justify-content: center;
            gap: 5px;

            .job-search-pages-page {
                padding: 3px;
                border-radius: 100%;
                border: 1px solid $dark-grey;

                &.selected {
                    background-color: $dark-grey;
                }
            }
        }

        .job-search-results-position-info-content {
            display: grid;
            grid-auto-flow: column;
            gap: 10px;
            justify-content: center;
            align-items: center;
        }

        .job-searches-job-card-header-text {
            display: grid;
            grid-auto-flow: row;
            justify-content: flex-start;
        }

        .job-search-results-position-info-container {
            padding: 32px 48px;
            border-radius: 24px;
            background-color: $white;
            box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);

            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;

            svg {
                margin-top: 3px;

                * {
                    stroke: $deep-orange;
                }
            }
        }

        .job-search-position-info-action {
            display: grid;
            grid-auto-flow: column;
            align-items: center;

            &:hover {
                cursor: pointer;
            }
        }

        .job-search-results-actions-wrapper {
            display: grid;
            grid-auto-flow: column;
            gap: 15px;
            justify-content: center;

            svg.white {
                margin-right: -10px;

                * {
                    fill: $white;
                    stroke: $dark-grey;
                }
            }

            svg.filled {
                margin-right: -10px;

                * {
                    fill: $deep-orange;
                    stroke: $deep-orange;
                }
            }

            position: fixed;
            bottom: 30px;
            left: 45%;
        }
    }
}

.job-search-empty-wrapper {
    padding: 32px 48px;
    border-radius: 24px;
    background-color: $white;
    box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
    display: grid;
    grid-auto-flow: row;
    gap: 20px;
    text-align: center;

    .action-btn-wrapper {
        width: 192px;
        margin: auto;
        margin-top: 60px;
    }
}