@import 'style-constants.scss';

.candidates-nearly-there-wrapper {
    color: $purple-dark;

    .btn-wrapper {
        display: grid;
        grid-auto-flow: row;
        gap: 5px;
        justify-content: center;
    }

    .candidates-nearly-there-container {
        margin-left: auto;
        margin-right: auto;
        max-width: $paper-element-sm;
        
        @media screen and (max-width: $mobile) {
            max-width: none;
            width: 100%;
        }

        @media screen and (max-width: $mobile) {
            padding-bottom: 42px;
        }

        .text-container {
            margin-top: 31px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
            padding: 40px 96px;
            background-color: $white;
            margin-bottom: 56px;
            border-radius: 24px;
            
            @media screen and (max-width: $mobile) {
                margin-top: 0;
                margin-bottom: 20px;
            }


            .text-container-heading {
                margin-bottom: 16px;
            }

            &-text {
                font-size: 16px;
                font-weight: normal;
                margin-bottom: 15px;
                letter-spacing: 0.27px;
                color: $purple-dark;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .cjnt-options-container {
            display: flex;
            justify-content: space-between;

            .cjnt-option {
                padding-bottom: 31px;
                background-color: $white;
                display: flex;
                flex-direction: column;
                align-items: center;
                box-shadow: 0px 0px 10px #79797929;
                justify-content: space-between;
                border-radius: 18px;

                &.explore-jobs-option {
                    .option-image-wrapper {
                        margin-top: -20px;
                    }
                }

                &.finish-profile-option {
                    .option-image-wrapper {
                        margin-top: -12px;
                    }
                }

                &-heading {
                    margin: 14px 0;
                }

                &-text {
                    padding: 0 96px;
                    margin-bottom: 30px;
                    text-align: center;
                    flex-grow: 1;
                }

                .option-btn {
                    margin-top: 25px;
                }
            }
        }

        @media screen and (max-width: $mobile) {
            .cjnt-options-container {
                display: flex;
                flex-direction: column;
                gap: 21px;
                justify-content: space-between;

                .cjnt-option {
                    padding-bottom: 31px;
                    background-color: $white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-shadow: 0px 0px 10px #79797929;
                    justify-content: space-between;
                    border-radius: 18px;
    
                    &.explore-jobs-option {
                        .option-image-wrapper {
                            margin-top: 14px;
                        }
                    }
    
                    &.finish-profile-option {
                        .option-image-wrapper {
                            margin-top: 22px;
                        }
                    }
    
                    &-heading {
                        font-size: 16px;
                        letter-spacing: 0.54px;
                        font-weight: 500;
                        color: $purple-dark;
                        margin: 14px 0;
                    }
    
                    &-text {
                        padding: 0 35px;
                        font-size: 14px;
                        font-weight: normal;
                        letter-spacing: 0px;
                        color: $purple-dark;
                        text-align: center;
                        flex-grow: 1;
                    }
    
                    .option-btn {
                        margin-top: 25px;
                    }
                }
            }
        }
        
    }
}
