@import 'style-constants.scss';

.custom-switch {
    display: flex;
    padding: 0;

}

.custom-switch-control {
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiFormControlLabel-label {
        height: 100%;
        margin-right: 10px;
        color: $grey;
        font-size: $subheading-xs;
    }
}