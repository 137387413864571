@import 'style-constants.scss';

.supplier-admin-dashboard-wrapper {

    .supplier-dashboard-inner-content-wrapper {
        .jobs-list-container {
            margin-top: 50px;
            justify-content: normal;
            width: 100%;

            .job-box {
                width: 100%;
            }
        }

        .client-dashboard-invoices-wrapper {
            margin-top: 50px;
        }
    }
}
