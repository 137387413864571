@import "style-constants.scss";

.upcoming-interviews-wrapper {
    .upcoming-interviews-title {
        color: $purple-dark;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .upcoming-interviews-container {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 15px 22px;
    }
}
