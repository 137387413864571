@import 'style-constants.scss';

.add-contracts-form {
    .start-date-container {
        width: 100%;
        margin-right: 20px;
    }
    
    .date-fields-block {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        margin-bottom: 35px;
    }
    
    .end-date-field, .end-date-container, .start-date-field  {
        width: 100%;
    }
    
    .calendar-icon {
        cursor: pointer;
        color: $grey-dark;
    }

    .contract-type-field {
        margin-top: 25px;
        margin-bottom: 32px;
    }

    .switch-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 10px -4px 10px 0;

        .switch-label {
            margin: 0;
        }

        .MuiFormControlLabel-label {
            font-size: 14px;
            letter-spacing: 0.24px;
            color: $grey-normal-title;
            margin-right: 17px;
        }
    }

    .sign-required-field {
        margin-bottom: 25px;

        .sign-required-checkbox.Mui-checked {
            color: $secondary-color-300;
        }
    }

}
