@import "style-constants.scss";

.evrotrust-info-wrapper {
    padding: 30px 50px;
    background-color: #edeefc;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 18px;
    margin-bottom: 20px;

    .evrotrust-info-title {
        color: $primary-color-900;
    }

    .evrotrust-info-content {
        display: flex;
        justify-content: space-evenly;

        .evrotrust-info-subsection {
            width: 33%;

            .evrotrust-info-item-wrapper {
                display: flex;
                margin: 15px 0;

                .check-icon {
                    fill: $secondary-color-300;
                }

                .close-icon {
                    fill: $red-darker;
                }

                .check-icon,
                .close-icon {
                    margin-right: 5px;
                }

                .evrotrust-info-item {
                    color: $grey-normal-title;
                }
            }
        }
    }

    .evrotrust-info-content::after {
        content: "";
        width: 33%;
    }
}
