@import 'style-constants.scss';

.video-recording-view-container {
    .countdown-container {
        display: flex;
        min-height: 323px;
        text-align: center;
        position: relative;
        flex-direction: column;
        justify-content: center;
    
    }

    .guide-questions-wrapper {
        box-shadow: 0px 0px 10px #79797929;
    }

    .questions-list {
        display: grid;
        row-gap: 30px;
    }

    .question-item {
        display: flex;
        align-items: center;
    }

    .box-icon {
        margin-right: 10px;
    }

    p {
        color: $purple-dark;
        font: normal normal normal $medium Urbanist;
        letter-spacing: 0px;
        opacity: 1;
    }

    .hint {
        margin: 10px 0;
    }
    .timer-container {
        display: flex;

        svg {
            margin: 0 5px;
        }

        .timer {
            color: $purple-dark;
            font: normal normal normal $medium Urbanist;
            letter-spacing: 0px;
            opacity: 1;
        }
    }

    .flex-header {
        display: flex;
        justify-content: space-between;
    }
}
