@import "style-constants.scss";

.candidate-preferences {
    margin: auto;
    padding: 30px 40px;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 18px;
    position: relative;

    &.background-white {
        background-color: $white;
    }

    .upload-input {
        display: none;
    }

    .candidate-avatar-wrapper {
        position: relative;

        .avatar-actions {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .icon-button {
                width: 18px;
                height: 18px;
                padding: 0;
            }

            .avatar-delete-button {
                background-color: $grey-dark;
                color: $white;

                .close-icon {
                    width: 15px;
                    height: 15px;
                    margin-bottom: 5px;
                }
            }

            .avatar-edit-button {
                background-color: $white;
                border: 2px $purple-dark solid;
                color: $purple-dark;
                margin-left: 10px;

                .edit-sharp-icon {
                    width: 12px;
                    height: 12px;
                    margin-bottom: 6px;
                }
            }
        }
    }

    .header-wrapper-info {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .profile-status-text {
        display: grid;
        grid-auto-flow: column;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
    }

    .edit-icon {
        margin-left: 5px;
    }

    .header-wrapper {
        border-radius: 16px;
        background-color: $background-color;
        padding: 29px 16px;
        display: flex;
        justify-content: space-between;

        .MuiAvatar-root {
            width: 106px;
            height: 106px;
            border: 1.5px dashed $dark-grey;
        }

        .header {
            display: grid;
            gap: 2rem;
            grid-auto-flow: column;
            justify-content: flex-start;

            .header-info {
                margin-top: 1rem;

                .candidate-email {
                    display: grid;
                    gap: 0.74rem;
                    align-items: center;
                    grid-auto-flow: column;

                    .MuiSvgIcon-root {
                        font-size: 1.5625rem;
                        color: $grey-dark;
                    }
                }
            }
        }
    }

    .tech-stack-container {
        display: grid;
        grid-auto-flow: row;
        justify-content: flex-start;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;

        .tech-stack-item {
            padding: 6px 16px;
            border-radius: 100px;
            background-color: $pale-purple;
            text-align: center;
            align-content: center;
        }
    }

    .preferences-row-wrapper {
        position: relative;
        z-index: 1;

        .preferences-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
            border-radius: 16px;
            padding: 16px;
            background-color: $background-color;

            .row-title {
                flex-basis: 30%;
                display: grid;
                grid-auto-flow: column;
                justify-content: flex-start;
                align-items: center;
                gap: 0.878125rem;

                svg {
                    width: 25.33px;

                    path {
                        fill: #040227;
                        stroke: none;
                    }
                }

                .preferences-title {
                    flex-basis: 80%;
                }
            }

            .row-value {
                flex-basis: 50%;

                p {
                    font-size: 1rem;
                    letter-spacing: 0.15px;
                    color: $medium-dark-shade-blue;
                }
            }

            .job-edit-icon-container {
                svg:hover {
                    cursor: pointer;
                    
                    rect {
                        fill: $purple;
                    }

                    path {
                        stroke: $white;
                    }
                }

                .job-edit-button {
                    background-color: $grey-light;
                    padding: 0;

                    .edit-sharp-icon {
                        width: 15px;
                        height: 15px;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .divider,
        .row-divider {
            width: 100%;
            margin: 0;
            margin-top: 1.2rem;
            border: 1px solid $grey-light-amount;
        }

        .row-divider {
            margin-top: 0;
        }

        .last {
            margin-bottom: 2.25rem;
        }
    }
}