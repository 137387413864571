@import 'style-constants.scss';

.maintenance-page-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;

    .maintenance-image {
        margin-bottom: 30px;
    }

    .maintenance-title {
        color: $primary-color;
    }

    .maintenance-info {
        font-size: 16px;
        color: $grey-dark;
        text-align: center;
        max-width: 500px;
        margin-top: 20px;
    }

    @media screen and (max-width: $tablet) {
        .maintenance-image {
            max-width: 236px;
            max-height: 302px;
            margin-bottom: 20px;
        }

        .maintenance-info {
            font-size: 14px;
            max-width: 360px;
            margin-top: 10px;
        }    
    }

    @media screen and (max-width: $mobile) {
        height: 90vh;
        width: 80%;
        background: rgba(250, 250, 250, .9);
        border-radius: 8px;
    }

    @media screen and (max-width: $mobile-nav) {

        .maintenance-image {
            max-width: 189px;
            max-height: 242px;
        }
    }
}
