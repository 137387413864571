@import "style-constants.scss";

.coaching-opportunity-details-wrapper {

    .MuiAvatar-root {
        width: 70px;
        height: 70px;
    }

    .coaching-opportunity-wrapper {
        position: relative;
        min-height: 200px;
        padding: 30.65px 40.54px 0 40.54px;
        margin-top: 30px;
    }

    .coaching-opportunity-header {
        display: flex;
        width: 100%;

        .title
        .sub-title {
            display: flex;
            align-self: center;
            margin-left: 21px;
            color: $purple-dark;
            font-size: $medium-title;
            letter-spacing: 0.31px;
            word-break: break-all;
        }

        .sub-title {
            opacity: 0.8;
            font-size: $medium;
            font-weight: 500;
        }

        .coaching-opportunity-info {
            width: 100%;
            margin-left: 13px;
            display: flex;
            flex-direction: column;
            align-self: center;
        }

        .actions {
            flex-grow: 3;
            display: flex;
            margin-left: 5px;
            align-items: center;
            width: 120px;
            justify-content: flex-end;
            height: fit-content;

            .three-dots-action {
                margin-left: 20px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .centered {
        display: flex;
        position: absolute;
        top: 250px;
        left: 60%;
    }

    hr {
        border: 1px solid $grey-light-medium;
    }

    .icon {
        fill: $grey-lighter;
    }

    .booked-coaching-sessions-wrapper {
        padding-top: 30px;
    }

    .languages-list {
        display: flex;
        flex-wrap: wrap;
        margin-right: -5px;
        margin-bottom: -5px;
        margin-left: 15px;

        .language-item {
            padding: 5px 12px;
            margin-right: 5px;
            margin-bottom: 5px;
            border-radius: 4px;
            background-color: $primary-color-light;
            color: $primary-color-700;
            font-size: $small;
            font-weight: 500;
            letter-spacing: 0.24px;
            height: fit-content;
        }
    }
}
