@import 'style-constants.scss';

.select-tech-stack-wrapper {
    .technologies-wrapper {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        gap: 10px;
        margin-top: 14px;

        .technology-wrapper {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: space-between;

            .technology-right-aligned-items {
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                gap: 10px;
            }

            .skill-box {
                padding: 5px 16px;
                background-color: $pale-purple;
                border-radius: 100px;
            }
        }

        .delete-technology-icon {
            &:hover {
                cursor: pointer;

                * {
                    stroke: $red;
                }
            }
        }
    }

    .add-new-skill-option {
        cursor: pointer;
    }

    .add-skill-option {
        color: $grey-dark;
    }

    .search-skills-loader {
        display: flex;
        align-items: center;
        justify-content: center;

        .MuiCircularProgress-colorPrimary {
            color: $primary-color-700;
        }
    }
}