@import 'style-constants.scss';

.section-wrapper {
    margin-bottom: 30px;

    .section-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .title {
        color: $purple-dark;
        font-size: $medium;
        
        font-weight: bold;
        letter-spacing: 0.11px;
        text-transform: uppercase;
    }

    .sub-title {
        font-size: $small;
        line-height: 16px;
        letter-spacing: 0px;
        color: $purple-dark;
        opacity: 0.8;
        margin-bottom: 20px;
        word-break: break-all;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .title {
            font-size: $smallest;
        }

        .sub-title {
            font-size: $tiny;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .title {
            font-size: $medium;
        }

        .sub-title {
            font-size: $small;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .title {
            font-size: $medium;
        }

        .sub-title {
            font-size: $small;
        }
    }
}