@import 'style-constants.scss';

.register-agency-candidate-wrapper {
    .register-agency-candidate-form-wrapper {
        .form-control-checkbox {
            margin: auto;
            margin-top: 20px;
        }

        .buttons-wrapper {
            margin-top: 15px;
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            gap: 14px;
        }

        .login-text-wrapper {
            margin-top: 17.5px;
            font-size: $small;
            line-height: 21px;
            letter-spacing: 0.24px;
            color: $purple-darker-light;
            text-align: center;

            a {
                color: $purple;

                &.candidate {
                    color: $purple;
                }

                &.client {
                    color: $deep-orange;
                }
            }
        }
    }

    @include profile-creation-mobile {
        .register-agency-candidate-form-wrapper {
            .login-text-wrapper {
                margin-top: 14.5px;
                font-size: $tiny;
            }
        }
    }
}