@import 'style-constants.scss';

.submenu-action-button-container {
    &:last-child {
        padding-bottom: 25px;

        &.with-helper-text {
            padding-bottom: 8px;
        }
    }

    .helper-text {
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.17px;
        color: $purple-dark;
        padding-top: 4px;
    }
}
