@import 'style-constants.scss';

.evrotrust-settings-content {
    padding: 40px;

    .agency-evrotrust-settings-form-field {
        margin-bottom: 34px;
    }

    .agency-evrotrust-settings-form-block {
        display: flex;
        justify-content: space-between;
        padding-top: 25px;

        .agency-evrotrust-settings-form-field {
            width: 48%;
        }

        &.vertical {
            flex-direction: column;

            .agency-evrotrust-settings-form-field {
                width: 100%;
            }
        }
    }

    :last-child {
        margin-bottom: 0;
    }

    @media screen and (max-width: $small-desktop) {
        .evrotrust-settings-content-wrapper {
            flex-wrap: wrap;
        }

        .agency-evrotrust-settings-form-field {
            width: 100%;
            order: 2;
            padding-right: 0px;
        }
    }
}

.agency-evrotrust-checkbox-wrapper,
.agency-team-notifications-checkbox-wrapper {
    display: flex;
    align-items: center;

    .checkbox-text {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        color: $grey-normal-title;
    }
}
