@import "style-constants.scss";

.recruiters-table-wrapper {

    .logo-name-wrapper {
        display: flex;
        align-items: flex-start;
        pointer-events: none;

        .avatar-logo-image {
            width: 30px;
            height: 30px;
            margin-right: 11px;
            background-color: $grey-avatar-background;

            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .MuiAvatar-colorDefault {
            background-color: $grey-avatar-background;
        }
    }
}
