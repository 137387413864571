@import "style-constants.scss";

.add-skill-container {
    // overflow: hidden;
    .sort-list {
        display: flex;
        flex-wrap: wrap;
        letter-spacing: 0.25px;
    }

    .add-skill-form {
        display: flex;
        align-items: center;
        margin: 20px 0;

        .add-skill-input {
            position: relative;
        }

        .search-skills-loader {
            position: absolute;
            left: 86%;
            top: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            .MuiCircularProgress-root .MuiCircularProgress-colorPrimary .MuiCircularProgress-indeterminate {
                width: 20px;
                height: 20px;
            }
        }

        @keyframes spinSkillsLoader {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }

        .MuiOutlinedInput-input {
            color: #7e7e7e;
            padding: 7.5px 15px;
            border: 1px solid $secondary-color;
            border-radius: 4px;
        }

        fieldset {
            display: none;
        }

        .add-skill-button {
            cursor: pointer;
            width: 62px;
            height: 36px;
            background-color: #16d696;
            border: 2px solid #16d696;
            color: white;
            border-radius: 5px;
            margin-left: 5px;
        }
        .add-skill-button:focus {
            outline: none;
        }
    }
}
