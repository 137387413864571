@import 'style-constants.scss';

.add-recruiter-form {
    .MuiFormControl-marginNormal {
        margin: 0;
        margin-bottom: 3px;
    }

    .email-field,
    .recruiter-name-field,
    .contact-person-field,
    .country-field {
        margin-top: 12px;
        margin-bottom: 35px;
    }

    .country-field {
        width: 100%;
    }
    
    .recruiter-final-block {
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        margin-top: 12px;
        margin-bottom: 32px;
        grid-template-columns: 50% 50%;
    }
}
