@import "style-constants.scss";

.client-overview-wrapper {
    margin-bottom: 45px;

    .overview-subtitle {
        margin-bottom: 23px;
        color: $primary-color-900;
    }

    .client-overview-content {
        padding: 20px 30px 30px 30px;

        .projects-content {
            display: flex;
            gap: 30px;
            color: $grey-normal-title;
            margin-bottom: 20px;

            > div > span {
                font-size: 36px;
                font-weight: 900;
            }

            .active-projects > span {
                color: $secondary-color-300;
            }

            .complete-projects > span {
                color: $primary-color-400;
            }

            .total-projects > span {
                color: $grey-light-amount;
            }

            > div:not(:last-child) {
                border-right: solid 1px solid $grey-border;
                padding-right: 30px;
            }
        }
    }

    .financial-content {
        display: flex;
        gap: 30px;
        color: #00000099;

        .monthly-finance {
            & > span {
                font-size: 36px;
                color: #808495;
            }
        }

        .total-finance {
            & > span {
                font-size: 36px;
                color: #808495;
            }
        }

        > div:not(:last-child) {
            border-right: solid 1px solid $grey-border;
            padding-right: 110px;
        }
    }
}
