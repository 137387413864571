
@import 'style-constants.scss';

.time-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 332px;
    height: 74px;
    margin-bottom: 22px;

    .additional-information-block {
        width: 302px;
        display: flex;
        flex-direction: column;
        padding-left: 30px;

        .project-name {
            font-size: 20px;
            color: #5066E7;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .time-block {
            display: flex;
            align-items: center;

            .time-icon {
                width: 14px;
                height: 13px;
                color: #7E7E7E;
            }
    
            .hours-entry {
                padding-left: 8px;
                padding-right: 30px;
                font-size: 14px;
                color: #5066E7;
            }
        }
    }

    .project-remove-icon {
        display: flex;
        align-items: center;            
        margin-right: 15px;
        color: $grey-dark;
        width: 23px;
        height: 23px;
        cursor: pointer;
    }

    .project-remove-icon:hover {
        color: $primary-color;
    }

    .time-entry-right-side:hover .delete-time-entry {
        display: block;
        position: absolute;
        left:280px;
    }
}

.sick-leave-select {
    color: $yellow;
}

.bench-activities-select {
    color: $red-darker;
}
