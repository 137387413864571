@import "style-constants.scss";

.interview-invite-form-view {
    .form-interview-time-slots-wrapper {
        color: $purple-dark;

        .sub-title {
            padding-top: 10px;
        }

        .row-list {
            padding-top: 20px;
            display: grid;
            gap: 20px;
        }

        .slot-item {
            display: grid;
            gap: 20px;
        }

        .slot-item-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .add-new-slot-button {
            margin: -6px 0px;
        }
    }
}
