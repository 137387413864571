@import "style-constants.scss";

.submenu-content-container {
    display: flex;

    .submenu-scroll-container {
        overflow: auto;
        padding: 0 5px 5px;

        .site-header {
            position: fixed;
            top: 0;
            left: calc($submenu-width + $navigation-width);

            &.shrink-nav {
                left: calc($submenu-width + $navigation-width-closed);
            }
        }
        
        .MuiPaper-elevation4 {
            box-shadow: none;
        }

        h1 {
            margin-top: 0;
        }

        @media screen and (max-width: $mobile) {
            width: 100%;
        }
    }

    @media screen and (max-width: $mobile) {
        display: block;
    }
}
