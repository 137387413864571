@import "style-constants.scss";

.cv-section {
    .export-cv-btn {
        display: flex;
        width: min-content;
        align-self: flex-end;
        margin-bottom: 15px;
    }

    .candidate-cv {
        padding-top: 1.30rem;
        display: flex;
        flex-direction: column;
    }
}
