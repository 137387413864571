@import 'style-constants.scss';

.interview-invite-options-dialog {
    .options-labels-wrapper {
        display: grid;
        gap: 25px;

        .invalid-field-message {
            margin-top: -15px;
        }

        .options-label {
            font-size: $medium;
            letter-spacing: 0px;
            color: $purple-dark;
            box-shadow: 0px 0px 10px $grey-medium-shadow;
            border-radius: 4px;
            justify-content: space-between;
            margin: 0;
            padding: 10px 20px;
            text-transform: uppercase;

            &.checked {
                span {
                    font-weight: 500;
                }
            }

            .radio-btn {
                color: $grey-light-short-text;

                .radio-btn-dot {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 18px;
                    height: 18px;
                    outline: 2px solid $grey-light-short-text;
                    border-radius: 100%;
                }

                &.Mui-checked {
                    color: $secondary-color-300;

                    .radio-btn-dot {
                        outline-color: $secondary-color-300;

                        &::after {
                            content: ' ';
                            position: absolute;
                            top: 2px;
                            width: 14px;
                            height: 14px;
                            background: $secondary-color-300;
                            border-radius: 100%;
                        }
                    }

                    &:hover {
                        background-color: rgba($secondary-color-300, 0.04);
                    }
                }

                &:hover {
                    background-color: rgba($grey-light-short-text, 0.04);
                }
            }
        }
    }
}
