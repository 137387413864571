@import "style-constants.scss";

.candidate-job-details-page-header {
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 4px;
    position: relative;
    min-height: 106.42px;
    background-color: $white;

    .job-info {
        padding: 50px 40px 30px;
        display: grid;
        gap: 15px;

        .job-title-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .job-title {
            font-size: $medium-title;
            letter-spacing: 0.61px;
            color: $purple-dark;
            font-weight: 500;
            cursor: pointer;
        }

        .row-items {
            display: grid;
            justify-content: space-between;
            align-items: center;
            grid-auto-flow: column;

            &.justify-start {
                gap: 40px;
                justify-content: flex-start;
            }
        }

        .row-item {
            display: grid;
            gap: 8px;
            justify-content: flex-start;
            align-items: center;
            grid-auto-flow: column;
            font-size: $medium;
            line-height: 21px;
            letter-spacing: 0.28px;
            color: $purple-dark;

            &.location {
                align-items: flex-start;
            }

            svg path {
                fill: $purple-dark;
            }

            a {
                text-decoration: none;
                color: inherit;
            }
        }
    }

    .job-info-toggle {
        display: flex;
        align-items: center;
        letter-spacing: 0.28px;
        color: $purple-dark;
        cursor: pointer;

        p {
            font-size: $medium;
            margin-right: 10.55px;
            text-decoration: underline;
            user-select: none;
        }
    }

    .full-job-details-body {
        padding: 20.15px 41.37px 31.26px 40.25px;
        background-color: $white;
        border-radius: 4px;

        .job-section {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 15px;
            }

            .job-section-title {
                color: $purple-dark;
                font-size: 18px;
                font-weight: 600;
            }

            .job-section-text {
                color: $purple-dark;
                font-size: 16px;
                
                white-space: pre-line;
            }

            .skills-container {
                display: flex;
                flex-wrap: wrap;

                .skill-container {
                    align-items: center;
                    padding: 7px 14px;
                    border: 1px solid #dddddd;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    margin-right: 15px;
                    background-color: $white;
                    cursor: pointer;

                    .skill {
                        color: $grey-dark-text;
                        width: calc(100% - 23px);
                        display: inline;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
