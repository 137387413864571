@import "style-constants.scss";

.recruiter-employees-content {
    .action-header {
        width: 100%;
        display: flex;
        justify-content: center;

        .add-member-button {
            margin-bottom: 20px;
        }
    }
}
