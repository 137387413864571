@import 'style-constants.scss';

.site-header {
    height: $header-height;
    background-color: transparent;
    z-index: 1000;
    position: sticky;
    top: 0;
    width: 100%;
    margin: 0;
    box-shadow: none;
    align-items: center;

    &.with-submenu {
        .search-wrapper {
            padding-left: $submenu-width;
        }
    }

    .toolbar-hidden {
        display: none;
    }

    .MuiToolbar-regular {
        min-height: $header-height;
        padding: 0;
    }

    .MuiBadge-badge {
        height: 18px;
        min-width: 18px;
    }

    .icon {
        color: $grey-dark;
    }

    .plus-icon {
        width: 28px;
        height: 28px;
    }

    .helper-div {
        flex-grow: 1;
    }


    .search-wrapper {
        color: $grey-lighter;
        width: 100%;

        .search-bar {
            border-radius: 18px;
            width: 100%;
            height: $search-bar-height;
            margin-top: 25px;
            margin-bottom: 25px;
        }

        .search-field {
            height: 100%;
            background: $grey-light;
        }

        .search-results-dropdown {
            overflow-y: auto;
            top: calc($header-height - 21px);
            overscroll-behavior: none;

            .search-icon {
                margin-right: 12px;
            }

            .icon-button {
                margin-left: -18px;
            }

            .icon-button:hover,
            .icon-button {
                background-color: transparent;
            }

            h5 {
                font-size: 12px;
            }

            .search-menu {
                margin: 12px 0;
            }

            .search-result-avatar {
                background-color: #edecec;

                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .MuiAvatar-colorDefault {
                background-color: #bdbdbd;
            }
        }

        .people-results-list::-webkit-scrollbar {
            display: none;
        }

        .people-results-list {
            max-height: 250px;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
    }

    @media screen and (max-width: $mobile) {
        display: none;
    }

    // small desktops
    @media screen and (max-width: $small-desktop) {
        .search-wrapper {
            width: $content-shell-width-without-submenu-small-desktop;
        }

        &.with-submenu {
            .search-wrapper {
                width: calc($submenu-width + $content-shell-width-with-submenu-small-desktop);
            }
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .search-wrapper {
            width: $content-shell-width-without-submenu-medium-desktop;
        }

        &.with-submenu {
            .search-wrapper {
                width: calc($submenu-width + $content-shell-width-with-submenu-medium-desktop);
            }
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .search-wrapper {
            width: $content-shell-width-without-submenu-large-desktop;
        }

        &.with-submenu {
            .search-wrapper {
                width: calc($submenu-width + $content-shell-width-with-submenu-large-desktop);
            }
        }
    }
}