@import 'style-constants.scss';

.create-task-form {

    .MuiFormControl-marginNormal {
        margin: 0;
        margin-bottom: 3px;
    }

    .task-name-field{
        margin-top: 12px;
        margin-bottom: 35px;
    }

    .task-description-field{
        margin-top: 12px;
        margin-bottom: 35px;
    }
    
    .task-middle-block {
        display: grid;
        grid-auto-flow: column;
        gap: 5px;
        margin-top: 12px;
        margin-bottom: 32px;
    }

    .switch-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 15px -4px 14px 0;

        .MuiFormControlLabel-label {
            font-size: 14px;
            letter-spacing: 0.24px;
            color: #00000099;
            margin-right: 17px;
        }
    }

    .switch-label {
        margin: 0;
    }

    .reminder-datetime-block {
        display: flex;
        justify-content: space-between;
    }

    .task-date-field {
        margin-right: 20px;
    }

    .datetime-icon {
        cursor: pointer;
        color: $grey-dark;
    }

    .datetime-icon-disabled {
        cursor: pointer;
        color: $grey-light-amount;
    }

    .hidden {
        visibility: hidden;
    }
}
