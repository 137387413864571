@import "style-constants.scss";

.applied-candidate-item-wrapper {
    .application-details-link {
        text-decoration: none;
    }
    .applied-candidate-item {
        position: relative;
        width: 100%;
        min-height: 144px;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px $grey-darker-button;
        border-radius: 18px;
        padding: 40px;

        .item-row-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .applied-candidate-label {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            height: 33px;
            padding: 15px;
            border-bottom-left-radius: 4px;
            border-top-right-radius: 4px;

            .applied-candidate-text {
                text-align: center;
                font-size: 16px;
                line-height: 17px;
                font-weight: 400;
                letter-spacing: 0.11px;
            }
        }

        .applied-candidate-label.pending-review,
        .applied-candidate-label.pending-admin-review,
        .applied-candidate-label.interview-stage,
        .applied-candidate-label.not-vetted {
            background-color: rgba($grey-dark, 0.1);

            .applied-candidate-text {
                color: $grey-dark;
            }
        }

        .applied-candidate-label.hired,
        .applied-candidate.invited {
            background-color: rgba($secondary-color-300, 0.1);

            .applied-candidate-text {
                color: $secondary-color-300;
            }
        }

        .applied-candidate-label.rejected {
            background-color: rgba($red-darker, 0.1);

            .applied-candidate-text {
                color: $red-darker;
            }
        }

        .applied-candidate-person {
            display: grid;
            justify-content: flex-start;
            align-items: center;
            grid-auto-flow: column;
            gap: 20px;
            width: 220px;

            .applied-candidate-avatar {
                .MuiAvatar-circle {
                    width: 51px;
                    height: 52px;
                }
            }

            .applied-candidate-name {
                font-size: 18px;
                line-height: 22px;
                font-weight: 500;
                letter-spacing: 0.61px;
                color: $purple-dark;
            }
        }

        .applied-candidate-date {
            display: grid;
            grid-auto-flow: column;
            gap: 12px;

            .applied-candidate-date-text {
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                letter-spacing: 0.15px;
                color: $medium-dark-shade-blue;
            }
        }

        .applied-candidate-matching {
            position: relative;

            .applied-candidate-matching-text-container {
                display: grid;
                grid-auto-flow: column;
                gap: 10px;
                align-items: center;
                margin-bottom: 15px;

                .applied-candidate-matching-text {
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: 400;
                    letter-spacing: 0.28px;
                    color: $purple-dark;
                }

                .applied-candidate-matching-icon {
                    display: flex;
                    align-items: center;
                }
            }

            .applied-candidate-matching-number {
                font-size: 30px;
                line-height: 18px;
                font-weight: bold;
                letter-spacing: 1.01px;
                color: $purple-dark;
                display: flex;
                align-items: center;

                small { 
                    font-size: 70%;
                    font-weight: 400;
                }


            }

            .applied-candidate-matching-tooltip.hide {
                visibility: hidden;
            }

            .applied-candidate-matching-tooltip.show {
                visibility: visible;
            }

            .applied-candidate-matching-tooltip {
                display: flex;
                align-items: center;
                position: absolute;
                top: 22px;
                right: 10px;
                z-index: 999;
                flex-wrap: wrap;
                max-width: 700px;
                background: $white 0% 0% no-repeat padding-box;
                box-shadow: 0px 1px 3px $grey-shadow;
                border-radius: 4px;
                padding: 21px 28px;
                text-align: left;
                font-size: 14px;
                line-height: 21px;
                font-weight: 300;
                letter-spacing: 0.24px;
                color: $grey-dark-text;
                width: 283px;

                .applied-candidate-matching-tooltip-title {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    letter-spacing: 0.24px;
                    color: $purple-dark;
                    margin: 8px 0 19px 0;
                }
            }
        }

        .applied-candidate-actions {
            align-self: flex-start;

            .three-dots-icon {
                fill: $grey-dark;
            }
        }

        .applied-candidate-note-container {
            margin-top: 22px;
            padding-top: 23px;
            border-top: 1px solid $grey-light-amount;
            width: 100%;
            display: flex;
            align-items: flex-start;

            .note-icon-wrapper {
                margin-right: 10px;
            }

            p {
                color: $purple-dark;
                font-size: $medium;
                letter-spacing: 0.28px;
                line-height: 21px;
            }

            .note-agency-name {
                font-weight: 500;
            }

            .note-content {
                margin-top: 5px;
                word-break: break-word;
                white-space: pre-wrap;
            }
        }

        .send-profile-button-wrapper,
        .rate-candidate-button-wrapper {
            display: flex;
            margin-top: 14px;
            justify-content: center;
        }
    }
}
