@import 'style-constants.scss';

.expand-team-banner-wrapper {
    display: flex;
    background-color: $white;
    padding: 30px 40px 10px;
    margin-top: 55px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px $grey-darker-button;
    justify-content: space-between;

    .expand-team-content {
        width: 75%;
    }

    .expand-team-title {
        font-size: $large-title;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 0.34px;
        color: $purple-dark;
        padding-bottom: 10px;
    }

    .expand-team-additional-text {
        width: 564px;
        font-size: $medium;
        color: $purple-dark;
        letter-spacing: 0.11px;
        opacity: 0.8;
        padding-bottom: 10px;
    }

    .expand-team-actions-wrapper {
        padding: 12px 0px;
    }

    .expand-team-icon-container {
        margin-top: -40px;
    }
}
