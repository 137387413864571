@import "style-constants.scss";

.profile-certificates-info-wrapper {
    .profile-certificate-rows-wrapper {
        .profile-info-row {
            cursor: grab;
            padding-top: 0;
            border-bottom: 1px solid $grey-border;
            padding-bottom: 30px;
            margin-bottom: 30px;
            &:last-child {
                border-bottom: 0; 
                padding-bottom: 0px;
                margin-bottom: 0px;
            }
        }
    }
}
