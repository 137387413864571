@import 'style-constants.scss';

.pie-chart-wrapper {
    position: relative;
    width: 450px;
    margin: 0 auto;

    .pie-chart {
        height: 280px;
        width: 100%;
    }

    .legend-item-wrapper {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .legend-point {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 15px;
        }

        .legend-text {
            font-size: $tiny;
            line-height: 13px;
            font-weight: 400;
            letter-spacing: 0px;
            color: $grey-lighter;
        }
    }

    .pie-chart-tooltip {
        background: $white;
        box-shadow: 0px 1px 18px #0000001F;
        border-radius: 4px;
        padding: 13px 20px;
        font-size: $small;
        line-height: 22px;
        letter-spacing: 0.25px;
        text-align: left;
        color: $purple-dark;
    }

    .background-circle-wrapper {
        position: absolute;
        width: 260px;
        height: 280px;
        z-index: -1;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .background-circle {
            position: relative;
            width: 194px;
            height: 194px;

            .title {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                transform: translate(-50%, -50%);
                color: $purple-dark;
                font-size: $small;
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
            }

            &::after {
                position: absolute;
                content: ' ';
                width: 100%;
                height: 100%;
                background-color: $white;
                border-radius: 50%;
                z-index: -1;
                top: 0;
            }
        }
    }
}