@import 'style-constants.scss';

.three-dots-options-popover {
    .three-dots-popover-list {
        padding: 5px 0;
    }

    .three-dots-popover-list-item {
        padding: 0 25px;
    }

    .list-item-text:hover {
        color: $dark-grey
    }
}
