@import "style-constants.scss";

.cv-skills-wrapper {
    .title {
        margin-right: 10px;
        margin-bottom: 10px;
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .rating-stars-wrapper {
        svg {
            width: 16px;
            height: 16px;
        }
    }

    .skills-container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 10px;

        .skill-box {
            border-radius: 100px;
            padding: 6px 16px;
            max-width: fit-content;
            background-color: $pale-purple;
            margin-right: 12px;
            margin-bottom: 16px;
            display: grid;
            grid-auto-flow: column;
            justify-content: center;
            align-items: center;
            gap: 2px;

            &.client {
                background-color: $pale-orange;
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px $purple-dark solid;

        .item-edit-icon-wrapper {
            cursor: pointer;

            svg:hover {
                cursor: pointer;
    
                rect {
                    fill: $purple;
                }
    
                path {
                    stroke: $white;
                }
            }
        }
    }
}
