@import 'style-constants.scss';

.job-dialog-form {
    .form-row {
        display: grid;
        grid-auto-flow: column;
        gap: 20px;
    }

    .job-image {
        border-radius: 100%;
        border: 1px dashed $dark-grey;
        width: 106px;
        height: 106px;

        &:hover {
            cursor: pointer;
        }
    }

    .job-image-wrapper {
        position: relative;
    }

    .job-image-actions {
        position: absolute;
        top: 44%;
        left: 27%;

        svg * {
            stroke: $orange;
        }

        .add-icon {
            position: absolute;
            left: 44%;
        }
    }

    .countries-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        .grid-row-span-2 { 
            grid-row: span 2;

            .country-checkbox {
                align-self: flex-start;
            }
        }
        
        .country-wrapper {
            margin: 10px 0;
            display: flex;
            align-items: center;

            .country-checkbox {
                margin-right: 5px;
                color: $grey-light-short-text;

                &.Mui-checked {
                    color: $purple-dark;
                }
            }
        }
    }
}