@import 'style-constants.scss';

.topic-row {
    display: flex;
    margin: 10px 0;

    .topic-row-title {
        color: $primary-color-400;
        
        font-size: 15px;
        font-weight: 500;
        margin-right: 20px;
    }

    .row-edit-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        color:$grey-dark;
        background-color: $grey-light;
        border: 1px solid $grey-light;
        border-radius: 14px;
        cursor: pointer;
        margin-right: 7px;
        margin-left: auto;
    }

    .row-edit-icon-wrapper:hover {
        color: $primary-color;
        border: 1px solid $primary-color;
    }

    .row-edit-icon {
        width: 10px;
        height: 10px;
    }

    .row-remove-icon {
        color:$grey-dark;
        width: 23px;
        height: 23px;
        cursor: pointer;
    }

    .row-remove-icon:hover {
        color: $primary-color;
    }
}
