@import 'style-constants.scss';

.agency-dashboard-wrapper {
    .banner-wrapper {
        margin-bottom: 40px;
    }

    .team-statistics-container .section-wrapper {
        margin-bottom: 20px;
    }

    .recent-employees-container .section-wrapper {
        margin-bottom: 11.5px;
    }
    
    .active-holidays-container .active-holidays-chart-wrapper {
        margin-bottom: 40px;
    }

    .flex-items-wrapper {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 48% 48%;
        justify-content: space-between;

        &.with-wrap {
            @media screen and (max-width: $mobile) {
                grid-auto-flow: row;
                grid-template-columns: 1fr;
                justify-content: flex-start;
            }
        }
    }
}
