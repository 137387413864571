@import 'style-constants.scss';

.dashboard-upcoming-interviews-wrapper {
    border-radius: 24px;
    background-color: #FFFFFF;
    box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
    padding: 24px;

    .dashboard-upcoming-interviews-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
    }

    .dashboard-upcoming-interviews-view-all-btn {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        svg * {
            stroke: $deep-orange;
        }
    }

    .dashboard-upcoming-interviews-container {
        display: grid;
        grid-auto-flow: row;
        gap: 5px;
        grid-template-columns: 1fr;
    }

    .dashboard-upcoming-interviews-interview-container {
        border-radius: 12px;
        background-color: $white;
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
        padding: 8px;

        &:nth-child(odd) {
            background-color: $app-background;
        }
    }

    .dashboard-upcoming-interviews-interview-candidate-info {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 5px;
        align-items: center;
    }

    .dashboard-upcoming-interviews-interview-dates-info {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 3px;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }
}