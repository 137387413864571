@import 'style-constants';

.dashboard-saved-candidates-wrapper {
    max-height: 400px;
    display: grid;
    grid-auto-flow: row;
    justify-content: flex-start;
    grid-template-rows: 15% 10% 75%;
    grid-template-columns: 1fr;
    border-radius: 24px;
    background-color: $white;
    box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
    padding: 24px;

    .three-dots-action:hover {
        cursor: pointer;
    }

    .dashboard-saved-candidates-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
    }

    .dashboard-saved-candidates-table-content {
        overflow-x: scroll;
        display: flex;
        flex-direction: column;
    }

    .dashboard-saved-candidates-table-headers,
    .dashboard-saved-candidates-table-content-row {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 28% 58% 7% 7%;
        align-items: center;
    }

    .dashboard-saved-candidates-table-content-row {
        margin-bottom: 10px;
        padding: 12px 0;

        .dashboard-save-candidates-table-content-candidate-details {
            display: grid;
            grid-auto-flow: column;
            gap: 10px;
            justify-content: flex-start;
            align-items: center;
        }

        .dashboard-save-candidates-table-content-stack {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            
            .stack-info-box {
                border-radius: 100px;
                border: 1.5px solid $dark-grey;
                padding: 0px 10px;
                margin-right: 4px;
                margin-bottom: 4px;
                display: grid;
                align-items: center;
                align-self: center;
                background-color: $white;
            }
        }

        .MuiAvatar-root {
            width: 32px;
            height: 32px;
        }

        &:nth-child(odd) {
            background-color: $app-background;
            border-radius: 12px;
        }
    }

    .view-all-button {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;

        svg {
            margin-top: 5px;

            * {
                stroke: $deep-orange;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}