@import 'style-constants.scss';

.individual-training-container {
    text-align: -webkit-center;

    .individual-training-wrapper,
    .topic-wrapper {
        .actions-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .individual-training-description-wrapper,
        .topic-description-wrapper {
            margin-bottom: 30px;
            
            .description-content {
                padding: 30px 20px 20px 20px;

                .description-text-field {
                    width: 100%;
                }
            }
        }
    }
}
