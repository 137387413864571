@import "style-constants.scss";

.candidate-basic-information {
    margin: auto;
    padding: 30px 40px;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 18px;
    position: relative;
    background-color: $white;

    .upload-input {
        display: none;
    }

    .candidate-avatar.darker {
        -webkit-filter: brightness(80%);
    }

    .candidate-avatar-wrapper {
        position: relative;

        .avatar-actions {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .icon-button {
                width: 18px;
                height: 18px;
                padding: 0;
            }

            .avatar-delete-button {
                background-color: $grey-dark;
                color: $white;

                .close-icon {
                    width: 15px;
                    height: 15px;
                    margin-bottom: 5px;
                }
            }

            .avatar-edit-button {
                background-color: $white;
                border: 2px $purple-dark solid;
                color: $purple-dark;
                margin-left: 10px;

                .edit-sharp-icon {
                    width: 12px;
                    height: 12px;
                    margin-bottom: 6px;
                }
            }
        }
    }

    .header-wrapper {
        display: flex;
        justify-content: space-between;

        .MuiAvatar-root {
            width: 106px;
            height: 106px;
        }

        .header {
            display: grid;
            gap: 2rem;
            grid-auto-flow: column;
            justify-content: flex-start;

            .header-info {
                margin-top: 0.5rem;

                .candidate-name-container {
                    display: flex;
                    gap: 0.74rem;

                    .candidate-name {
                        font-size: 1.125rem;
                        font-weight: 500;
                        letter-spacing: 0.31px;
                        color: $purple-dark;
                    }

                    .name-edit-button {
                        background-color: $grey-light;
                        width: 24px;
                        height: 24px;
                        padding: 0;

                        .edit-sharp-icon {
                            width: 15px;
                            height: 15px;
                            margin-bottom: 5px;
                        }
                    }
                }

                .candidate-email {
                    margin-top: 0.874375rem;
                    display: flex;
                    width: auto;
                    gap: 0.74rem;
                    align-items: center;

                    .MuiSvgIcon-root {
                        font-size: 1.5625rem;
                        color: $grey-dark;
                    }

                    .candidate-email-address {
                        font-size: 1rem;
                        letter-spacing: 0.15px;
                        color: $purple-dark;
                    }

                    .email-edit-button {
                        background-color: $grey-light;
                        width: 24px;
                        height: 24px;
                        padding: 0;

                        .edit-sharp-icon {
                            width: 15px;
                            height: 15px;
                            margin-bottom: 5px;
                        }
                    }
                }

                .registration-information {
                    margin-top: 0.874375rem;
                    display: flex;
                    justify-content: center;
                    gap: 0.74rem;

                    .registration-date {
                        background-color: $secondary-color-background;
                        color: $actions-green;
                        font-size: 14px;
                        font-weight: 500;
                        padding: 2px 7px;
                        border-radius: 4px;
                    }

                    .registration-type {
                        font-size: 14px;
                        font-weight: 500;
                        padding: 2px 7px;
                        border-radius: 4px;
                    }

                    .invited-by-admin {
                        background-color: rgba($pink-light, 0.1);
                        color: $pink-light;
                    }

                    .linkedinpaidjobpost {
                        background-color: rgba($primary-color, 0.1);
                        color: $primary-color;
                    }

                    .googleadcampaign {
                        background-color: rgba($red-darker, 0.1);
                        color: $red-darker;
                    }

                    .other-sources {
                        background-color: rgba($grey-dark-text, 0.1);
                        color: $grey-dark-text;
                    }

                    .registration-link {
                        background-color: rgba($orange, 0.1);
                        color: $orange;
                    }
                }
            }
        }
    }

    .divider {
        width: 100%;
        margin: 0;
        margin-top: 1.2rem;
        border: 1px solid $grey-light-amount;
    }

    .assignee-information {
        display: flex;
        justify-content: space-between;
        padding-top: 1.5rem;

        .row-title {
            flex-basis: 30%;
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            align-items: center;
            gap: 0.878125rem;
            color: $purple-dark;

            svg {
                width: 25.33px;
            }

            .title {
                flex-basis: 80%;
                font-size: 1rem;
                font-weight: 500;
            }
        }

        .row-value {
            flex-basis: 20%;
            font-size: 1rem;
            letter-spacing: 0.15px;
            color: $medium-dark-shade-blue;
        }

        .edit-icon-container {
            width: 24px;
            height: 24px;

            .edit-button {
                background-color: $grey-light;
                width: 24px;
                height: 24px;
                padding: 0;

                .edit-sharp-icon {
                    width: 15px;
                    height: 15px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}
