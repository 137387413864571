@import "style-constants.scss";

.default-your-account-manager-info-container {
    margin: 0px auto 0px auto;
    width: 100%;

    .actions-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .your-account-manager-info-content {
        display: flex;
        justify-content: space-between;
        padding: 33px 85px 33px 85px;

        .info-section-content {
            display: flex;
            margin-top: 5px;
        }

        .manager-name {
            margin: auto;
            &.is-tbc {
                margin-top: 10px;
                margin-left: 0;
            }
        }

        .manager-avatar-image {
            width: 40px;
            height: 40px;
        }

        .info-section {
            display: flex;
            flex-direction: column;

            .info-section-title {
                display: flex;
            }

            svg {
                color: $grey-dark;
            }

            h5 {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.21px;
                color: #0415ac;
                margin-left: 8px;
            }

            span {
                font-size: 14px;
                letter-spacing: 0.24px;
                color: $grey-normal-title;
                margin-top: 15px;
                margin-left: 29px;
            }
        }
    }
}
