@import 'style-constants.scss';

.holiday-requests-wrapper {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 40px;

    .kpi-item {
        grid-template-columns: none;

        .kpi-item-amount {
            color: $secondary-color;
        }

        &.normal {
            .kpi-item-amount {
                color: $grey-light-amount;
            }
        }

        &.remaining {
            .kpi-item-amount {
                color: $red-darker;
            }
        }

        &.booked {
            .kpi-item-amount {
                color: $secondary-color;
            }
        }

        &.unpaid {
            .kpi-item-amount {
                color: $secondary-color;
            }
        }
    }

    .table-wrapper {
        width: fit-content;
        height: fit-content;
        margin-left: auto;

        .table-aligned-items {
            margin: 0;
        }
    }

    @media screen and (min-width: calc($medium-desktop + 1px)) {
        margin-bottom: 60px;
        grid-template-columns: 1fr auto;

        .kpi-item {
            padding: 10px;
        }
    }

    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .kpi-item {
            padding: 10px 5px;
        }
    }

    @media screen and (max-width: $small-desktop) {
        .kpi-item {
            padding: 10px 5px;
        }
    }
}

.holidays-and-weekends {
    .MuiTypography-colorInherit {
        color: $grey-light-amount;
    }
}
