@import 'style-constants.scss';

.job-search-empty-section-wrapper {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;

    p {
        text-align: center;
    }

    svg {
        margin: auto;
    }
}