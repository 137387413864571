@import 'style-constants.scss';

.database-skill-dialog-wrapper {

    .MuiDialog-paper {
        width: $page-content-width;
        padding: 0 16px; 
    }

    .database-skill-dialog-header {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: flex-end;
    }

    .database-skill-form {
        width: 100%;
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .database-skills-checkbox-wrapper {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
    }

    .dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
        margin-top: -15px;
    }

    .dialog-title {
        margin-bottom: 20px;
        text-align: center;
        white-space: pre-wrap;
    }

    .dialog-actions {
        margin-top: 12px;
        margin-bottom: 35px;
        display: flex;
        justify-content: center;
    }
}
