@import "style-constants.scss";

.right-aligned-section-actions-container {
    display: grid;
    grid-auto-flow: column;
    flex: 20 1 200px;
    gap: 7px;
    justify-content: flex-end;

    &.no-margin {
        margin: 0;
    }
}
