@import "style-constants.scss";

.main-layout-wrapper {
    display: flex;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;

    .main-layout-body-wrapper {
        transition: width 0.3s;
        width: 100%;
        z-index: 2;

        .main-layout-content {
            height: 100%;
            overflow-y: scroll;
            overflow-x: auto;
            margin-right: -10px; // because of scrollbar width
        }

        .hidden-header {
            height: 100vh;
        }

        &.navigation-close {
            width: calc(100% - $navigation-width-closed);
        }

        .site-header {
            background-color: $app-background;
        }

        &.primary-light {
            background-color: $app-background;

            .site-header {
                background-color: $app-background;
            }
        }
    }

    .main-layout-body-wrapper.navigation-close {
        width: calc(100% - $navigation-width-closed);
    }

    @media screen and (max-width: $mobile) {
        .main-layout-body-wrapper {
            width: 100%;

            &.navigation-close {
                width: 100%;
            }

            .main-layout-content {
                padding: 10px;
            }
        }
    }
}

.main-layout-wrapper::-webkit-scrollbar {
    display: none;
}
