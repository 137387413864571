@import "style-constants.scss";

.profile-upload-cv-wrapper {
    margin-top: 20px;

    .profile-upload-cv-content {
        min-height: 80px;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 30px 0;

        .content-header {
            &.uploaded {
                color: $grey-dark;
                font-weight: 600;
            }
        }

        .cv-file-name {
            margin: 10px 0;
            cursor: pointer;
        }

        .download-cv-icon-button {
            margin-bottom: -20px;

            .download-cv-icon {
                color: $actions-green;
            }
        }

        .upload-cv-button {
            margin: 10px 250px;
        }

        .arrow {
            color: #999;
            width: 30px;
            height: 30px;
        }

        .suggestion {
            margin: 10px 0;
            font-weight: 600;
            color: #444;
        }

        .row {
            text-align: center;
            width: 100%;
        }
    }
}
