@import 'style-constants.scss';

.company-information-wrapper {
    margin-bottom: 20px;

    .company-information-content-wrapper {
        padding: 40px;
    }

    .company-information-form-field {
        margin-bottom: 34px;

        &.last {
            margin-bottom: 0;
        }
    }
}
