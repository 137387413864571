@import 'style-constants.scss';

.labeled-switcher-wrapper {
    border: 1px solid $dark-grey;
    border-radius: 32px;
    margin-bottom: 35px;

    .labeled-switcher-container {
        display: grid;
        grid-auto-flow: column;
        padding: 7px;

        .option {
            border-radius: 39px;
            background-color: $white;
            color: $grey;
            text-align: center;
            padding: 5px 0;

            &.selected {
                background-color: $dark-grey;
                color: $white;
            }

            &.option-disabled {
                background-color: $white;
                color: $dark-grey;

                &:hover {
                    cursor: not-allowed;
                }
            }
        }
    }

    .labeled-switcher-container:not(.option-disabled):hover {
        cursor: pointer;
    }
}