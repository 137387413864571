@import 'style-constants.scss';

.candidate-layout-wrapper {
    position: relative;
    display: flex;
    min-height: 100vh;
    overflow: auto;

    .candidate-layout-body-wrapper {
        width: 100%;
        background-color: $white;
    }

    .candidate-layout-content {
        overflow: auto;
        padding: 60px 30px 30px;
    }
}
