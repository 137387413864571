@import "style-constants.scss";

.tech-stack-dialog {
    .MuiDialog-container {
        .MuiPaper-root {
            width: 28.875rem;
            .dialog-title-text {
                letter-spacing: 0.11px;
                font-weight: 500;
            }
            .MuiButton-label {
                font-weight: 500;
            }
        }
    }

    .tech-stack-options {
        display: flex;
        flex-wrap: wrap;
    }

    .single-technology-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        margin-right: 12px;
        padding: 5px 10px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        background-color: $white;
        color: $purple-dark;
        cursor: pointer;

        .technology-name {
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.29px;

            &::before {
                display: block;
                content: attr(data-content);
                font-weight: 400;
                height: 0px;
                color: transparent;
                overflow: hidden;
                visibility: hidden;
            }
        }

        .jobs-count-badge {
            border: 1px solid #dddddd;
            border-radius: 4px;
            background-color: #f6f6f6;
            padding: 4px;
            margin-left: 7px;

            span {
                font-size: 14px;
                font-weight: 300;
            }
        }

        &.without-tech-stack {
            opacity: 0.5;
        }
    }
}
