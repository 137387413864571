@import 'style-constants.scss';

.job-search-card-wrapper {
    padding: 16px;
    border-radius: 24px;
    background-color: $white;
    box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);

    .job-search-card-header {
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        justify-content: flex-start;
    }

    .job-search-card-container {
        margin-top: 15px;
        display: grid;
        grid-auto-flow: row;
        gap: 5px;
    }

    .info-row {
        display: grid;
        grid-auto-flow: column;
        gap: 5px;
        justify-content: flex-start;
        align-items: center;

        svg {
            * {
                stroke: $grey;
            }
        }
    }

    .job-search-card-actions {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        gap: 20px;
    }

    .job-img {
        border-radius: 100%;
        width: 40px;
        height: 40px;
    }
}