@import "style-constants.scss";

.guest-wrapper {
    display: flex;
    min-height: 100vh;
    background-color: #FAFAFA;

    .login-card-header {
        .login-card-title {
            font-size: 20px;
            color: $purple-dark;
            text-align: center;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .login-card-subtitle {
            font-size: 16px;
            color: $purple-dark;
            text-align: center;
            margin-bottom: 20px;
        }

        .signup-ref {
            text-decoration: none;
            color: $primary-color-700;
        }
    }

    .guest-image {
        background-size: cover;
        width: 50%;
        background-position: center;
        background-repeat: no-repeat;
    }

    .guest-introduction {
        display: flex;
        width: 50%;
        flex-direction: column;
        justify-content: center;
        padding: 70px 80px 90px 80px;
        background-color: $light-shade-blue;

        .trademark-image {
            width: 142px;
            background-size: contain;
            position: absolute;
            top: 50px;
        }
    }

    .guest-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        overflow: auto;
        margin: 0 auto;

        .guest-content-body {
            height: 100vh;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 0;
        }

        &.mobile {
            .guest-content {
                width: 100%;
            }

            .guest-content-body {
                height: 100vh;
                display: block;
            }
        }

        &.register-mobile {
            background: url("../../assets/mountain-no-border.png");
            background-repeat: no-repeat;
            background-size: cover;

            .guest-content-body {
                z-index: 0;
            }
        }

        &.register-mobile::before {
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background: transparent url('../../assets/transparent-background.svg');
            background-repeat: no-repeat;
            background-size: cover;
        } 
    }

    @media screen and (max-width: $mobile) {
        position: relative;
        .guest-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .guest-content {
            position: relative;
            z-index: 1;
            width: 100%;
            overflow-y: hidden;

            .login-container {
                background: rgba(250, 250, 250, 0.9);
                border-radius: 8px;
            }
        }
    }
}
