@import 'style-constants.scss'; 

.employee-performance-review-history {
    padding-left: 0;

    .no-reviews-info {
        padding-top: 20px;
        padding-left: 45px;
        color: #7E7E7E;
    }
}
