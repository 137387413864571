@import 'style-constants.scss';

.profile-request-wrapper {
    max-width: $large-desktop;
    margin: auto;

    .matching-jobs-loader {
        margin: auto;
        margin-top: auto;
        display: flex;
        margin-top: 100px;
    }

    .matching-jobs-container {
        margin-top: 20px;
    }
}

.grouped-select-filter-list-menu.matching-jobs {
    .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
        margin-left: 25px;
    }
}
