@import 'style-constants.scss';

.my-applications-list-wrapper {
    margin-bottom: 40px;

    .my-applications-list {
        padding-top: 10px;
        margin-bottom: 20px;
        display: grid;
        gap: 20px;
    }

    .my-application-card-link {
        text-decoration: none;
    }

    .my-application-card-wrapper {
        display: grid;
        grid-auto-flow: row;
        border-radius: 24px;
        background-color: $white;
        padding: 22px 32px;
        box-shadow: 0px 1px 3px rgba(121, 121, 121, 0.1607843137);
        gap: 16px;

        .my-application-card-heading {
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
            align-items: center;

            .company-info {
                display: grid;
                grid-auto-flow: column;
                justify-content: flex-start;
                gap: 12px;

                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 8px;
                }
            }
        }

        .position-info {
            display: grid;
            grid-auto-flow: row;
            justify-content: flex-start;
            gap: 10px;
        }

        .additional-details-wrapper {
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
            align-items: center;
        }

        .additional-details-content {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            gap: 48px;

            .detail {
                display: grid;
                grid-auto-flow: column;
                justify-content: flex-start;
                gap: 6px;
            }
        }

        .link {
            color: $deep-purple;
            font-size: $subheading-xs;
            font-weight: 600;
            line-height: 24px;
            text-decoration: none;
            
            &:hover {
                color: $purple;
            }
        }

        .application-status {
            padding: 10px 24px;
            border-radius: 8px;
            height: min-content;

            &.not-vetted,
            &.pending-review,
            &.pending-admin-review,
            &.interview-stage {
                color: $deep-orange;
                border: 1px solid $deep-orange;
                background-color: $pale-orange;
            }

            &.hired,
            &.invited {
                color: $green-darker;
                border: 1px solid $green-darker;
                background-color: $pale-green;
            }

            &.rejected {
                color: $red;
                border: 1px solid $red;
                background-color: $pale-red;
            }
        }
    }

    .no-applications-found {
        max-width: $content-shell-width-with-submenu-medium-desktop;
        display: grid;
        margin: 80px auto;
        position: relative;
        background-color: $app-background;

        .no-applications-text {
            position: absolute;
            bottom: 10%;
            left: 43%;
        }
    }

    .contact-hr-wrapper {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        gap: 10px;
        background-color: $white;
        border-radius: 24px;
        padding: 32px;
        box-shadow: 0px 1px 3px rgba(121, 121, 121, 0.1607843137);

        .contact-hr-content {
            display: grid;
            grid-auto-flow: row;
            justify-content: flex-start;
        }

        .contact-hr-button {
            height: min-content;
            margin: auto;
        }
    }
}