@import 'style-constants.scss';

.applied-candidates-wrapper {
    width: 100%;
    margin-bottom: 30px;

    .applied-candidates-title {
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0.11px;
        color: $purple-dark;
        margin-bottom: 20px;
    }

    .applied-candidates-sub-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: $purple-dark;
        display: flex;
        align-items: center;
        height: 25px;
        margin-bottom: 28px;
        opacity: 0.8;

        svg {
            margin-left: 10px;
        }
    }

    .applied-candidates-tabs-container {
        display: grid;
        grid-auto-flow: column;
        gap: 40px;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 2px solid $grey-light-amount;

        .applied-candidates-tab {
            cursor: pointer;

            &.active-candidates-tab {

                .applied-candidates-tab-title {
                    font-weight: 500;
                    color: $purple-dark;
                }
            }

            .applied-candidates-tab-title {
                text-align: left;
                font-size: 16px;
                line-height: 19px;
                font-weight: 400;
                letter-spacing: 0.54px;
                color: $grey-dark;
                text-transform: capitalize;
                padding-bottom: 5px;
            }
        }
    }
}
