@import "style-constants.scss";
.candidate-journey-preferences {
    display: flex;
    flex-direction: column;
    align-items: center;

    .candidate-journey-preferences-view {
        margin: 0 auto;
        width: 100%;
        
        @media screen and (max-width: $mobile) {
            padding: 0;
        }

        .form-row {
            margin-bottom: 20px;
        }

        .form-title {
            color: $purple-dark;
            letter-spacing: 0.61px;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 32px;
        }

        .form-sub-title {
            margin-bottom: 15px;
        }

        //change the calendar icon color to be blue
        // when the earliest date field is focused
        .Mui-focused {
            .MuiSvgIcon-root {
                color: $grey;
            }
        }

        //change the calendar icon color to be red
        //when there is an error for the earliest date field
        .Mui-error {
            .MuiSvgIcon-root {
                color: #f44336;
            }
            .MuiOutlinedInput-root {
                outline: none;
            }
        }

        .MuiFormLabel-root.Mui-focused {
            font-weight: 500;
            font-size: 17px;
            letter-spacing: 0.18px;
        }

        .candidate-isAvailability-unknown {
            display: flex;
            height: 56px;
            align-items: center;

            .custom-switch-control {
                flex-direction: initial;

                .custom-switch {
                    margin-left: 0;
                    margin-right: 6px;
                }
            }
        }

        .MuiInput-underline:after {
            border-bottom: 1px solid red;
        }

        .form-cols {
            display: flex;
            justify-content: space-between;

            .col-1of2 {
                flex-basis: 48.5%;
            }
        }

        .base-input .MuiOutlinedInput-notchedOutline {
            border-radius: 16px;
            border: 1px solid $pale-gray;
        }

        .base-input input:focus ~ fieldset {
            border-color: $dark-grey;
        }
      
        .MuiInputBase-input {
            color: $dark-grey;
        }

        .MuiFormLabel-root {
            background-color: white;
            color: $grey;
        }

        .with-error .MuiFormLabel-root {
            color: $red;
        }

        .Mui-focused {
            color: $grey;
        }
    }
}
