@import 'style-constants.scss';

.topic-employee-container {
    width: $page-content-width;
    margin: 20px auto;

    .topic-files-container {
        .files-content {
            color: inherit;
            padding: 0 30px 24px;
            margin-bottom: 42px;
            margin-top: 11px;
        }
    }

    .topic-overview-container {
        width: 100%;
        padding-bottom: 30px;
    }

    .overview-wrapper {
        text-align: left;

        .overview-body {
            padding: 30px 44px 30px 30px;
            font-size: 14px;
            font-weight: normal;
            
            margin: 0;
            color: $grey-normal-title;
            white-space: pre-wrap;
        }
    }
}
