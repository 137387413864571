@import "style-constants.scss";

.training-card-wrapper {
    .training-card-content {
        padding: 20px 25px;
        padding-bottom: 0;
    }

    .title {
        color: $purple-dark;
        font-size: $large-title;
        letter-spacing: 0.26px;
    }

    .description {
        padding: 18px 0;
        color: $purple-dark;
        font-size: $small;
        letter-spacing: 0.24px;
        opacity: 0.8;
    }

    .training-card-footer {
        padding: 15px 25px 20px 25px;

        .lessons-info {
            color: $purple-dark;
            font-size: $small;
            letter-spacing: 0.18px;
            opacity: 0.8;
        }

        .lessons-count {
            color: $primary-color-700;
            opacity: 1;
            font-weight: 500;
            padding-left: 5px;
        }
    }

    hr {
        border: 1px solid $grey-light-medium;
    }

    &:hover {
        cursor: pointer;
    }
}