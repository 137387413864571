@import 'style-constants.scss';

.recent-employees-container {
    display: flex;
    flex-direction: column;

    .recent-employees-paper {
        display: grid;
        gap: 10px;
        flex-grow: 1;
        align-items: flex-start;
        align-content: flex-start;
        padding: 15px 0px 15px 40px;
        grid-template-rows: min-content;

        .MuiTypography-body1 {
            font-size: $medium;
            line-height: 19px;
            letter-spacing: 0.11px;
            color: $purple-dark;
        }

        .MuiTypography-body2 {
            font-size: $small;
            font-weight: 300;
            line-height: 15px;
            letter-spacing: 0.11px;
            color: $white;
        }

        .flex-wrapper {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
        }

        .employee-wrapper {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: 45% 29% 21%;
            align-items: center;
            justify-content: space-between;
            grid-auto-rows: minmax(40px, auto);
            gap: 10px;
        }

        .name-avatar {
            justify-content: flex-start;
            gap: 15px;
            text-decoration: none;
            color: inherit;

            .avatar {
                width: 30px;
                height: 30px;
            }
        }

        .date {
            justify-content: flex-start;
            gap: 7.5px;
        }

        .label-wrapper {
            display: flex;
            justify-self: flex-end;
            justify-content: center;
            width: 81px;
            padding: 5px 0px;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;

            &.started {
                background-color: $teal;
            }

            &.left {
                background-color: $red-darker;
            }
        }
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .recent-employees-paper {
            .MuiTypography-body1 {
                font-size: $small;
            }

            .MuiTypography-body2 {
                font-size: $smallest;
            }
        }
    }
}
