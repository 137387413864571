@import 'style-constants.scss';

.attached-files-container {
    overflow-wrap: break-word;

    border: 1px solid $pale-gray;
    border-radius: 16px;
    padding: 16px;
    margin: 8px 0;

    .attached-list-title {
        color: $grey-dark;
        font-size: 16px;
        letter-spacing: 0.15px;
        margin-bottom: 2px;
    }

    .MuiList-root,
    .MuiListItem-secondaryAction {
        padding: 0;
    }

    .MuiListItemAvatar-root {
        margin-right: 4px;
        min-width: 0;
    }
}
