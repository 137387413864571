@import 'style-constants.scss';

.custom-checkbox {
    &.Mui-checked {
        color: $secondary-color-300;
    }

    &.Mui-disabled {
        color:#808495;
    }
}
