@import "style-constants.scss";

.add-comments-dialog {
    .profile-name-position-wrapper {
        max-width: 50%;
    }

	.checkbox-container {
		display: flex;
		align-items: center;

		.notification-text {
			color: $grey-dark;
			font-size: $small;
			margin: auto 0;
		}
	}
}
