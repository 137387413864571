@import "style-constants.scss";

.terms-and-conditions-label {
    margin-left: 0px;
    margin-right: 0px;

    .MuiFormControlLabel-label {
        font-size: $subheading-m;
        color: $white;
        margin-left: 10px;
    }

    a {
        color: $pale-purple;
    }
}
