@import 'style-constants.scss';

.image-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;

    .image {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 150px;
        height: 150px;
        background-color: #edecec;

        &.no-background {
            background-color: transparent;
        }

        .uploaded-picture {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }

        .default-avatar-container {
            width: 100%;
            height: 100%;
            background-color: $white;
        }

        .MuiAvatar-root {
            width: 100%;
            height: 100%;
        }
    }

    .photo-info {
        margin-left: 30px;
        padding-top: 5px;
        flex-grow: 1;
    }

    .photo-list-item {
        display: flex;
        align-items: flex-end;
        margin-bottom: 12px;
    }

    .item-key {
        min-width: 40px;
        font-size: 12px;
        letter-spacing: 0.4px;
        color: #7e7e7e;
        margin-right: 17px;
    }

    .item-value {
        font-size: 14px;
        letter-spacing: 0.43px;
        color: #00000099;
    }
}

.photo-upload-buttons {
    display: flex;
    justify-content: space-between;

    .upload-input {
        display: none;
    }
}

.edit-photo-button {
    padding-left: 15px;
}
