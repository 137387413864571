@import "style-constants.scss";

.prod-guest-layout {
    overflow: auto;
    height: 100vh;

    .prod-guest-wrapper {
        display: flex;
        min-height: 100vh;
        position: relative;
    }

    .guest-introduction {
        display: flex;
        width: 50%;
        flex-direction: column;
        justify-content: center;
        padding: 12rem 80px 90px 80px;
        background-color: $light-shade-blue;

        .trademark-image {
            width: 142px;
            background-size: contain;
            position: absolute;
            top: 50px;
        }
    }

    .guest-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        overflow: auto;

        .guest-content-body {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
    }

    .welcome-candidate-wrapper {
        svg {
            margin-left: -9px;
            padding-top: 2px;
        }

        .footer-content-title {
            color: $purple-dark;
            font-size: $large-title-extra;
            letter-spacing: 0.74px;
            text-transform: uppercase;
            opacity: 1;
            font-weight: 500;
        }

        .benefits-list {
            margin-top: 30px;
            display: grid;
            grid-auto-flow: row;
            gap: 30px;

            .item {
                list-style-type: none;
                display: grid;
                grid-auto-flow: column;
                gap: 10px;
                justify-content: flex-start;
            }

            .item-text {
                display: flex;
                align-self: center;
                text-align: left;
                font-size: $medium-title;
                letter-spacing: 0.11px;
                color: $purple-dark;
                opacity: 1;
            }
        }

        .partner-logos {
            margin-top: 60px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 13px;

            .image-wrapper {
                width: 137px;
            }

            .logo-image {
                display: flex;
                align-self: center;
                margin-bottom: 25px;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        position: relative;
        .guest-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .guest-content {
            position: relative;
            z-index: 1;
            width: 100%;

            .guest-content-body {
                padding: 0 10px;

                &.mobile {
                    padding: 0;
                }
            }

            .login-container {
                background: rgba(250, 250, 250, 0.9);
                border-radius: 8px;
            }
        }
    }
}
