@import "style-constants.scss";

.start-candidate-journey-mobile-wrapper {
    width: $start-candidate-journey-width;

    display: flex;
    margin: auto;
    flex-direction: column;
    width: 100%;

    .start-candidate-journey-header {
        .primary-header {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.84px;
            color: $purple-dark;
        }
    
        .secondary-header {
            font-size: 16px;
            letter-spacing: 0px;
            color: $purple-dark;
            margin-top: 30px;
        }

        margin-bottom: 20px;
    }

    .start-candidate-journey-sub-header {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0px;
        color: $purple-dark;
        margin-bottom: 20px;
    }

    .start-candidate-journey-steps-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .start-candidate-journey-icon-container {
            .start-candidate-journey-icon {
                width: 80%;
            }
        }
    }

    .single-step {
        display: flex;
        font-size: 16px;
        letter-spacing: 0px;
        color: $purple-dark;

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        .common-line {
            margin-bottom: 10px;

            span {
                display: inline;
            }

            .step-title {
                margin-right: 8px;
            }
        }

        .step-box {
            box-shadow: 0px 1px 3px #00000033;
            border-radius: 4px;
            background-color: $purple-dark;
            display: flex;
            margin-right: 14px;
            width: 30px;
            height: 32px;

            .box-text {
                color: $white;
                margin: auto;
            }
        }

        .step-duration {
            font-weight: 300;
        }

        .step-description {
            font-size: 14px;
        }
    }

    .highlighted-text {

        &.green {
            color: $secondary-color-300;
        }
    }

    .start-candidate-journey-button {
        display: flex;
        margin: 30px auto auto;
        padding: 13px 33px;
    }
}    