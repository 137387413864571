@import 'style-constants.scss';

.application-process-banner-wrapper {
    padding: 32px 74px;
    background-color: $white;
    box-shadow: 0px 1px 3px $grey-medium-shadow;
    border-radius: 18px;

    .welcome-back-wrapper {
        display: grid;
        gap: 15px;
        margin-bottom: 30px;
        padding: 0px 80px;
        justify-content: center;
        text-align: center;

        .welcome-back-title {
            font-size: $medium-title;
            font-weight: 500;
            letter-spacing: 0.31px;
            color: $purple-dark;
        }

        .welcome-back-text {
            font-size: $small;
            line-height: 22px;
            letter-spacing: 0.25px;
            color: $medium-dark-shade-blue;
        }

        .welcome-back-update-btn {
            width: fit-content;
            margin: 0 auto;
        }
    }

    .application-process-banner-title {
        margin-bottom: 21px;
        text-align: center;
        display: grid;
        grid-auto-flow: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
    }

    .application-process-banner-steps-container {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: space-between;
    }

    .info-icon {
        &:hover {
            cursor: pointer;
        }
    }

    .arrow-icon {
        margin-top: 5px;
    }

    .step-container {
        text-align: center;

        .step-text-wrapper {
            max-width: 95px;
            margin: 0 auto;

            .step-text {
                font-size: $medium;
                font-weight: 300;
                line-height: 21px;
                letter-spacing: 0.28px;
                color: $purple-dark;
                text-align: center;
            }
        }
    }
}