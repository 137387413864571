@import "style-constants.scss";

.table-category-filter-wrapper {
    display: grid;
    grid-auto-flow: column;
    gap: 5px;
    margin-bottom: 20px;

    .table-category-filter-element {
        text-align: center;
        background-color: $white;
        color: $grey-dark;
        letter-spacing: 0.24px;
        opacity: 0.5;
        border-radius: 10px 10px 0px 0px;
        padding: 8px 0;

        :hover {
            cursor: pointer;
        }

        &.selected {
            background-color: $purple-dark;
            color: $white;
            opacity: 1;
        }
    }
}