@import "style-constants.scss";

.holidays-review-wrapper { 

    .holidays-content {
        width: 100%;

        .actions-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 10px;
        }

        .buttons-wrapper {
            display: flex;
            justify-content: space-between;
            margin-left: 40px;
        }
    }
}

.gant-chart {
    margin: -20px auto;
    height: 2000px;
}
