@import "style-constants.scss";

.booked-coaching-sessions {
    padding: 30px;
    background-color: $white;
    box-shadow: 0px 1px 3px $grey-darker-button;
    min-width: 777px;

    .sessions-timeline-wrapper {
        .MuiTimelineDot-root {
            margin: 0;
            padding: 4.7px;
            box-shadow: none;
            background-color: #e1e1e1;
        }

        .MuiTimelineConnector-root {
            width: 4px;
            background-color: #e1e1e1;
        }

        .placeholder-connector {
            min-height: 40px;
            align-self: center;

            &::before {
                padding: 0px;
            }
        }

        .booked-session-item {
            min-height: 120px;

            &.MuiTimelineItem-missingOppositeContent::before {
                padding: 0px 10px;
            }

            &:nth-child(odd) {
                .booked-session-card {
                    margin-left: 15px;
                }

                .booked-session-date-time-information {
                    &::after {
                        clip-path: polygon(0 50%, 50% 75%, 50% 25%);
                        right: 100%;
                        margin-right: -12px;
                    }

                    &::before {
                        right: calc(100% + 8px);
                    }
                }

                .MuiTimelineContent-root {
                    padding: 0px 0px 0px 20px;
                }
            }

            &:nth-child(even) {
                .booked-session-content-wrapper {
                    margin-left: auto;
                }

                .booked-session-date-time-information {
                    right: 0px;

                    &::after {
                        clip-path: polygon(50% 25%, 50% 75%, 100% 50%);
                        left: 100%;
                        margin-left: -12px;
                    }

                    &::before {
                        top: 65%;
                        left: calc(100% + 8px);
                    }
                }

                .booked-session-card {
                    margin-right: 15px;
                }

                .MuiTimelineContent-root {
                    padding: 0px 20px 0px 0px;
                }
            }

            .booked-session-content-wrapper {
                position: relative;
                width: 315px;

                .booked-session-date-time-information {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    padding: 10px 15px;
                    background-color: $purple-dark;
                    box-shadow: 0px 3px 6px $black-grey-shadow;
                    border-radius: 4px;
                    width: 133px;
                    z-index: 1;
                    top: -57px;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 22px;
                        height: 23px;
                        background: $purple-dark;
                        border-radius: 50%;
                        z-index: 0;
                        top: 57%;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        width: 5px;
                        height: 23px;
                        background: white;
                        z-index: 1;
                        top: 57%;
                    }

                    .session-icon {
                        width: 13px;
                        height: 13px;
                        margin-right: 8px;

                        path {
                            fill: $white;
                        }
                    }

                    .session-start-date {
                        margin-bottom: 13px;
                    }

                    .session-start-date,
                    .session-start-time {
                        display: flex;
                        align-items: center;
                    }

                    .start-date,
                    .start-time {
                        font-size: $small;
                        line-height: 17px;
                        letter-spacing: 0.13px;
                        color: $white;
                    }
                }

                .booked-session-card {
                    border-radius: 4px;
                    box-shadow: 0px 1px 3px $grey-darker-button;
                    margin-top: 15px;
                }

                .employee-information-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px;
                    background-color: $primary-color-light;

                    .employee-type {
                        font-size: $small;
                        letter-spacing: 0.18px;
                        color: $primary-color-700;
                        margin-bottom: 12px;
                        text-align: left;
                    }

                    .employee-name {
                        font-size: $medium;
                        font-weight: 500;
                        letter-spacing: 0.21px;
                        color: $purple-dark;
                    }

                    .employee-profile-logo {
                        width: 38px;
                        height: 38px;
                    }
                }

                .coaching-opportunity-information-wrapper {
                    padding: 20px;
                    background-color: $white;

                    .coaching-opportunity-title {
                        font-size: $medium;
                        font-weight: 500;
                        letter-spacing: 0.21px;
                        color: $purple-dark;
                        text-align: left;
                    }
                }
            }
        }
    }

    .show-container {
        position: relative;
        display: flex;
        align-items: center;
        margin: auto;
        width: 120px;
        margin: 30px auto 0px;

        .show-text {
            font-size: $medium;
            color: $purple-dark;
            text-decoration: underline;
        }

        .show-icon {
            position: absolute;
            width: 37px;
            height: 37px;
            color: $purple-dark;
            left: calc(100% - 37px);
            transition: .3s all;
        }

        &.isShowMore {
            .show-icon {
                transform: rotate(180deg);
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}
