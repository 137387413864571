.your-account-manager-wrapper {
    .account-manager-loader {
        position: absolute;
        top: 24%;
        left: 54%;
    }

    .candidate-customer-support-wrapper {
        margin-top: 20px;
    }
}
