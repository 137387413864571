@import 'style-constants.scss';

.client-profile-journey-wrapper {
    margin: auto;
    margin-top: 40px;

    .step-component {
        width: $paper-element-sm;
        padding: 40px 96px;
        margin: auto;
        display: grid;
        grid-auto-flow: row;
        gap: 32px;

        .step-component-form {
            display: grid;
            grid-auto-flow: row;
            gap: 32px;
        }

        .form-control {
            display: grid;
            grid-auto-flow: row;
            gap: 10px;
        }

        .form-control-group {
            display: grid;
            grid-auto-flow: column;
            gap: 16px;
        }
    }

    .country-select-container {
        display: grid;
        grid-auto-flow: column;
        margin-top: 1px;
        align-items: center;
        gap: 5px;

        svg:hover {
            cursor: pointer;
        }
    }
}

#country-menu {
    .MuiPaper-rounded {
        margin-left: -45px;
        margin-top: 45px;
    }
}