@import 'style-constants.scss';

.add-coaching-opportunity-dialog { 
    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }
    
    .dialog-title-text {
        color: $grey-dark;
        font-size: 16px;
    }
    
    .dialog-actions {
        margin-bottom: 22px;
    }
    
    .MuiDialogContent-root.dialog-content {
        overflow-y: 'hidden'; 
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: scroll;
    }
    
    .MuiDialogContent-root.dialog-content::-webkit-scrollbar {
        width: 0px;
        display: none;
    }

    .MuiTypography-body1 {
        font-size: $medium-title;
        font-weight: 500;
    }

    .form-sub-title {
        margin-bottom: 20px
    }

    .upload-input {
        display: none;
    }

    .avatar-actions {
        display: flex;
        width: 110px;
        height: 110px;
        background-color: #CDCDCD;
        border-radius: 50%;
        position: relative;

        .upload-image {
            display: block;
            margin: auto;

            svg {
                width: 17.5px;
                height: 17.5px;
                fill: $grey-shadow;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .coach-avatar {
            width: 100%;
            height: 100%;
        }

        .icons-container {
            width: 50px;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 40%;
            left: 25%;

            .icon-button {
                width: 18px;
                height: 18px;
                padding: 0;
            }
    
            .avatar-delete-button {
                background-color: $grey-dark;
                color: $white;
    
                .close-icon {
                    width: 15px;
                    height: 15px;
                    margin-bottom: 5px;
                }
            }
    
            .avatar-edit-button {
                background-color: $white;
                border: 2px $purple-dark solid;
                color: $purple-dark;
    
                .edit-sharp-icon {
                    width: 12px;
                    height: 12px;
                    margin-bottom: 6px;
                }
            }
        }
    }
}
