@import 'style-constants.scss';

.add-edit-project-dialog {
    
    .MuiDialog-paper {
        width: $page-content-width;
        padding: 0 16px; 
    }

    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-title-text {
        color: #7E7E7E;
        font-size: 16px;
    }
    
    .dialog-actions {
        margin-top: 22px;
        margin-bottom: 14px;
        padding-right: 0;
    }
}
