@import 'style-constants.scss';

.content-moderator-wrapper {
    .action-wrapper {
        color: $purple-dark;
        text-align: center;
        font-size: 16px;
        display: flex;
        justify-content: center;
        text-decoration: underline;
        margin-top: 10px;
    }

    .action-wrapper:hover {
        cursor: pointer;
    }
}
