@import 'style-constants.scss';

.add-edit-education-form {

    .MuiFormControl-marginNormal {
        margin: 0;
        margin-bottom: 3px;
    }

    .title-field {
        margin-top: 12px;
        margin-bottom: 35px;

        &.invalid {
            margin-bottom: 18px;
        }
    }

    .institution-field {
        margin-bottom: 0;

        &.invalid {
            margin-bottom: -17px;
        }
    }
    
    .switch-wrapper {
        display: flex;
        justify-content: flex-end;
        margin: 15px -4px 14px 0;

        .MuiFormControlLabel-label {
            font-size: 14px;
            letter-spacing: 0.24px;
            color: #00000099;
        }
    }

    .switch-label {
        margin: 0 10px;
        border: 1px solid #FFF;
        padding: 2px 5px;
    }

    .date-fields-block {
        display: flex;
        justify-content: space-between;
    }

    .start-date-field {
        margin-right: 20px;
    }

    .calendar-icon {
        cursor: pointer;
        color: $grey-dark;
    }

    .disabled-calendar-icon {
        color: $grey-disabled;
    }
}
