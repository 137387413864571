@import "style-constants.scss";

.client-your-account-manager-information-wrapper {
    padding: 24px 0;

    .client-your-account-manager-title {
        font-size: $medium;
        font-weight: 500;
        letter-spacing: 0.11px;
        color: $purple-dark;
        padding-bottom: 20px;
    }

    .client-your-account-manager-content {
        background-color: $white;
        border-radius: 24px;
        box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
        padding: 24px;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: flex-start;
        margin-top: 20px;

        .information-section {
            .title {
                font-size: $medium;
                font-weight: 500;
                letter-spacing: 0.21px;
                color: $purple-dark;
                padding-bottom: 20px;
            }

            .icon-text-wrapper {
                display: flex;
                align-items: center;
                padding: 16px;
                background-color: $app-background;
                border-radius: 8px;
                margin-top: 11px;

                .icon {
                    margin-right: 10px;
                }

                .text {
                    font-size: $medium;
                    letter-spacing: 0.28px;
                    color: $purple-dark;
                }
            }
        }
    }
}
