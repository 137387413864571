@import "style-constants.scss";

.upload-cv-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    margin-bottom: 20px;
    width: 100%;

    .MuiPaper-elevation1 {
        box-shadow: 0px 0px 10px #79797929;
    }

    .raw-cv-profile-content {
        padding: 28px 40px;
        background: white;
        min-height: 372px;
    }

    .step-number {
        width: 44px;
        height: 39px;
        color: $white;
        background-color: $primary-color;
        font-size: 20px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .text-info {
        h2 {
            color: $purple-dark;
            font-size: 18px;
        }

        p {
            color: $purple-dark;
            font-size: 16px;
            margin-top: 20px;
        }
    }

    .text-info li {
        color: $grey-lighter;
        font-size: 14px;
        line-height: 28px;
    }

    .text-info li::marker {
        color: $primary-color;
    }

    .cols {
        display: flex;
        margin: 0 -30px;

        .col-1of3 {
            width: 100%;
            padding: 0 30px;

            border-right: 2px solid $grey-light-medium;

            &:last-child {
                border-right: 0;
            }
        }
    }

    img {
        margin-bottom: 12px;
    }

    .MuiTypography-displayBlock {
        overflow-wrap: break-word;
    }
}
