.add-important-documents-dialog {
    .add-important-documents-field {
        width: 100%;
    }

    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-title-text {
        color: #7E7E7E;
        font-size: 16px;
    }
    
    .dialog-actions {
        margin-top: 16px;
        margin-bottom: 22px;
    }
}
