@import "style-constants.scss";

.candidate-application-wrapper {
    position: relative;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 4px;
    padding: 50px 45px 30px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;

    .candidate-application-status-label {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 33px;
        padding: 15px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;

        .candidate-application-status-text {
            text-align: center;
            font-size: $medium;
            line-height: 17px;
            font-weight: 400;
            letter-spacing: 0.11px;
        }

        &.pending-review,
        &.pending-admin-review,
        &.interview-stage,
        &.not-vetted {
            background-color: rgba($grey-dark, 0.1);
            color: $grey-dark;
        }

        &.hired,
        &.invited {
            background-color: rgba($secondary-color-300, 0.1);
            color: $secondary-color-300;
        }

        &.rejected {
            background-color: rgba($red-darker, 0.1);
            color: $red-darker;
        }
    }

    .candidate-application-position {
        font-size: $medium-title;
        line-height: 22px;
        font-weight: 500;
        letter-spacing: 0.61px;
        color: $purple-dark;
    }

    .candidate-application-date {
        width: fit-content;
        display: grid;
        grid-auto-flow: column;
        gap: 12px;
        margin-top: 15px;

        .candidate-application-date-text {
            font-size: $medium;
            line-height: 19px;
            font-weight: 400;
            letter-spacing: 0.15px;
            color: $medium-dark-shade-blue;
        }
    }

    .candidate-application-matching {
        position: relative;

        .candidate-application-matching-text-container {
            display: grid;
            grid-auto-flow: column;
            gap: 10px;
            align-items: center;
            margin-bottom: 15px;

            .candidate-application-matching-text {
                font-size: 16px;
                line-height: 21px;
                font-weight: 400;
                letter-spacing: 0.28px;
                color: $purple-dark;
            }

            .candidate-application-matching-icon {
                display: flex;
                align-items: center;
            }
        }

        .candidate-application-matching-number {
            font-size: 30px;
            line-height: 18px;
            font-weight: bold;
            letter-spacing: 1.01px;
            color: $purple-dark;
            display: flex;
            align-items: center;

            small {
                font-size: 70%;
                font-weight: 400;
            }
        }

        .candidate-application-matching-tooltip.hide {
            visibility: hidden;
        }

        .candidate-application-matching-tooltip.show {
            visibility: visible;
        }

        .candidate-application-matching-tooltip {
            display: flex;
            align-items: center;
            position: absolute;
            top: 22px;
            right: 10px;
            z-index: 999;
            flex-wrap: wrap;
            max-width: 700px;
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 3px $grey-shadow;
            border-radius: 4px;
            padding: 21px 28px;
            text-align: left;
            font-size: 14px;
            line-height: 21px;
            font-weight: 300;
            letter-spacing: 0.24px;
            color: $grey-dark-text;
            width: 283px;

            .candidate-application-matching-tooltip-title {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: 0.24px;
                color: $purple-dark;
                margin: 8px 0 19px 0;
            }
        }
    }
}
