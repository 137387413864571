@import 'style-constants.scss';

.performance-content-wrapper {
    display: flex;
    padding: 25px 85px 3px 30px;
    margin: 0 auto;
    width: 100%;

    .recharts-wrapper{
        
        .recharts-legend-item {
            .recharts-surface {
                border-radius: 35px;
            }
        }

        .legend-text {
            font-size: 12px;
            margin-left: 10px;
            margin-right: 22px;
            padding-bottom: 10px;
            color: rgba(67, 66, 93, 0.5);
        }
    }
}
