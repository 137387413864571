@import 'style-constants.scss';

.feedback-dialog-wrapper {
    .MuiFormControlLabel-label {
        color: $grey-dark-text;
        font-size: $small;
        letter-spacing: 0.18px;
    }

    .feedback-dialog-info-section {
        color: $purple-dark;
        font-size: $small;
        letter-spacing: 0.18px;

        &.text-red {
            color: $red-darker;
        }
    }

    .form-row,
    .rating-criteria-wrapper,
    .MuiFormControlLabel-label {
        margin: 15px 0;
    }

    .rating-criteria-row {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 2fr 2fr;

        .rating-criterion {
            padding: 8px 0;
            color: $grey-dark-text;
            font-size: $small;
            letter-spacing: 0.24px;
        }
    }

    .rating-details-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        margin-bottom: 15px;
        row-gap: 10px;
    }

    .rating-stars-wrapper {
        display: grid;
        grid-auto-flow: column;
        align-self: center;
    }

    .rating-description {
        grid-column: 1 / 3;
        color: $grey-dark-text;
        font-size: $small;
        letter-spacing: 0.24px;
    }

    .rating-type-with-reviewer-tooltip-wrapper {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: 10px;

        .rating-type {
            color: $purple-dark;
            font-size: $medium;
            font-weight: 500;
        }

        .info-icon {
            width: 12.45px;
            height: 12.45px;
        }
    }
}
