@import 'style-constants.scss';

.client-container {
    display: flex;
    flex: 1;
    height: calc(100vh - #{$header-height});

    .client-content-wrapper {
        width: 100%;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
    }

    .client-content-wrapper::-webkit-scrollbar {
        width: 0px;
        display: none;
    }

    .client-content {
        width: $page-content-width;
        margin: 0 auto;
        margin-top: 20px;
    }
}
