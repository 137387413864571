@import 'style-constants.scss';

.my-upcoming-interviews-list-wrapper {
    margin-bottom: 40px;
    
    .my-upcoming-interviews-list {
        padding-top: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 33px;
        column-gap: 20px;
    }

}
