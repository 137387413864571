@import 'style-constants.scss';

.journey-completion-screen-wrapper {
    height: 100vh;
    overflow: auto;
    padding-top: 160px;
    background-color: #c5c9f64d;
    position: relative;

    .company-logo {
        position: absolute;
        top: 70px;
        left: 110px;
        width: 48px;
        height: 48px;
        margin-bottom: 20px;
        background-color: #edecec;

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .journey-completion-screen-container {
        max-width: 867px;
        margin: 0 auto;

        .thank-you-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 40px;

            .thank-you-message-container {
                padding-top: 45px;
                width: 435px;
                height: 152px;
                background-image: url('../../assets/journey-completion-confetti.svg');
                background-repeat: no-repeat;
                background-size: cover;
                display: flex;
                align-items: center;
                justify-content: center;

                .thank-you-message {
                    color: $purple-dark;
                    letter-spacing: 0.61px;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: bold;
                }
            }
        }
    }

    .job-positions-wrapper {
        width: 100%;
        max-width: 100%;
    }

    .section-description-container {
        width: fit-content;
        margin: 0 auto;
    }

    .additional-sub-heading-text,
    .additional-heading-text {
        color: $purple-dark;
        font-weight: 500;
        letter-spacing: 0.14px;
    }

    .additional-heading-text {
        text-align: center;
        font-size: 18px;
        margin-top: 44px;
    }

    .additional-sub-heading-text {
        font-size: 16px;
        margin-top: 10px;
    }
}
