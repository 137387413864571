@import "style-constants.scss";

.profile-creation-layout-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;  
    max-height: -webkit-fill-available;
    overflow-y: auto;
    background-color: $dark-grey;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0px;
    }

    .profile-creation-layout-body-wrapper {
        width: 100%;
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .profile-creation-layout-content {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiCircularProgress-root {
        width: 29px !important;
        height: 29px !important;
    }

    @include profile-creation-mobile {
        background-color: $white;

        .profile-creation-layout-body-wrapper {
            align-items: flex-start;
        }

        .MuiCircularProgress-root {
            width: 16px !important;
            height: 16px !important;
        }
    }
}
