@import "style-constants.scss";

.interview-section-wrapper {
    .notification-section-body {
        .interview-action-buttons {
            display: grid;
            grid-auto-flow: column;
            width: fit-content;
            gap: 15px;
            margin: 0 auto;
            margin-top: 30.56px;
        }
        .body-divider,
        .interview-details {
            margin-top: 30.56px;
        }
        .interview-details {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .left {
                .job-position {
                    font-size: $medium-title;
                    letter-spacing: 0.61px;
                    color: $purple-dark;
                    font-weight: 500;
                    opacity: 1;
                }
                .time {
                    font-size: $medium;
                    letter-spacing: 0.15px;
                    color: $purple-dark;
                    opacity: 1;
                    margin-top: 10.75px;
                }
                .location {
                    font-size: $medium;
                    letter-spacing: 0.15px;
                    color: $medium-dark-shade-blue;
                    margin-top: 20.21px;
                }
            }
            .right {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                align-items: flex-end;
                .days {
                    font-size: $extra-large;
                    color: $purple-dark;
                    letter-spacing: 0.3px;
                    font-weight: 500;
                    opacity: 1;
                }
                .month {
                    font-size: $medium;
                    letter-spacing: 0.16px;
                    color: $purple-dark;
                    text-transform: capitalize;
                    font-weight: 500;
                    opacity: 1;
                }
            }
        }
    }

    @import "style-constants.scss";

    .upcoming-interview-container {
        .upcoming-interview-title {
            font-size: $medium-title;
            font-weight: bold;
            text-align: left;
            letter-spacing: 0.23px;
            color: $purple-dark;
            opacity: 1;

            &.spacing {
                letter-spacing: 0.61px;
            }
        }

        .upcoming-interview-header {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid $grey-light-amount;

            .upcoming-interview-description {
                padding-top: 11.77px;
                padding-bottom: 20.15px;
                font-size: $medium;
                text-align: left;
                color: $purple-dark;
                opacity: 0.8;
            }
        }

        .location {
            color: $medium-dark-shade-blue;
            font-size: $medium;
            letter-spacing: 0.15px;
        }

        .upcoming-interview-content {
            padding-top: 30.67px;
            display: flex;

            .time {
                color: $medium-dark-shade-blue;
                font-size: $medium;
                letter-spacing: 0.15px;
                margin-top: 10px;
                margin-bottom: 20px;
            }

            .upcoming-interview-info {
                flex-grow: 5;
            }

            .interview-date-info {
                display: flex;
                flex-direction: column;
                text-align: right;
                margin: auto;
                flex-grow: 2;

                .date {
                    font-weight: bold;
                    font-size: $extra-large;
                }

                .month {
                    font-weight: bold;
                    font-size: $medium;
                }
            }

            .candidate-name {
                color: $purple-dark;
                font-size: $medium;
                letter-spacing: 0.54px;
                align-self: center;
            }

            .profile-logo {
                width: 52px;
                height: 52px;
            }
        }
    }
}
