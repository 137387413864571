@import "style-constants.scss";

.experience-dialog {
    .MuiPaper-root{
        width: 28.875rem;

        .dialog-title-text {
            letter-spacing: 0.11px;
            font-weight: 500;
        }
        .MuiButton-label {
            font-weight: 500;
        }
    }
}
