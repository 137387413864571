@import 'style-constants.scss';

.job-search-saved-candidates-wrapper {
    .job-search-saved-candidates-container {
        display: grid;
        justify-content: flex-start;
        gap: 16px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-bottom: 16px;

        &.full-page {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .job-search-saved-candidates-card-content {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        justify-content: space-between;
        height: 100%;

        .job-search-saved-candidates-position-content {
            margin-top: auto;
        }
    }

    .job-search-saved-candidates-card {
        border-radius: 24px;
        background-color: $white;
        box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
        padding: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .job-search-saved-candidates-card-header {
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;

            .MuiAvatar-root {
                width: 72px;
                height: 72px;
            }
        }

        .job-search-saved-candidates-matching-score-wrapper {
            display: grid;
            grid-auto-flow: row;

            svg,
            p {
                justify-self: end;
            }
        }

        .job-search-saved-candidates-preferences-info {
            display: grid;
            grid-auto-flow: column;
            gap: 20px;
            justify-content: flex-start;
            align-items: center;

            .job-search-saved-candidates-preferences-info-details {
                display: grid;
                grid-auto-flow: column;
                justify-content: space-between;
                align-items: center;
            }
        }

        .job-search-saved-candidates-tech-stack-info {
            border-radius: 12px;
            background-color: $app-background;
            padding: 16px 8px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .job-search-saved-candidates-tech-stack-box {
                border-radius: 100px;
                border: 1.5px solid $dark-grey;
                padding: 2px 16px;
                background-color: $white;
                margin-right: 3px;
                margin-bottom: 3px;
                text-align: center;
                max-height: 30px;
            }
        }

        .invite-to-interview-button {
            width: 100%;
            align-self: flex-end;
        }

    }

    @media screen and (max-width: $small-desktop) {
        .job-search-saved-candidates-container {
            grid-template-columns: 1fr 1fr 1fr;

            &.full-page {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}