@import 'style-constants.scss';

.client-dashboard-wrapper {
    display: grid;
    grid-auto-flow: row;
    gap: 16px;
    z-index: 2;

    .section-title {
        margin-left: 21px;
        margin-bottom: 16px;
    }

    .dashboard-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;

        .dashboard-text-items {
            display: grid;
            grid-auto-flow: row;
        }
    }

    .discover-candidates-banner {
        padding: 27px 24px;
        border-radius: 24px;
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        background-color: $orange;
    }

    .dashboard-info-cards {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 16px;
    }

    .dashboard-additional-cards {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 2.05fr 1fr;
        gap: 16px;
    }

    .dashboard-recent-searches-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        overflow-x: scroll;
    }
}