@import 'style-constants.scss';

.calendly-settings-content {
    padding: 40px;

    .agency-calendly-settings-form-field {
        margin-bottom: 34px;
    }

    .agency-calendly-settings-form-block {
        display: flex;
        justify-content: space-between;

        .agency-calendly-settings-form-field {
            width: 48%;
        }
    }

    :last-child {
        margin-bottom: 0;
    }

    .agency-calendly-checkbox-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .checkbox-text {
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.00938em;
            color: $grey-normal-title;
        }
    }

    @media screen and (max-width: $small-desktop) {
        .calendly-settings-content-wrapper {
            flex-wrap: wrap;
        }

        .agency-calendly-settings-form-field {
            width: 100%;
            order: 2;
            padding-right: 0px;
        }
    }
}