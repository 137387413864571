@import 'style-constants.scss';

.info-card-wrapper {
    display: grid;
    grid-auto-flow: row;
    border-radius: 24px;
    box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
    padding: 24px;

    .info-card-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: flex-start;

        .icon-wrapper {
            width: 56px;
            height: 56px;
            display: grid;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
        }

        .percentage-wrapper {
            display: grid;
            grid-auto-flow: column;
            gap: 5px;
            align-items: center;
        }
    }

    .info-card-footer,
    .view-all-text {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
    }

    .view-all-text:hover {
        cursor: pointer;
    }

    &.empty-card {
        background-color: $white;

        .icon-wrapper {
            background-color: $pale-gray;

            * {
                stroke: $grey;
            }
        }

        .info-card-content {
            color: $dark-grey;
        }

        .info-card-footer {
            color: $grey;
        }
    }

    &.orange-card {
        background-color: $orange;

        .icon-wrapper {
            background-color: $pale-orange;

            * {
                stroke: $grey;
            }
        }

        .info-card-content {
            color: $dark-grey;
        }

        .info-card-footer {
            color: $grey;
        }

        .view-all-text {
            color: $dark-grey;
        }

        .percentage-wrapper {
            color: $dark-grey;
        }
    }

    &.purple-card {
        background-color: $purple;

        .icon-wrapper {
            background-color: $pale-purple;

            * {
                stroke: $grey;
            }
        }

        .info-card-content {
            color: $dark-grey;
        }

        .info-card-footer {
            color: $grey;
        }

        .view-all-text {
            color: $dark-grey;
        }

        .percentage-wrapper {
            color: $dark-grey;
        }
    }

    &.dark-card {
        background-color: $dark-grey;

        .icon-wrapper {
            background-color: $grey;

            * {
                stroke: $white;
            }
        }

        .info-card-content {
            color: $white;
        }

        .info-card-footer {
            color: $white;
        }

        .view-all-text {
            color: $deep-orange;

            * {
                stroke: $deep-orange;
            }
        }

        .percentage-wrapper {
            color: $deep-green;

            * {
                stroke: $deep-green;
            }
        }
    }

    .white-text {
        color: $white;
    }
}