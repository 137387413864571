@import 'style-constants.scss';

.form-options-wrapper {
    position: relative;

    &:not(:first-child) {
        margin-top: 15px;
    }

    &.open {
        .form-options-button {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }

        .form-options-menu {
            border: 1px solid $medium-light-shade-blue;
            border-top: none;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
        }
    }

    &:not(.open).error {
        .form-options-button {
            border-color: $red;
        }

        .form-options-button-text.placeholder-text {
            .MuiListItemText-primary {
                color: $red;
            }
        }
    }

    .form-options-button {
        width: 100%;
        border: 1px solid $medium-light-shade-blue;
        border-radius: 30px;
        padding: 12.5px 16px 12.5px 30px;

        &:hover {
            background-color: transparent;
        }

        .form-options-button-text {
            margin: 0;

            .MuiListItemText-primary {
                font-size: $medium;
                line-height: 19px;
                letter-spacing: 0.15px;
                opacity: 1;
                color: $medium-shade-blue;
                text-align: left;
            }

            &.placeholder-text {
                .MuiListItemText-primary {
                    font-size: $small;
                    font-weight: 300;
                    font-style: italic;
                    letter-spacing: 0.13px;
                }
            }
        }
    }

    .form-options-menu {
        position: absolute;
        background-color: $white;
        padding: 0px 25px;
        width: 100%;
        top: calc(100% - 1px);
        z-index: 1;

        &::before {
            position: absolute;
            content: '';
            left: 25px;
            width: calc(100% - 50px);
            height: 1px;
            background-color: $grey-light-background;
        }

        .MuiCollapse-wrapper {
            padding: 15px 0px;

            ::-webkit-scrollbar {
                width: 5px;
                border-radius: 4px;
            }

            ::-webkit-scrollbar-track {
                background: #E8E8E8;
                border-radius: 4px;
            }

            ::-webkit-scrollbar-thumb {
                background: #C8C8C8;
                border-radius: 4px;
            }
        }

        .form-options-menu-list {
            display: grid;
            gap: 20px;
            grid-template-rows: 1fr;
            max-height: 150px;
            overflow: auto;
        }

        .form-options-menu-item {
            display: flex;
            justify-content: flex-start;

            .MuiListItemText-root {
                text-align: left;
                margin: 0;
            }

            .MuiListItemText-primary {
                font-size: $medium;
                line-height: 19px;
                letter-spacing: 0.15px;
                color: $purple-darker-light;
            }

            &.selected {
                .MuiListItemText-primary {
                    color: $teal;
                }
            }
        }
    }

    @include profile-creation-mobile {
        .form-options-button {
            padding-top: 11px;
            padding-bottom: 11px;

            svg {
                width: 16px;
                height: 16px;
            }

            .form-options-button-text {
                &.placeholder-text {
                    .MuiListItemText-primary {
                        font-size: $smallest;
                        letter-spacing: 0.11px;
                    }
                }
            }
        }
    }
}
