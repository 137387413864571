@import 'style-constants.scss';

.job-card-wrapper {
    display: grid;
    grid-auto-flow: row;
    border-radius: 24px;
    background-color: $white;
    padding: 22px 32px;
    box-shadow: 0px 1px 3px rgba(121, 121, 121, 0.1607843137);
    gap: 16px;

    .card-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;

        .company-info {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            gap: 12px;

            img {
                width: 48px;
                height: 48px;
                border-radius: 8px;
            }
        }
    }

    .position-info {
        display: grid;
        grid-auto-flow: row;
        justify-content: flex-start;
        gap: 10px;
    }

    .additional-details-wrapper {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
    }

    .additional-details-content {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 48px;

        .detail {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            gap: 6px;
        }
    }

    .link {
        color: $deep-purple;
        font-size: $subheading-xs;
        font-weight: 600;
        line-height: 24px;
        text-decoration: none;

        &:hover {
            color: $purple;
            cursor: pointer;
        }
    }

    .application-status {
        padding: 10px 24px;
        border-radius: 8px;
        height: min-content;

        &.not-vetted,
        &.pending-review,
        &.pending-admin-review,
        &.interview-stage {
            color: $deep-orange;
            border: 1px solid $deep-orange;
            background-color: $pale-orange;
        }

        &.hired,
        &.invited {
            color: $green-darker;
            border: 1px solid $green-darker;
            background-color: $pale-green;
        }

        &.rejected {
            color: $red;
            border: 1px solid $red;
            background-color: $pale-red;
        }
    }

    .card-title {
        font-weight: 800;
        font-size: $medium-title;
        letter-spacing: 0.61px;
        color: $purple-dark;
    }

    .card-sub-title {
        font-size: $medium;
        letter-spacing: 0.28px;
        color: $purple-dark;
    }

    .card-content {
        padding: 18px 40px 50px 40px;
        display: grid;
        grid-auto-flow: row;
        gap: 10px;

        .row {
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;

            &.grid-wrapper {
                justify-content: flex-start;
                gap: 10px;
            }
        }
    }

    .date-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 8px;
        align-items: center;

        .date-value {
            font-size: $medium;
            letter-spacing: 0.15px;
            color: $purple-dark;
        }
    }

    .job-info-toggle {
        display: grid;
        grid-auto-flow: column;
        gap: 8px;
        min-width: 200px;
        align-items: center;

        p {
            text-decoration: underline;
            font-size: $medium;
            letter-spacing: 0.28px;
            color: $purple-dark;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .section-divider {
        margin: 20px 0px;
        background-color: $grey-light-amount;
    }

    .feedback-wrapper {
        .feedback-header {
            width: fit-content;
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 10px;
            margin-bottom: 8px;

            h4 {
                font-size: $medium;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0.28px;
                color: $purple-dark;
            }
        }

        .feedback-body {
            font-size: $medium;
            line-height: 21px;
            letter-spacing: 0.28px;
            color: $purple-dark;
            margin-left: 23.1px;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }

    .full-job-details-body {
        .job-section {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 15px;
            }

            .skills-container {
                display: grid;
                grid-auto-flow: column;
                justify-content: flex-start;
                gap: 8px;

                .skill-container {
                    align-items: center;
                    padding: 8px;
                    border: 1px solid $pale-gray;
                    border-radius: 8px;
                    margin-bottom: 10px;
                    background-color: $white;
                    cursor: pointer;

                    .skill {
                        color: $grey;
                        width: calc(100% - 23px);
                        display: inline;
                        font-size: $subheading-m;
                        font-weight: 400;
                        line-height: 26px;
                    }
                }
            }
        }
    }
}