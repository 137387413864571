@import 'style-constants.scss';

.upload-linkedin-dialog {
    position: relative;

    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:hover {
        background: $purple-light;
    }

    .MuiDialog-paper {
        padding: 30px 40px;
        width: 462px;
    }

    .dialog-title {
        padding: 0;
        padding-right: 30px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-text {
            color: $grey-dark;
            font-size: 16px;
            font-weight: 500;
        }

        .dialog-close-btn {
            position: absolute;
            top: 16px;
            right: 24px;
        }
    }

    .dialog-content {
        padding: 8px 0px;
        overflow: unset;

        display: grid;
        gap: 15px;

        .dialog-text {
            color: $grey-dark-text;
            font-size: 16px;
            opacity: 1;
            
        }

        .upload-file {
            .import-pdf-container {
                width: 382px;
                margin: 0;

                @media screen and (max-width: $mobile) {
                    width: 100%;
                }
            }        
        }
    }

    @media (max-width: $mobile) {
        .MuiDialog-container {
            height: unset;
        }

        .MuiDialog-paper {
            padding: 30px 40px;
            width: 100%;
            margin: 0;
            height: 100vh;
        }

        .MuiDialog-paperWidthSm {
            max-width: unset;
        }

        .MuiDialogContent-root {
            flex: unset;
        }

        .dialog-actions-container {
            display: flex;
            justify-content: center;
        }

        .dialog-title {
            .dialog-title-text {
                color: $purple-dark;
                font-size: 18px;
            }
        }
    }

    .dialog-actions-container{
        padding: 0;
        margin-top: 12px;
    }

    .MuiSelect-icon {
        right: 16px;
        color: $grey-dark;
    }    
}
