@import "style-constants.scss";

.matching-details-container {
    margin-top: 63.58px;
    display: flex;

    .applied-candidate-progress {
        position: relative;
        margin-bottom: 12px;
        width: 100%;
        height: 33px;
        background-color: $grey-button-hover;
        border-radius: 4px;
        
        &::before {
            position: absolute;
            content: attr(title);
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            letter-spacing: 0.24px;
            color: $purple-dark;
            z-index: 2;
            padding: 6px;
        }

        & > div {
            background-color: $grey-progress;
            
        }
    }

    .matching-score-wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;

        .matching-score {
            display: flex;
            justify-content: center;
            align-items: center;

            .applied-candidate-matching-icon {
                display: inline-block;
                width: 18px;
                height: 18px;
            }

            .overall-score {
                margin-right: 10px;

                small {
                    font-weight: 400;
                    font-size: 70%;
                }
            }
        }
    }

    .applied-candidate-matching-tooltip-wrapper {
        position: relative;
        width: 18px;
        height: 18px;
    }


    .applied-candidate-matching-tooltip.hide {
        visibility: hidden;
    }

    .applied-candidate-matching-tooltip.show {
        visibility: visible;
    }

    .applied-candidate-matching-tooltip {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: calc(100% + 10px);
        right: 0px;
        z-index: 999;
        flex-wrap: wrap;
        max-width: 700px;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px $grey-shadow;
        border-radius: 4px;
        padding: 21px 28px;
        text-align: left;
        font-size: 14px;
        line-height: 21px;
        font-weight: 300;
        letter-spacing: 0.24px;
        color: $grey-dark-text;
        width: 283px;

        .applied-candidate-matching-tooltip-title {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            letter-spacing: 0.24px;
            color: $purple-dark;
            margin: 8px 0 19px 0;
        }
    }

    .section-title {
        display: flex;
        align-items: center;
        margin-bottom: -10px;
    }
    .skills-score-details {
        display: flex;
        flex-direction: column;
        margin-left: 10.83px;
        width: 100%;
        h4 {
            font-size: $medium;
            letter-spacing: 0.28px;
            color: $purple-dark;
            font-weight: 500;
        }

        .flex-row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;
        }

        .section-description {
            letter-spacing: 0px;
            font-size: $medium;
            color: $purple-dark;
            opacity: 0.8;
        }

        .overall-score {
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 1.01px;
            color: $purple-dark;
            display: flex;
            align-items: center;
        }
    }
    .matched-skills {
        margin-top: 37.14px;
        display: flex;
        flex-direction: column;

        .matched-skill {
            position: relative;
            width: 100%;
            border-radius: 4px;
            background-color: rgba($color: #e9e9e9, $alpha: 0.35);

            .skill-title {
                padding: 7px 0px 7px 23px;
                letter-spacing: 0.28px;
                color: $purple-dark;
                font-size: $medium;
                opacity: 1;
            }

            .skill-progress {
                position: absolute;
                border-radius: 4px;
                background-color: #e9e9e9;
                top: 0;
                bottom: 0;
                z-index: -90;
            }
        }

        .matched-skill:not(:first-child) {
            margin-top: 20.18px;
        }
    }
    .section-divider {
        width: 100%;
    }
}
