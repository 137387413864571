@import "style-constants.scss";

.flag-container {
    display: flex;
    align-items: flex-start;

    .flag {
        padding: 2px 9px;
        border-radius: 4px;

        &.none {
            background-color: rgba($grey-lighter, 0.1);
            color: $grey-lighter;
        }

        &.missing-timesheet,
        &.missing-information-from-acc-manager {
            background-color: rgba($red-darker, 0.1);
            color: $red-darker;
        }

        &.approved-for-sending {
            background-color: rgba($primary-color, 0.1);
            color: $primary-color;
        }

        &.sent-to-client {
            background-color: rgba($secondary-color, 0.1);
            color: $secondary-color;
        }

        &.awaiting-approval {
            background-color: rgba($orange, 0.1);
            color: $orange;
        }
    }

    .flag-name {
        margin-left: 10px;
    }

    .cut-text {
        .MuiOutlinedInput-input {
            max-width: 70px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .flag-edit-button {
        margin-left: 5px;
        width: 24px;
        height: 24px;

        .edit-sharp-icon {
            width: 16px;
        }
    }
}

.flag-tooltip-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.change-status-dialog {
    .MuiDialog-paper {
        width: 517px;
        padding: 0 16px; 
    }

    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-title-text {
        color: #7E7E7E;
        font-size: 16px;
    }
    
    .dialog-actions {
        margin-bottom: 22px;
        padding-right: 0;
    }

    .MuiDialogContent-root.dialog-content {
        overflow-y: 'hidden'; 
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: scroll;
    }

    .MuiDialogContent-root.dialog-content::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
}
