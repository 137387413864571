@import "style-constants.scss";

.job-notifications {
    cursor: pointer;
    width: 100%;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px $grey-darker-button;
    border-radius: 18px;
    padding: 31px;
    height: auto;
    max-width: 298px;

    .nothing-to-show-wrapper {
        margin-top: 25px;
    }

    .job-notification-title {
        display: flex;
        align-items: center;

        .job-notification-title-icon {
            margin-right: 10px;
        }

        .job-notification-title-text {
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.11px;
            color: $purple-dark;
        }
    }

    .candidate-application-link {
        text-decoration: none;
    }

    .candidate-application-link:last-child .job-notification {
        border-bottom: none;
    }

    .job-notification {
        width: 245px;
        height: auto;
        padding: 20px 0;
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #f3f3f3;

        .notification-type-indication-box {
            display: inline-block;
            min-width: 16px;
            height: 16px;
            box-shadow: 1px 1px 2px $grey-darker-button;
            border-radius: 2px;
            margin: 4px 10px 0px 0px;

            &.new-indicator {
                background-color: $white;
                border: 1px solid $purple-dark;
            }

            &.seen-indicator {
                background-color: $grey-light-box;
            }

            &.rejected-indicator {
                background-color: $grey-light-box;
            }

            &.approved-indicator {
                background-color: $grey-light-box;
            }
        }

        .job-notification-information {
            display: grid;
            gap: 10px;

            .job-notification-text {
                text-align: left;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.21px;
                color: $purple-dark;
            }

            .job-notification-date {
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                line-height: 28px;
                letter-spacing: 0.18px;
                color: $grey-dark;
            }
        }
    }
}
