@import 'style-constants.scss';

.search-results-dropdown {
    display: grid;
    justify-content: start;
    grid-template-columns: 1fr;

    .search-bar-loader {
        justify-self: center;
        margin-bottom: 10px;
    }

    .MuiIconButton-label {
        justify-content: start;
    }
}
