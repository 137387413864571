@import "style-constants.scss";

.onboarding-wrapper {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100vh;
    display: flex;
    overflow: auto;
    justify-content: center;
    flex-direction: column;

    @media screen and (min-width: $desktop) {
        background: url("/assets/clientOnboarding/onboarding-background-first-page.svg") no-repeat center center fixed;
        background-size: cover;

        &.step-middle {
            background: url("/assets/clientOnboarding/onboarding-background-default.svg") no-repeat center center fixed;
            background-size: cover;
        }

        &.step-last {
            background: url("/assets/clientOnboarding/onboarding-background-last-page.svg") no-repeat center center fixed;
            background-size: cover;
        }
    }

    .company-logo {
        width: 82px;
        height: auto;
        align-self: center;
        padding-bottom: 106px;
    }

    .onboarding-banner-content {
        position: relative;
        width: 856px;
        min-height: 212px;
        align-self: center;
        padding: 57px 274px 29px 40px;
        background-color: $white;
        border-radius: 4px;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #79797929;

        &.create-employee-profile-banner-content {
            .banner-content-image {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 30px;
            }
        }

        &.billing-supplier-banner-content {
            padding: 60px 274px 60px 40px;
            .banner-content-image {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 30px;
            }
        }

        .banner-header {
            color: $purple-dark;
            font-size: $large-title;
            font-weight: 500;
            letter-spacing: 0.34px;
            margin-bottom: 20px;
        }

        .banner-text {
            color: $purple-dark;
            font-size: $medium;
            margin-top: 15px;
            font-weight: normal;
            word-wrap: break-word;
            letter-spacing: 0.11px;
        }

        .banner-bold-section {
            margin-top: 32.8px;
        }

        .banner-image {
            position: absolute;
            top: -25px;
            right: 20px;
        }

        .evrotrust-android-img {
            width: 148px;
            height: 67px;
        }

        .evrotrust-ios-img {
            width: 142px;
            height: 47px;
            margin: 0 0 10px 10px;
        }
    }

    .buttons-container {
        padding-top: 40px;
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .link-button {
            background-color: transparent;
            border: none;
            box-shadow: none;
        }
    }

    @media screen and (max-width: 1200px) {
        .onboarding-banner-content {
            .banner-header {
                font-size: 28px;
                margin-bottom: 20px;
            }

            .banner-text {
                font-size: 18px;
            }

            .banner-bold-section {
                margin-top: 20px;
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .onboarding-banner-content {
            margin-top: 0;

            .banner-header {
                font-size: 24px;
                margin-bottom: 15px;
            }

            .banner-text {
                font-size: 16px;
            }

            .banner-bold-section {
                margin-top: 15px;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        .company-logo {
            display: none;
        }
    }

    @media screen and (max-width: 900px) {
        .onboarding-banner-content {
            margin-top: -40px;
            width: 80%;

            .banner-text {
                margin: 0;
            }
        }

        .company-logo {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {
        padding: 0;
        background: #edeefc;

        svg {
            display: none;
        }

        .onboarding-banner-content {
            width: 100%;
            position: static;
            padding: 50px 50px 0;
            transform: translateY(0%);
            margin: 0;

            .banner-text {
                font-size: 14px;
            }
        }

        .buttons-container {
            padding-top: 40px;
            padding-bottom: 50px;
        }
    }

    @media screen and (max-width: 550px) {
        .onboarding-banner-content {
            width: 100%;
            position: static;
            padding: 30px;
            transform: translateY(0%);
            margin: 0;

            .banner-text {
                font-size: 14px;
            }
        }
    }
}