@import "style-constants.scss";

.nav-main-list {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 75%;
    margin: 0 auto;

    &::-webkit-scrollbar {
        display: none;
        width: 0px;
    }

    .notification-badge {
        position: absolute;
        top: 20px;
        left: 21px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $red;
        display: flex;
        align-items: center;
        justify-content: center;

        .badge-text {
            font-size: 10px;
            line-height: 1;
            color: $white;
        }
    }

    @media screen and (max-width: $mobile) {
        .notification-badge {
            position: absolute;
            top: 9px;
            right: 75%;
            left: unset;
        }
    }


    @media screen and (max-width: $small-desktop) {
        max-height: 20%;
    }

    @media screen and (max-width: $medium-desktop) {
        max-height: 30%;
    }

    @media screen and (min-width: calc($medium-desktop - 100px)) {
        max-height: 40%;
    }

    @media screen and (min-width: calc($large-desktop - 100px)) {
        max-height: 60%;
    }
}