@import 'style-constants.scss';

.confirm-move-to-agency-dialog {

  .MuiDialog-paper {
    width: $page-content-width;
  }

  .confirm-dialog-header {
    padding-bottom: 0;
    padding-right: 8px;
    display: flex;
    justify-content: flex-end;
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;
    margin-top: -15px;
  }

  .dialog-text {
    color: $grey-dark;
    font-size: 16px;
    letter-spacing: 0.11px;
  }

  .dialog-title {
    font-weight: 500;
    word-break: break-all;
    text-align: center;
  }

  .dialog-subtitle {
    font-weight: 300;
    margin-top: 9px;
  }

  .dialog-actions {
    margin-top: 20px;
    margin-bottom: 43px;
    display: flex;
  }
}
