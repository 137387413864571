@import 'style-constants.scss';

.primary-button {
    color: $white;
    background-color: $indigo;
    border-radius: 18px;
    padding: 8px 24px;
    letter-spacing: 1.07px;
    line-height: 15px;
    font-weight: 400;
    text-transform: uppercase;
    min-width: 170px;

    &:hover {
        background-color: $white;
        color: $indigo;
    }

    &.dark {
        background-color: $dark-grey;
        color: $white;
        text-transform: none;
        font-size: $subheading-m;
        border-radius: 16px;
        min-height: 56px;
        min-width: 182px;
    }

    &.dark:hover {
        background-color: $deep-purple;
        border: none;
    }

    &.purple {
        background-color: $deep-purple;
        color: $white;
        text-transform: none;
        font-size: $subheading-m;
        border-radius: 16px;
        min-height: 56px;
        min-width: 192px;
    }

    &.purple:hover {
        background-color: $purple;
        border: none;
    }

    &.light {
        background-color: $grey-light-background;
        color: $indigo;
        border: 1px solid $indigo;
    }

    &.white-border {
        border: 1px solid $white;
    }

    &.full-width {
        width: 100%;
    }

    .MuiButton-label {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        gap: 10px;
    }

    @media screen and (max-width: $small-desktop) {
        font-size: $tiny;
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        font-size: $smallest;
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        font-size: $smallest;
    }
}