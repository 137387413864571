@import "style-constants.scss";

.job-box {
    position: relative;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 10px $grey-darker-button;
    border-radius: 18px;

    .job-details-link {
        text-decoration: none;
    }

    .job-box-content {
        &.unset {
            justify-content: unset;
        }

        &.max-height {
            height: 269px;
        }
    }

    .job-box-body {
        display: flex;
        padding: 50px 40px 20px;

        .basic-job-info-left {
            width: 100%;
            display: grid;
            gap: 10px;

            h2 {
                text-align: left;
                font-size: $medium-title;
                font-weight: 500;
                letter-spacing: 0.23px;
                color: $purple-dark;

                a {
                    color: inherit;
                }
            }

            .row-items {
                display: grid;
                justify-content: space-between;
                align-items: center;
                grid-auto-flow: column;

                &.justify-start {
                    gap: 40px;
                    justify-content: flex-start;
                }
            }

            .row-item {
                display: grid;
                gap: 8px;
                justify-content: flex-start;
                align-items: center;
                grid-auto-flow: column;

                &.location {
                    align-items: flex-start;
                    padding-top: 10px;
                }

                font-size: $medium;
                line-height: 21px;
                letter-spacing: 0.28px;
                color: $purple-dark;

                svg path {
                    fill: $purple-dark;
                }
            }
        }

        .basic-job-info-right {
            margin-left: 20px;
            display: flex;

            .job-three-dots-action {
                color: $grey-dark;
                cursor: pointer;
            }
        }
    }

    .job-box-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 81px;
        background-color: $grey-background-light;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 0 40px;

        .total-profiles-count {
            display: grid;
            gap: 13px;
            grid-auto-flow: column;

            .total-profiles-count-number {
                text-align: left;
                font-size: 35px;
                font-weight: 900;
                line-height: 21px;
                letter-spacing: 0.61px;
                color: $purple-dark;
            }
        }

        .candidates-statuses-count-boxes {
            position: relative;
            display: grid;
            gap: 40px;
            grid-auto-flow: column;
            justify-content: space-evenly;
            align-items: center;
        }
    }

    .job-details {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 15px;
        padding: 20px 40px;

        .box {
            padding: 5px;
            min-width: 123px;
            height: 25px;
            text-align: center;
            border-radius: 4px;
            font-size: $small;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: capitalize;

            &.open-status {
                background-color: #ECFCF6;
                color: #22D696;
            }

            &.published-privacy {
                background-color: #EBEFFA;
                color: #0D37C9;
            }

            &.high-priority {
                background-color: #FAE9ED;
                color: $red-darker;
            }

            &.medium-priority {
                background-color: #FEF9E5;
                color: #FAC800;
            }

            &.low-priority,
            &.closed-status,
            &.unpublished-privacy {
                background-color: $background-label;
                color: $grey-dark;
            }
        }
    }
}