@import "style-constants.scss";

.invalid-field-message {
    font-size: $subheading-xs;
    color: $red;
}

.MuiPaper-elevation1 {
    box-shadow: 0px 1px 3px #00000033;
}

.disabled {
    color: $grey-lighter;
    background-image: linear-gradient($grey-disabled, $grey-light-amount);
    cursor: not-allowed;
}

.global-blur-text {
    filter: blur(6px);
    opacity: 0.8;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiPickersDay-daySelected:hover {
    background-color: $purple-dark;
    color: $white;
}

.MuiTypography-colorPrimary {
    color: $purple-dark
}

// centralise the KeyboardDatePickers (popup calendars)
.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    margin-left: 35.5px;
}

// Change rounding of all paper elements to match new styles
.MuiPaper-root.MuiPaper-rounded {
    border-radius: 24px;
}

.MuiPaper-root.MuiPaper-rounded.rounded-l {
    border-radius: 32px;
}

// Change rounding of all input elements to match new styles
.MuiInputBase-root,
.MuiOutlinedInput-root {
    border-radius: 16px;
}

// Change input focused color
.Mui-focused {
    color: $dark-grey !important;
    border-color: $pale-gray !important;
}

.MuiOutlinedInput-root,
.MuiOutlinedInput-notchedOutline {
    border-color: $pale-gray !important;
}

.MuiOutlinedInput-root {
    background-color: $white !important;
}

// Styles for checkboxes in popovers
.global-table-filter-checkbox {
    color: $white;

    &.Mui-checked {
        color: $white;
    }
}

.global-css-skill-box {
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 15px;
    border-radius: 100px;
    padding: 6px 16px;
    background-color: $pale-purple;
    cursor: pointer;
    min-width: max-content;

    &:hover {
        background-color: $purple;
    }

    .skill-name {
        color: $dark-grey;
        display: inline;
        font-size: $subheading-m;
    }

    .delete-skill-icon {
        border: none;
        background-color: inherit;
        font-size: 16px;
        color: $grey-dark-text;
        padding-bottom: 2px;

        &:hover {
            cursor: pointer;
        }
    }
}

// hover effect of table row is here
// because table is not centralized component
.MuiTableRow-hover {
    cursor: pointer;
}

.MuiSwitch-track,
.Mui-checked,
.MuiSwitch-colorSecondary {
    background-color: transparent !important;
}

.MuiSwitch-switchBase.Mui-checked {
    transform: none;
}

.MuiIconButton-root {
    padding: 0;
}

// this styles are positioned here
// because table is not centralized component
.center-info-text {
    color: rgba(0, 0, 0, 0.54);
    text-align: center;
    padding: 10px;
}

/* Scrollbar width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Handle scrollbar background*/
::-webkit-scrollbar-thumb {
    background: $grey-dark;
}

/* Handle on hover scrollbar background */
::-webkit-scrollbar-thumb:hover {
    background: $grey-darker;
}

// this is needed so that the contents of the td can take up the space in the entire td
// If you give your TD a height of 1px, then the child div would have a heighted parent to calculate it's
// % from. Because your contents would be larger then 1px, the td would automatically grow, as
// would the div.
.table-cell-with-height {
    height: 1px;
}

.outlined-export-btn,
.outlined-export-btn:hover,
.clear-btn,
.clear-btn:hover {
    border: 1px solid $primary-color-900;
    background-color: $white;
    margin-left: 5px;

    .MuiButton-label {
        color: $primary-color-900;
    }
}

.MuiButton-label {
    text-transform: none;
}

// Override the color of the progress bar globally
.MuiCircularProgress-svg {
    color: $purple;
}

.MuiCircularProgress-root.white {
    .MuiCircularProgress-svg {
        color: $white;
    }
}

.MuiCircularProgress-root.teal {
    .MuiCircularProgress-svg {
        color: $teal;
    }
}

.MuiCircularProgress-root.deep-purple {
    .MuiCircularProgress-svg {
        color: $deep-purple;
    }
}

.MuiCircularProgress-root.deep-orange {
    .MuiCircularProgress-svg {
        color: $deep-orange;
    }
}

.page-loader {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    z-index: 999;
}

.centered {
    margin: auto;
}

.journey-buttons-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    &>button:not(:last-child) {
        margin-right: 19px;
    }

    @media screen and (max-width: $mobile) {
        margin: 0;
        margin: 30px 0 0 0;
        padding-bottom: 80px;

        .MuiButton-label {
            font-size: 14px;
        }
    }
}

.display-none {
    display: none;
}

.custom-tooltip {
    border-radius: 16px;
    padding: 10px 15px;
    background: $grey;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: $subheading-xxs;
    font-weight: 500;
    line-height: 14px;
    font-family: "Urbanist", sans-serif;

    &.less-padding {
        padding: 4px 10px;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        font-size: $smallest;
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        font-size: $small;
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        font-size: $small;
    }
}

.add-new-language-option:hover,
.add-new-tag-option:hover {
    cursor: pointer;
}

.global-tags-list {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    justify-content: flex-start;
    padding-bottom: 20px;

    .tag-name {
        padding: 8px 17px;
        border: 1px solid $actions-border;
        border-radius: 4px;
        opacity: 1;
        color: $grey-dark-text;
        font-size: $medium;
    }
}

.global-sessions-list {
    display: grid;
    grid-auto-flow: row;
    gap: 18px;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fill, $coaching-session-card-width);

    &.thin {
        grid-template-columns: repeat(auto-fill, $coaching-session-card-width-thin);
    }
}

.global-text-red {
    color: $red-darker;
}

.global-full-viewport-height {
    min-height: 100vh;
}

.global-dots-icon-wrapper {
    display: flex;

    svg:hover {
        border-radius: 20px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.04);
    }
}

.global-information-icon {
    color: $purple-dark;

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        width: $small;
        height: $small;
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        width: $medium;
        height: $medium;
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        width: $medium-title;
        height: $medium-title;
    }
}

.grid-container {
    display: grid;

    &.rows {
        grid-auto-flow: row;
    }

    &.cols {
        grid-auto-flow: column;
    }

    &.gap-8 {
        gap: 8px;
    }
}

.global-pointer-on-hover:hover {
    cursor: pointer;
}

.text-divider {
    display: flex;
    align-items: center;
    color: $grey-dark;
    margin: 39px 0;
    width: 100%;

    .divider-content {
        padding: 0 10px;
        letter-spacing: 0.15px;
        font-size: $medium;
    }

    &::before,
    &::after {
        content: '';
        height: 1px;
        background-color: $grey-dark;
        flex-grow: 1;
    }

    &.mobile {
        margin: 20px 0;
    }
}

.google-btn {
    border: 1px solid $actions-border;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 4px;
    padding: 15px 86px;
    text-transform: uppercase;
    font: $medium Urbanist;
    letter-spacing: 0.15px;
    display: grid;
    grid-auto-flow: column;
    gap: 15px;
    justify-content: center;
    font-weight: 500;
    width: 100%;

    p.google-btn-text {
        color: $grey-dark;
    }

    &:hover {
        cursor: pointer;
        background-color: $actions-border;
        border: 1px solid $primary-color-700;
        opacity: 0.8;

        p.google-btn-text {
            color: $primary-color-700;
        }
    }

    &.mobile {
        padding: 15px 34px;
    }

    p.google-btn-text,
    svg {
        margin-top: 0;
        display: block;
        margin: auto;
    }


}

// responsive classes
.content-shell {
    box-sizing: border-box;
    margin: auto;
    margin-top: 59px;
    margin-bottom: 59px;

    &.with-submenu {
        padding-left: $submenu-width;

        @media screen and (max-width: $mobile) {
            padding-left: 0;
        }
    }

    .submenu-scroll-container {
        margin: auto;
        margin-top: 30px;
        width: 100%;
    }

    // mobile
    @media screen and (max-width: $mobile) {
        width: 100%;
        padding-left: 0;
        margin-top: 0;
    }

    // small desktops
    @media screen and (min-width: calc($mobile + 1px)) and (max-width: $small-desktop) {
        width: $content-shell-width-without-submenu-small-desktop;

        &.with-submenu {
            width: calc($submenu-width + $content-shell-width-with-submenu-small-desktop + 10px);
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        width: $content-shell-width-without-submenu-medium-desktop;

        &.with-submenu {
            width: calc($submenu-width + $content-shell-width-with-submenu-medium-desktop + 10px);
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        width: $content-shell-width-without-submenu-large-desktop;

        &.with-submenu {
            width: calc($submenu-width + $content-shell-width-with-submenu-large-desktop + 10px);
        }
    }
}

.codery-team-background {
    position: absolute;
    bottom: 0;
    left: 195px;
    z-index: -1;

    &.z-index-1 {
        z-index: 1;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.submenu-scroll-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.submenu-scroll-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.grid-form-double-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 34px;
    column-gap: 20px;
}

// codery styles

.heading-l {
    font-size: $heading-l;
    font-weight: 700;
    line-height: 58px;
}

.heading-m {
    font-size: $heading-m;
    font-weight: 700;
    line-height: 42px;
}

.heading-s {
    font-size: $heading-s;
    font-weight: 700;
    line-height: 34px;
}

.heading-xs {
    font-size: $heading-xs;
    font-weight: 700;
    line-height: 30px;
}

.subheading-xxl {
    font-size: $subheading-xxl;
    font-weight: 500;
    line-height: 28px;
}

.subheading-xl {
    font-size: $subheading-xl;
    font-weight: 400;
    line-height: 28px;
}

.subheading-l {
    font-size: $subheading-l;
    font-weight: 500;
    line-height: 26px;
}

.subheading-m {
    font-size: $subheading-m;
    font-weight: 400;
    line-height: 26px;
}

.subheading-s {
    font-size: $subheading-s;
    font-weight: 500;
    line-height: 24px;
}

.subheading-xs {
    font-size: $subheading-xs;
    font-weight: 400;
    line-height: 24px;
}

.subheading-xs-thicker {
    font-size: $subheading-xs;
    font-weight: 600;
    line-height: 24px;
}

.subheading-xxs {
    font-size: $subheading-xxs;
    font-weight: 400;
    line-height: 14px;
}

.subheading-xxxs {
    font-size: $subheading-xxxs;
    font-weight: 400;
    line-height: 12px;
}

.color-white {
    color: $white;
}

.color-deep-purple {
    color: $deep-purple;
}

.color-deep-orange {
    color: $deep-orange;
}

.color-dark-grey {
    color: $dark-grey;
}

.color-grey {
    color: $grey;
}

.color-pale-gray {
    color: $pale-gray;
}

.color-deep-green {
    color: $deep-green;
}

.text-centered {
    text-align: center;
}

.MuiInputBase-input {
    color: $dark-grey;
}

.custom-divider {
    color: $pale-gray;
    border: 0.5px solid $pale-gray;
    margin: 10px 0;
    height: 1px;
}