@import 'style-constants.scss';

.coaching-wrapper {
    .paper-wrapper {
        min-height: 208px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            text-align: center;
            font: $medium Urbanist;
            letter-spacing: 0.11px;
            color: $purple-dark;
            opacity: 1;
            margin-bottom: 18px;
            font-weight: 500;
        }

        h4 {
            text-align: center;
            font: $medium Urbanist;
            letter-spacing: 0.11px;
            color: $purple-dark;
            opacity: 0.79;
        }

        span {
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }
        }

    }

    .loader-wrapper {
        min-height: 500px;
        position: relative;
    }
    
    .centered {
        display: flex;
        position: absolute;
        left: 48%;
        top: 40%;
    }
}
