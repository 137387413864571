@import 'style-constants.scss';

.database-skills-wrapper {
    .search-term-field {
        border: 1px solid $pale-gray;
        border-radius: 16px;
        background-color: $white;
        width: 400px;
        height: 56px;
    }

    .search-icon {
        margin-left: 10px;
    }
}
