@import "style-constants.scss";

.interview-section {
    margin-top: 30.36px;
    padding: 23.18px 41.91px 30.34px 40.07px;

    .header {
        .title {
            font-size: $medium-title;
            letter-spacing: 0.23px;
            color: $purple-dark;
            font-weight: 500;
            opacity: 1;
        }

        .text {
            font-size: $medium;
            letter-spacing: 0px;
            color: $purple-dark;
            opacity: 0.8;
        }
    }
}
