@import 'style-constants.scss';

.profile-matching-job-card-wrapper {
    position: relative;
    background: $white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 10px $grey-darker-button;
    border-radius: 4px;
    margin: 0 auto 40px;
    width: calc(100% - 4px);

    .job-main-info {
        display: flex;
        padding: 50px 40px 20px;

        .job-main-info-left {
            width: 100%;
            display: grid;
            gap: 10px;

            h2 {
                text-align: left;
                font-size: $medium-title;
                font-weight: 500;
                letter-spacing: 0.23px;
                color: $purple-dark;

                .job-details-link {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }


        .job-header-row {
            display: grid;
            justify-content: space-between;
            align-items: center;
            grid-auto-flow: column;
            gap: 40px;
            justify-content: flex-start;
        }

        .job-header-icon-info-item {
            display: grid;
            gap: 8px;
            justify-content: flex-start;
            align-items: center;
            grid-auto-flow: column;

            font-size: $medium;
            line-height: 21px;
            letter-spacing: 0.28px;
            color: $purple-dark;
        }

        .job-main-info-right {
            margin-left: 20px;
            display: flex;

            .job-three-dots-action {
                cursor: pointer;
                color: $grey-dark;
            }
        }
    }

    .job-profiles {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 81px;
        background-color: $grey-background-light;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 0 40px;

        .total-profiles-count {
            display: grid;
            gap: 13px;
            grid-auto-flow: column;

            .total-profiles-count-number {
                text-align: left;
                font-size: 35px;
                font-weight: 900;
                line-height: 21px;
                letter-spacing: 0.61px;
                color: $purple-dark;
            }
        }

        .candidates-statuses-count-boxes {
            position: relative;
            display: grid;
            gap: 40px;
            grid-auto-flow: column;
            justify-content: space-evenly;
            align-items: center;
        }
    }

    .job-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 40px;

        .job-labels {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            gap: 15px;
        }

        .box {
            padding: 5px;
            text-align: center;
            border-radius: 4px;
            font-size: $small;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: capitalize;

            &.open-status {
                background-color: #ECFCF6;
                color: #22D696;
            }

            &.published-privacy {
                background-color: #EBEFFA;
                color: #0D37C9;
            }

            &.high-priority {
                background-color: #FAE9ED;
                color: $red-darker;
            }

            &.medium-priority {
                background-color: #FEF9E5;
                color: #FAC800;
            }

            &.low-priority,
            &.closed-status,
            &.unpublished-privacy {
                background-color: $background-label;
                color: $grey-dark;
            }
        }

        .job-matching-score {
            display: flex;
            align-items: center;
            font-size: 30px;
            font-weight: bold;
            line-height: 18px;
            letter-spacing: 1.01px;
            color: $purple-dark;

            small {
                font-size: 70%;
                font-weight: 400;
            }
        }
    }
}
