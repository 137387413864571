@import 'style-constants';

.billing-wrapper {
    .billing-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
    }

    .billing-content {
        margin-top: 33px;
    }

    .billing-table-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;

        .search-term-field {
            border: 1px solid $pale-gray;
            border-radius: 16px;
            background-color: $white;
            min-width: 400px;
            min-height: 56px;
        }

        .search-icon {
            margin-left: 10px;
        }
    }

    .billing-table-actions {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
    }

    .billing-table-content {
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 24px;
        width: 100%;
        box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
        border-radius: 24px;
        background-color: $white;
        border-collapse: collapse;

        td svg {
            margin-left: 5px;

            * {
                stroke: $dark-grey;
            }

            &:hover {
                cursor: pointer;
            }
        }

        th {
            text-align: left;
        }

        tbody tr {
            border-radius: 24px;

            &:nth-child(odd) {
                background-color: $app-background;
            }
        }

        td,
        th {
            padding: 15px;
        }
    }

    .status {
        border-radius: 24px;
        text-align: center;
        padding: 5px 15px;

        &.pending {
            color: $deep-orange;
            border: 1px solid $deep-orange;
            background-color: $pale-orange;
        }

        &.paid {
            color: $deep-green;
            border: 1px solid $deep-green;
            background-color: $pale-green;
        }

        &.overdue {
            color: $red;
            border: 1px solid $red;
            background-color: $pale-red;
        }
    }

    .MuiFormControl-root {
        min-width: 140px;
    }
}