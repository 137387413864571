@import "style-constants.scss";

.select-a-role-wrapper {
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100vh;
    padding: 70px 110px;
    position: relative;


    .select-a-role-content {
        width: fit-content;
        margin: 0 auto;
    }

    .logo-card-media-wrapper {
        margin: 0 auto;
        width: fit-content;
        padding-bottom: 70px;
    }

    .select-a-role-action-btn {
        width: 100%;
        margin-top: 30px;
    }
}
