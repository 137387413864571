@import "style-constants.scss";

.sort-list {
    width: 98%;
    margin-left: auto;
}

.sortable-item-container {
    display: block;

    &:hover {
        cursor: grab;
    }
    
    .sortable-item-content {
        display: flex;
        align-items: center;
    }
    
    .drag-icon {
        width: 20px;
        margin-right: 20px;
        
    }
    
    .item-row-name {
        font-size: $large-title;
        color: $grey-darker-text;
        line-height: 24px;
        margin-right: 20px;
    }
    
    .item-row-description {
        
        font-size: $medium;
        color: $grey-dark;
        font-weight: 300;
        padding-top: 10px;
        // width + margin of drag element
        margin-left: 40px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
                line-clamp: 3;
        -webkit-box-orient: vertical;
        -webkit-box-orient: -moz-vertical;
    }

    .item-remove-icon {
        color: $grey-dark;
        width: 23px;
        height: 23px;
        cursor: pointer;
    }

    .item-edit-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        color: $grey-dark;
        background-color: $grey-light;
        border: 1px solid $grey-light;
        border-radius: 14px;
        cursor: pointer;
        margin-right: 7px;
    }

    .item-edit-icon-wrapper:hover {
        color: $primary-color;
        border: 1px solid $primary-color;
    }

    .item-edit-icon {
        width: 10px;
        height: 10px;
    }

    hr {
        width: 97%;
        margin: 30px 0;
        margin-left: auto;
        border: 1px solid $grey-light-medium;
        height: 1px;
        opacity: 1;
    }
}
