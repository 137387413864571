@import "style-constants.scss";

.active-holidays-container {
    margin-top: 20px;
    position: relative;

    .section-actions-wrapper {
        justify-content: space-between;
    }

    .rbc-toolbar {
        justify-content: center;
        font-size: 15px;

        .arrows {
            display: flex;
            align-items: center;
        }

        .today {
            display: none;
        }

        button {
            all: unset;
            cursor: pointer;

            &:focus {
                all: unset;
                cursor: pointer;
            }

            &:hover {
                all: unset;
                cursor: pointer;
            }

            &:focus:active {
                all: unset;
                cursor: pointer;
            }
        }

        .MuiTouchRipple-root {
            display: none;
        }
    }

    .rbc-time-view {
        border: none;
    }

    .weekdays {
        color: $white;
        text-transform: uppercase;
        font-size: $medium;
    }

    .active-holidays-loader {
        position: absolute;
        left: 50%;
        top: 65%;
    }

    .active-holidays-chart-wrapper {
        margin-bottom: 20px;

        .rbc-time-header-cell {
            padding-top: 11px;
            background-color: $purple-dark;
        }

        .rbc-allday-cell {
            padding-left: 20px;
            width: calc(100% - 20px);
        }

        .rbc-time-view {
            .rbc-time-header {
                .rbc-label {
                    padding: unset;
                }

                .rbc-time-header-content {
                    border-left: unset;

                    .rbc-allday-cell .rbc-row-bg .rbc-day-bg {
                        &.weekend {
                            background-color: unset;
                        }

                        &.rbc-today {
                            background-color: unset;
                        }

                        border: none;
                    }
                }

                .rbc-time-view .rbc-row {
                    box-sizing: border-box;
                }

                .rbc-header {
                    background: $purple-dark;
                    border: none;
                    letter-spacing: 0.09px;
                    color: $white;
                    font-size: $medium;
                    padding-top: 4px;
                    border-radius: 18px 18px 0 0;
                }
            }

            .rbc-row {
                min-height: 30px;
                border: none;
            }

            .rbc-time-header-cell.rbc-row {
                min-height: 50px;
                border-radius: 18px
            }

            .rbc-event {
                padding: unset;
                border-radius: 18px;

                .holiday-event {
                    display: flex;
                    min-height: 30px;
                    padding: 3px 5px;
                    border-radius: 18px;
                    width: 100%;

                    .avatar {
                        width: 22px;
                        height: 22px;
                    }

                    span {
                        font-size: 12px;
                        margin-left: 10px;
                        padding-top: 4px;
                        letter-spacing: 0.21px;
                        color: white;
                    }

                    &.active {
                        background: $active-holidays-green;
                    }

                    &.upcoming {
                        background: $upcoming-holidays-purple;
                    }

                    &.sick-leave {
                        background: $sick-leaves-grey;

                        span {
                            color: $purple-dark;;
                        }
                    }

                    &.home-office {
                        background: $yellow;
                    }
                }

                &.rbc-event-continues-prior {
                    .rbc-event-content .holiday-event {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }

                &.rbc-event-continues-after {
                    .rbc-event-content .holiday-event {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }

            .rbc-time-content {
                display: none;
            }
        }
    }

    .active-holidays-legend {
        display: flex;
        justify-content: flex-end;
        padding: 15px;
        align-items: center;

        div {
            width: 18px;
            height: 18px;
            border-radius: 4px;
        }

        span {
            font-size: 14px;
            color: $grey-dark;
            letter-spacing: 0.34px;
            margin-left: 10px;
        }

        .active-holiday {
            background: $active-holidays-green;
            margin-left: 22px;
        }

        .upcoming-holiday {
            background: $upcoming-holidays-purple;
        }

        .sick-leave {
            background: $sick-leaves-grey;
            margin-left: 22px;
        }

        .home-office {
            background: $yellow;
            margin-left: 22px;
        }
    }

    .rbc-toolbar-label {
        font-size: 12px;
        color: $purple-dark;
    }

    .rbc-toolbar {
        margin-bottom: 0;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .weekdays {
            font-size: $small;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .weekdays {
            font-size: $medium;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .weekdays {
            font-size: $medium;
        }
    }
}
