@import 'style-constants.scss';

.global-table-date-picker-popover {
    .MuiPickersToolbar-toolbar {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
        height: auto;
        background-color: $purple-dark;
    }

    .MuiPickersToolbarText-toolbarTxt,
    .MuiTypography-subtitle1,
    .MuiPickersMonth-monthSelected,
    .MuiPickersYear-yearSelected {
        letter-spacing: 0.21px;
        font-size: $small;
    }

    .MuiPickersMonth-monthSelected,
    .MuiPickersYear-yearSelected {
        font-weight: bold;
        color: $purple-dark;
    }

    .MuiPickersMonthSelection-container {
        width: auto;
        background-color: $grey-light-background;

        .MuiTypography-subtitle1 {
            color: $purple-dark;
        }
    }

    .MuiPickersYearSelection-container,
    .MuiPickersCalendarHeader-iconButton {
        background-color: $grey-light-background;
    }

    .MuiPickersBasePicker-pickerView {
        background-color: $grey-light-background;
        width: auto;
        height: auto;
        min-height: 0px;
        min-width: 150px;
    }

    .MuiPickersMonth-root {
        height: 45px;
    }

    .MuiPaper-rounded {
        border-radius: 18px;
        box-shadow: none;
        margin-top: 11px;
        margin-left: 11.5px !important;
    }

    // small desktops and mobile
    @media screen and (max-width: $small-desktop) {
        .MuiTypography-h1 {
            font-size: $smallest;
        }

        .MuiTypography-body1 {
            font-size: $tiny;
        }

        .MuiTypography-body2 {
            font-size: $tiny;
        }
    }

    // medium desktops
    @media screen and (min-width: calc($small-desktop + 1px)) and (max-width: $medium-desktop) {
        .MuiTypography-h1 {
            font-size: $medium;
        }

        .MuiTypography-body1 {
            font-size: $small;
        }

        .MuiTypography-body2 {
            font-size: $smallest;
        }
    }

    // large desktops
    @media screen and (min-width: calc($medium-desktop + 1px)) {
        .MuiTypography-h1 {
            font-size: $medium;
        }

        .MuiTypography-body1 {
            font-size: $small;
        }

        .MuiTypography-body2 {
            font-size: $smallest;
        }
    }
}
