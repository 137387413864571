@import "style-constants.scss";

.candidate-journey-cv-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    margin-bottom: 20px;
    width: 100%;

    .raw-cv-profile-content {
        padding: 40px 96px;
        background: white;
        min-height: 372px;
    }
    
    .step-number {
        width: 44px;
        height: 39px;
        color: $white;
        background-color: $primary-color;
        font-size: 20px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .use-linkedin-wrapper {
        display: grid;
        grid-auto-flow: column;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 30px;
    }

    .use-linkedin {
        color: $purple;

        &:hover {
            cursor: pointer;
        }
    }

    .cols {
        display: flex;
        margin: 0 -30px;

        .col-1of3 {
            width: 100%;
            padding: 0 30px;

            border-right: 2px solid $grey-light-medium;

            &:last-child {
                border-right: 0;
            }
        }
    }

    .text-info {
        h2 {
            margin-bottom: 16px;
        }
    }

    img {
        margin-bottom: 12px;
    }

}
