@import 'style-constants.scss';

.job-search-upcoming-interview-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 73% 27%;
    justify-content: flex-start;
    gap: 16px;

    .job-search-upcoming-interviews-interview-details {
        border-radius: 24px;
        box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
        background-color: $white;
        padding: 30px;
    }

    .job-search-upcoming-interviews-interview-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
    }

    .job-search-upcoming-interviews-interview-status {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-end;
        gap: 2px;

        .job-search-upcoming-interviews-interview-status-box {
            border-radius: 8px;
            height: min-content;
            padding: 2px 6px;
            text-align: center;

            &.upcoming {
                background-color: $pale-orange;
                color: $deep-orange;
                border: 1px solid $deep-orange;
            }

            &.completed {
                background-color: $pale-green;
                color: $deep-green;
                border: 1px solid $deep-green;
            }
        }
    }

    .job-search-upcoming-interviews-interview-accepted {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        text-align: center;

        svg {
            margin: auto;
        }

    }

    .job-search-upcoming-interviews-candidate-info-wrapper {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;

        .MuiAvatar-root {
            width: 72px;
            height: 72px;
        }

        .job-search-upcoming-interviews-candidate-matching-score-info {
            text-align: right;
        }

        .job-search-upcoming-interviews-candidate-info-content {
            display: grid;
            grid-auto-flow: column;
            gap: 12px;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .job-search-upcoming-interviews-additional-details {
        margin-top: 20px;
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;

        .job-search-upcoming-interviews-additional-details-location {
            display: grid;
            grid-auto-flow: column;
            gap: 5px;
            justify-content: flex-start;
            align-items: center;
        }

        .view-all-btn {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            align-items: center;

            svg * {
                stroke: $deep-orange
            }

            &:hover {
                cursor: pointer;
            }
        }

        .link:hover {
            cursor: pointer;
        }
    }

    .job-search-upcoming-interviews-interview-time-details {
        border-radius: 24px;
        box-shadow: 0px 6px 16px 0px rgba(10, 10, 10, 0.1);
        background-color: $white;
        padding: 10px;
        display: grid;
        grid-auto-flow: row;
        justify-content: space-between;
        grid-template-columns: 1fr;

        .job-search-upcoming-interviews-interview-slot-details {
            border-radius: 24px;
            padding: 10px;
            background-color: $app-background;
            display: grid;
            grid-auto-flow: row;
            grid-template-columns: 1fr;
            margin-bottom: 10px;

            &.approved {
                background-color: $pale-orange;
            }

            .job-search-upcoming-interviews-interview-date-time-details {
                display: grid;
                grid-auto-flow: column;
                justify-content: space-between;
                align-items: center;

                .date-details,
                .time-details {
                    display: grid;
                    grid-auto-flow: column;
                    align-items: center;
                    gap: 3px;
                }
            }
        }

        .reschedule-btn {
            width: 100%;
            margin-bottom: 0;
        }
    }
}