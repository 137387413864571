@import 'style-constants.scss';

.add-supplier-form {
    .MuiFormControl-marginNormal {
        margin: 0;
        margin-bottom: 3px;
    }

    .email-field,
    .supplier-name-field,
    .contact-person-field,
    .supplier-status-field {
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
