@import 'style-constants';

.add-report-dialog {
    .MuiDialog-paper {
        width: $page-content-width;
        padding: 0 16px; 
    }

    .dialog-title {
        padding-bottom: 0;
        padding-right: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;   
    }

    .dialog-title-text {
        color: $grey-dark;
        font-size: $medium;
    }
    
    .dialog-actions {
        margin-top: 22px;
        margin-bottom: 14px;
        padding-right: 0;
    }

    .MuiDialogContent-root.dialog-content {
        overflow-y: 'hidden'; 
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: scroll;
    }

    .MuiDialogContent-root.dialog-content::-webkit-scrollbar {
        width: 0px;
        display: none;
    }

    .business-trip-form {
        .form-field {
            margin-bottom: 30px;
        }
    }

    .upload-file-title {
        margin-bottom: 17px;
        color: $purple-dark;
        font-size: $medium;
        letter-spacing: 0.15px;
        font-weight: 500;
    }

    .upload-file-description {
        color: $purple-dark;
        font-size: $small;
        letter-spacing: 0.13px;
        opacity: 0.8;
    }
}
