@import 'style-constants.scss';

.interviews-wrapper {
    .interviews-searches-header {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
    }

    .interviews-container {
        margin-top: 20px;
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .interviews-actions {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 20px;
        align-items: center;
    }
}
