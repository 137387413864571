@import "style-constants.scss";

.candidate-initial-journey-navigation-desktop-wrapper {
    width: $candidate-initial-journey-navigation-width;

    .MuiDrawer-paperAnchorDockedLeft {
        border: none;
    }

    .MuiDrawer-paper {
        position: relative;
        white-space: nowrap;
        background-color: $dark-grey;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
    }

    .MuiDrawer-paper::-webkit-scrollbar {
        width: 0px;
        display: none;
    }

    .candidate-initial-journey-navigation-content {
        display: grid;
        height: 100%;
        margin: 0 72px;
        grid-template-rows: 20% 60% 20%;
    }

    .candidate-initial-journey-navigation-header {
        margin: 60px 0 auto 0;

        .logo {
            width: 100px;
            height: auto;
        }
    }

    .steps-wrapper {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-start;
        gap: 8px;
    }

    .menu-items-wrapper {
        display: flex;
        flex-direction: column;

        .last-step {
            flex-grow: 1;
        }

        .step-item {
            margin: 5px 0;
        }

        ;
    }

    .clickable:hover {
        cursor: pointer;
    }

    .progress-wrapper {
        height: 100%;
        width: 8px;
        border-radius: 32px;
        background-color: $grey;
        position: relative;

        .filled-progress {
            position: absolute;
            top: 0;
            left: 0;
            width: 8px;
            border-radius: 32px;
            background-color: $deep-purple;

            &.deep-orange {
                background-color: $deep-orange;
            }

            &.first {
                height: 25%;
            }

            &.second {
                height: 50%;
            }

            &.third {
                height: 75%;
            }

            &.fourth {
                height: 100%;
            }

            &.first-half {
                height: 50%;
            }

            &.second-half {
                height: 100%;
            }
        }

    }

    .candidate-initial-journey-navigation-footer {
        margin: auto 0;

        .list-item {
            display: flex;
            align-items: center;
            width: fit-content;
            padding: 0px;

            .list-icon {
                color: $primary-color-100;
                min-width: 0;

                &.exit-icon {
                    margin-right: 13px;
                    margin-left: -3.665px;

                    svg {
                        width: 29.33px;
                        height: 29.33px;
                    }
                }
            }

            .list-item-text {
                font-size: 18px;
                letter-spacing: 0px;
                color: $purple;
            }

            .list-item-text-orange {
                font-size: 18px;
                letter-spacing: 0px;
                color: $orange;
            }

            &:hover {
                background-color: transparent;
            }
        }
    }
}

.candidate-initial-journey-navigation-mobile-wrapper {
    .candidate-initial-journey-navigation-content {
        width: 100%;
        height: 80px;
        background-color: $purple-dark;

        .candidate-initial-journey-navigation-header {

            .logo-wrapper {
                width: fit-content;
                margin: 0 auto;
                padding-top: 13.5px;

                .logo {
                    width: 100px;
                    height: auto;
                }
            }
        }
    }
}