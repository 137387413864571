@import "style-constants.scss";

.candidate-applications-actions {
    .candidate-applications-tabs-container {
        display: grid;
        grid-auto-flow: column;
        gap: 40px;
        justify-content: flex-start;
        border-bottom: 2px solid $grey-light-amount;
        margin-bottom: 30px;

        .candidate-applications-tab {
            cursor: pointer;

            &.active-applications-tab {

                .candidate-applications-tab-title {
                    font-weight: 500;
                    margin-bottom: -1px;
                    color: $purple-dark;
                    border-bottom: 2px solid $purple-dark;
                }
            }

            .candidate-applications-tab-title {
                text-align: left;
                font-size: $medium;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: 0.54px;
                color: $grey-dark;
                text-transform: capitalize;
                padding-bottom: 8px;
            }
        }
    }

    .search-term-field.candidate-applications {
        font-size: $small;
        color: $grey-dark;
        border: 1px solid $purple-dark;
        background-color: $white;
        flex-grow: 1;

        .search-icon {
            color: $purple-dark;
            margin-left: 10px;
        }

        .MuiInputBase-input {
            padding: 10px 0px;
        }
    }
}
