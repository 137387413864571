@import 'style-constants.scss';

.saved-talents-wrapper {
    .saved-talents-heading {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;

        .search-term-field {
            border: 1px solid $pale-gray;
            border-radius: 16px;
            background-color: $white;
            min-width: 400px;
            min-height: 56px;
        }

        .search-icon {
            margin-left: 10px;
        }
    }
}
