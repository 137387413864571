@import "style-constants.scss";

.hottest-talents-table-wrapper {
    .MuiAvatar-root {
        margin-right: 10px;
    }

    .profile-image {
        width: 30px;
        height: 30px;
        margin-right: 11px;
        pointer-events: none;
        background-color: #edecec;

        &.full-time-contract {
            border: 1.5px solid $green-light;
        }

        &.freelance-contract {
            border: 1.5px solid $yellow-light;
        }

        &.invited {
            border: 1.5px solid $green-light;
        }

        &.self {
            border: 1.5px solid $yellow-light;
        }

        &.google {
            border: 1.5px solid $purple;
        }

        &.linkedin {
            border: 1.5px solid $blue-light;
        }

        &.other-sources {
            border: 1.5px solid $grey-dark;
        }

        img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .name-experience-container {
        text-align: left;
        color: $grey-dark-text;
        min-height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .name {
            display: flex;
            align-items: flex-start;
            letter-spacing: 0.24px;
            line-height: 24px;

            a {
                text-decoration: none;
                color: inherit;
            }

            .invited-by-admin {
                border: 2px solid $green-light;
            }

            .self-registered {
                border: 2px solid $yellow-light;
            }
        }

        .experience {
            font-size: 12px;
            font-weight: 300;
            line-height: 21px;
            letter-spacing: 0.21px;
            white-space: nowrap;

            .experience-text {
                margin-right: 5px;
            }
        }
    }

    .technologies-container {
        display: flex;
        align-items: center;
        color: $grey-dark-text;
        font-weight: 300;
        flex-wrap: wrap;
        margin-bottom: -5px;

        .single-technology-box {
            padding: 3px 8px;
            text-align: center;
            border: 1px solid rgba($grey-dark, 0.26);
            border-radius: 4px;
            margin-right: 10px;
            margin-bottom: 5px;

            &.no-border {
                color: $purple-dark;
                font-weight: 400;
                text-align: left;
                padding: 3px 8px;
                border: none;
                border-radius: 0;
            }

            .technology-text {
                line-height: 19px;
                letter-spacing: 0.11px;
            }
        }

        .rest-skills-count {
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.22px;
            margin-bottom: 5px;

            &:hover {
                cursor: pointer;
            }
        }

        .rest-skills-container {
            position: relative;
        }

        .rest-skills-popup {
            position: absolute;
            top: 150%;
            z-index: 999;
            display: grid;
            grid-template-columns: auto auto auto;
            flex-wrap: wrap;
            width: max-content;
            max-width: 700px;
            background-color: $white;
            border-radius: 18px;
            -webkit-box-shadow: 0px 1px 6px #00000038;
            box-shadow: 0px 1px 6px #00000038;
            padding: 25px 25px;
        }
    }

    .status-wrapper {
        display: flex;
        align-items: flex-start;

        .status-container {
            width: fit-content;
            padding: 2px 9px;
            border-radius: 4px;
            font-weight: 500;
            position: relative;
            min-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.placed {
                background-color: rgba($secondary-color, 0.1);

                .status-text {
                    color: $secondary-color;
                }
            }

            &.non-billable-project {
                background-color: rgba($primary-color, 0.1);

                .status-text {
                    color: $primary-color;
                }
            }

            &.bench {
                background-color: rgba($red-darker, 0.1);

                .status-text {
                    color: $red-darker;
                }
            }

            &.project-ending {
                background-color: rgba($orange, 0.1);

                .status-text {
                    color: $orange;
                }
            }

            &.vetted {
                background-color: rgba($secondary-color-300, 0.1);

                .status-text {
                    color: $secondary-color-300;
                }
            }

            &.leadership-team {
                background-color: rgba($grey-background, 0.5);

                .status-text {
                    color: $grey-lighter;
                }
            }
        }

        .edit-status-button {
            margin-left: 5px;
            width: 24px;
            height: 24px;

            .edit-sharp-icon {
                width: 16px;
            }
        }
    }

    &.actions.hottest {
        display: table-cell;

        .dots-icon-wrapper {
            display: flex;
        }

        svg:hover {
            border-radius: 4px;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}