@import "style-constants.scss";

.missing-information-wrapper {
    background-color: rgba(220,8,58,.1);
    color: $red-darker;
    padding: 3px 5px;
    border-radius: 4px;
    width: 139px;
    margin: 20px 0px 80px;
    
    @media screen and (max-width: $mobile) {
        margin: 20px 0px 57px;
    }
}
