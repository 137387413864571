@import "style-constants.scss";

.personal-basic-info {
    margin: 20px auto 0 auto;
    width: 100%;

    .personal-info-avatar-wrapper {
        position: relative;
        width: fit-content;
        margin-bottom: 20px;

        .personal-info-avatar {
            width: 100px;
            height: 100px;

            &.MuiAvatar-colorDefault {
                background-color: $grey-creamy;
            }
        }

        .avatar-actions {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .upload-input {
                display: none;
            }

            .avatar-delete-button {
                background-color: $grey-dark;
                color: $white;
                font-size: 16px;
                padding: 2px;
                margin-right: 10px;
            }

            .avatar-edit-button {
                color: $purple-dark;
                background-color: $grey-light;
                border: 2px $purple-dark solid;
                font-size: 14px;
                padding: 2px;

                .edit-sharp-icon {
                    font-size: 12px;
                }
            }
        }
    }
}
