.explore-talents-search-bar-wrapper {
    display: inline-block;

    .secondary-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .talent-search-bar {
            color: #7e7e7e;
            background: #f3f3f3 0% 0% no-repeat padding-box;
        }

        .MuiOutlinedInput-input {
            width: 360px;
            padding: 13px;
            padding-right: 30px;
        }

        .skill-box.global-css-skill-box {
            background: transparent;
            margin: 0;
            padding: 0;
            position: absolute;
            right: 10px;
            border: none;
        }
    }
}
