@import "style-constants.scss";

.candidate-video-interview-feedback-wrapper {
    margin: 25px auto 0px;

    .candidate-video-interview-feedback-body {
        background: $white;
        padding: 30px 40px;
        border-radius: 18px;
        box-shadow: 0px 1px 3px $grey-darker-button;

        .feedback-block {
            .status-date-block {
                display: flex;
                min-width: 250px;

                .status-date {
                    display: inline;
                    white-space: nowrap;
                    margin-right: 10px;
                }
            }

            .feedback-block-content {
                height: 100%;
                padding-top: 13.5px;
                padding-bottom: 13.5px;
                align-self: center;

                &:last-child {
                    display: flex;
                    justify-content: flex-start;
                }

                &.right-aligned {
                    justify-content: flex-end;
                }

                h3 {
                    margin: 0;
                    letter-spacing: 0.28px;
                    font-size: $medium;
                    color: $purple-dark;
                }

                p {
                    margin: 0;
                    letter-spacing: 0.21px;
                    font-size: $medium;
                    color: $purple-dark;
                    word-break: break-word;
                }

            }

            .internal-feedback {
                white-space: pre-wrap;
                word-break: break-word;
            }
        }

        .status-label {
            padding: 5px 15px;
            color: $grey-dark;
            background-color: $background-label;
            border-radius: 4px;
            font-size: $medium;
            display: flex;
            align-items: center;

            .info-icon-wrapper {
                display: flex;
                justify-content: flex-start;
                align-content: center;
                margin-left: 8px;

                svg {
                    width: 14px;
                    height: 14px;
                }
            }

            &.vetted,
            &.pending,
            &.pending-video-review,
            &.pending-calendly-review,
            &.new,
            &.incomplete-profile {
                background-color: rgba($grey-dark, 0.1);

                p {
                    color: $grey-dark;
                }
            }

            &.rejected {
                background-color: rgba($red-darker, 0.1);

                p {
                    color: $red-darker;
                }

                .info-icon-wrapper svg path {
                    fill: $red-darker;
                }
            }

            &.small {
                font-size: $small;

                &.vetted {
                    background-color: $secondary-color-50;

                    p {
                        color: $secondary-color-500;
                    } 
                }
            }
        }

        .feedback-block.grid {
            display: grid;

            &.col-3 {
                grid-template-columns: 50% 25% 25%;
            }

            &.col-2 {
                grid-template-columns: 50% 50%;
            }

            position: relative;

            .rating-container {
                padding-top: 15px;
                padding-bottom: 0px;

                .MuiRating-iconFilled {
                    color: $grey-lighter;
                }
            }
        }

        .buttons-wrapper {
            display: flex;
            justify-content: center;
            margin: 10px 0;
        }

        .rate-candidate-btn {
            padding: 15px 33px;
        }
    }

    .MuiRating-root {
        span {
            margin-right: 2px;
        }
    }

    .status-history-wrapper {
        margin-bottom: 22px;
    }

    .status-history-content {
        display: grid;
        grid-auto-flow: row;
        gap: 20px;
        
        .header-container {
            display: grid;
            grid-auto-flow: column;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;

            .assignee-name {
                color: $purple-dark;
                letter-spacing: 0.21px;
                font-size: $medium;
                font-weight: 800;
            }

            .date {
                color: $purple-dark;
                letter-spacing: 0.21px;
                font-size: $medium;
            }
        }

        .description {
            color: $purple-dark;
            letter-spacing: 0.28px;
            font-size: $medium;
        }
    }
}

.rejection-reason-tooltip {
    padding: 20px;
    width: 205px;
    background-color: $white;
    color: $grey-dark-text;
    font-size: $small;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.24px;
    box-shadow: 0px 1px 3px $grey-shadow;
}
