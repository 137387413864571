@import 'style-constants.scss';

.auth-card-wrapper {
    margin: 20px 0;
    
    .auth-info-content {
        background-color: $dark-grey;
        display: flex;
        max-width: 555px;
        justify-content: flex-start;
        flex-direction: column;
    }

    .codery-info {
        margin-top: 132px;
    }

    .heading-s,
    .footer-component {
        margin-top: 15px;
    }

    .heading-l {
        color: $white;
    }

    .auth-card-content {
        background-color: transparent;
        width: 524px;

        .auth-card-header {
            padding: 35px 0px 25px;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
            background-color: $white;
            display: grid;
            gap: 10px;
            justify-content: center;
            position: relative;

            .auth-card-title {
                font-size: $larger-title;
                line-height: 29px;
                letter-spacing: 0.23px;
                color: $white;
                text-align: center;
            }
        }

        .auth-card-form-wrapper {
            padding: 0px 90px 40px 90px;
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
            background-color: $white;
            margin: 0 auto;
        }
    }

    @include profile-creation-mobile {
        .auth-card-content {
            max-width: 400px;

            .auth-card-header {
                background-color: $white;

                .auth-card-media {
                    height: 45px;
                }

                .auth-card-title {
                    color: $medium-shade-blue;
                    font-size: $smaller;
                    line-height: 16px;
                    letter-spacing: 0.12px;
                }
            }

            .auth-card-form-wrapper {
                padding: 30px;
            }
        }
    }
}